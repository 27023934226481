import React from 'react';
import { Helmet } from 'react-helmet';
import CssHeader from './CssHeader';
import Header from './Header';
import Footer from './Footer';
import IncludeSidebar from './IncludeSidebar';
import IncludeMariigeCertificate from "./IncludeMariigeCertificate";

function OnlinePayment() {
  return (
    <>
      <Helmet>
        <title>Court Marriage</title>
        <meta name="description" content="Court Marriage." />
        <meta name="keywords" content="Delhi Court Marriage" />
      </Helmet>
      <CssHeader />
      <Header />
      <div id="main" className="site-main hfeed site">
        <div id="main-content" className="main-content">
          <div id="primary" className="content-area">
            <div id="content" className="site-content" role="main">
             
            <div id="post-52" class="post-52 page type-page status-publish hentry">
              <header class="entry-header">
                <h1 class="entry-title">Online Payment</h1>
              </header>
              <div class="entry-content">
                <div class="fl-builder-content fl-builder-content-52 fl-builder-content-primary fl-builder-global-templates-locked" data-post-id="52">
                  <div class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5f2fa53b6bc02" data-node="5f2fa53b6bc02">
                    <div class="fl-row-content-wrap">
                    
                      <div class="fl-builder-content fl-builder-content-64 fl-builder-content-primary fl-builder-global-templates-locked" data-post-id="64">
                        <div class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5f2f8d9b13728" data-node="5f2f8d9b13728">
                          <div class="fl-row-content-wrap">
                            <div class="fl-row-content fl-row-fixed-width fl-node-content">
                              <div class="fl-col-group fl-node-5f2f8d9b14b5b" data-node="5f2f8d9b14b5b">
                                <div class="fl-col fl-node-5f2f8d9b14bf1" data-node="5f2f8d9b14bf1">
                                  <div class="fl-col-content fl-node-content">
                                    <div class="fl-module fl-module-html fl-node-5f2f8d9b1363a" data-node="5f2f8d9b1363a">
                                      <div class="fl-module-content fl-node-content">
                                        <div class="fl-html">
                                          <script src="js.instamojo.com/v1/button.js"></script>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="fl-module-content fl-node-content">
                                      <div class="fl-html">
                                        <div class="im-checkout btn-82">
                                          <a href="https://www.instamojo.com/@courtmarriage24x7/" class="im-checkout-btn btn--dark" target="_blank" rel="noopener noreferrer">
                                            
                                            <h1>Checkout for Online Payment</h1>
                                          
                                          </a>
                                        </div>
                                        
                                          <script src="https://js.instamojo.com/v1/button.js"></script>
                                      </div>
                                     
                                    </div>
                                    
                                    <div class="fl-module fl-module-callout fl-node-5f2f8df42dc9e" data-node="5f2f8df42dc9e">
                                      <div class="fl-module-content fl-node-content">
                                        <div class="fl-callout fl-callout-left fl-callout-has-photo fl-callout-photo-above-title">
                                          <div class="fl-callout-content">
                                            <div class="fl-callout-photo">
                                              <div class="fl-photo fl-photo-align-">
                                                <div class="fl-photo-content fl-photo-img-png"><img decoding="async" class="alignnone size-medium wp-image-96" src="/static/uploads/2022/05/320px-State_Bank_of_India_logo.svg_-300x105.png" alt="" width="300" height="105" /></div>
                                              </div>
                                            </div>
                                            <h3 class="fl-callout-title"><span class="fl-callout-title-text">STATE BANK OF INDIA</span></h3>
                                            <div class="fl-callout-text-wrap">
                                              <div class="fl-callout-text">
                                                <ul>
                                                  <li>Account Name : Shreyansh Jain</li>
                                                  <li>Account No. : 35059710887</li>
                                                  <li>Account Type : Saving</li>
                                                  <li>
                                                    IFSC Code : SBIN0014244<br />
                                                    <hr />
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="fl-module fl-module-callout fl-node-5f2f8e2fb789c" data-node="5f2f8e2fb789c">
                                      <div class="fl-module-content fl-node-content">
                                        <div class="fl-callout fl-callout-left fl-callout-has-photo fl-callout-photo-above-title">
                                          <div class="fl-callout-content">
                                            <div class="fl-callout-photo">
                                              <div class="fl-photo fl-photo-align-">
                                                <div class="fl-photo-content fl-photo-img-png"><img decoding="async" loading="lazy" class="alignnone size-medium wp-image-98" src="/static/uploads/2022/05/1200px-UPI-Logo-vector.svg_-300x137.png" alt="" width="300" height="137" /></div>
                                              </div>
                                            </div>
                                            <h3 class="fl-callout-title"><span class="fl-callout-title-text">9716030199@ybl</span></h3>
                                            <div class="fl-callout-text-wrap">
                                              <div class="fl-callout-text">
                                                <hr />
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="fl-module fl-module-gallery fl-node-5f2f8e75e4809" data-node="5f2f8e75e4809">
                                      <div class="fl-module-content fl-node-content">
                                        <div class="fl-mosaicflow">
                                          <div class="fl-mosaicflow-content">
                                            <div class="fl-mosaicflow-item">
                                              <div class="fl-photo fl-photo-align-center">
                                                <div class="fl-photo-content fl-photo-img-jpg"><img decoding="async" loading="lazy" class="fl-photo-img wp-image-78" title="gpay-9716030199 | Court Marriage &amp; Registration" src="/static/img/gpay-1535447958.jpg" sizes="(max-width: 400px) 100vw, 400px" alt="gpay-1535447958" width="400" height="400" /></div>
                                              </div>
                                            </div>
                                            <div class="fl-mosaicflow-item">
                                              <div class="fl-photo fl-photo-align-center">
                                                <div class="fl-photo-content fl-photo-img-png"><img decoding="async" loading="lazy" class="fl-photo-img wp-image-79" title="Paytm-9716030199 | Court Marriage &amp; Registration" src="/static/img/Paytm_logo.png" alt="Paytm_logo" width="675" height="225" /></div>
                                              </div>
                                            </div>
                                            <div class="fl-mosaicflow-item">
                                              <div class="fl-photo fl-photo-align-center">
                                                <div class="fl-photo-content fl-photo-img-png"><img decoding="async" loading="lazy" class="fl-photo-img wp-image-80" title="PhonePe-9716030199 | Court Marriage &amp; Registration" src="/static/img/PhonePe-Logo.wine_-1024x683.png"  alt="PhonePe-Logo.wine" width="1024" height="683" /></div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="fl-clear"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="fl-module fl-module-callout fl-node-5f2f8ebbefa99" data-node="5f2f8ebbefa99">
                                      <div class="fl-module-content fl-node-content">
                                        <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                                          <div class="fl-callout-content">
                                            <h3 class="fl-callout-title"><span class="fl-icon"><br />
                                              <i class="fab fa-amazon-pay" aria-hidden="true"></i><br />
                                              </span><br />
                                              <span class="fl-callout-title-text">9716030199</span>
                                            </h3>
                                            <div class="fl-callout-text-wrap">
                                              <div class="fl-callout-text">
                                                <hr />
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>                       
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            </div>
          </div>
          <IncludeSidebar/>
        </div>
        <IncludeMariigeCertificate/>
      </div>
      <Footer />
    </>
  );
}

export default OnlinePayment;
