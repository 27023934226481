import React from 'react';
import './CssHeader.css';

const CssHeader = () => {
  return (
    <>
      <link 
        rel='stylesheet' 
        id='wp-block-library-css' 
        href='/static/css/style.min6dcf.css?ver=5.9.2' 
        media='all' 
      />
      <link 
        rel='stylesheet' 
        id='fl-builder-layout-12-css' 
        href='/static/css/12-layoutfe83.css?ver=08a78dc4942e22fb537d5ac3bc1789f8' 
        media='all' 
      />
      <link 
        rel='stylesheet' 
        id='twentyfourteen-lato-css' 
        href='https://fonts.googleapis.com/css?family=Lato%3A300%2C400%2C700%2C900%2C300italic%2C400italic%2C700italic&subset=latin%2Clatin-ext&display=fallback' 
        media='all' 
      />
      <link 
        rel='stylesheet' 
        id='genericons-css' 
        href='/static/css/genericons19ce.css?ver=3.0.3' 
        media='all' 
      />

        <link 
        rel='stylesheet' 
        id='twentyfourteen-style-css' 
        href='/static/css/display-opinions-light6dcf.css?ver=20190507' 
        media='all' 
      />
      <link 
        rel='stylesheet' 
        id='twentyfourteen-style-css' 
        href='/static/css/style8d89.css?ver=20190507' 
        media='all' 
      />
      <link 
        rel='stylesheet' 
        id='twentyfourteen-block-style-css' 
        href='/static/css/blockscb8b.css?ver=20190102' 
        media='all' 
      />
      <script 
        src='/static/js/jquery/jquery.minaf6c.js?ver=3.6.0' 
        id='jquery-core-js'
      ></script>
      <script 
        src='/static/js/jquery/jquery-migrate.mind617.js?ver=3.3.2' 
        id='jquery-migrate-js'
      ></script>
    </>
  );
};

export default CssHeader;
