import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Home';
import About from "./About";
import MarriageCertificate from "./MarriageCertificate";
import Documents from "./Documents";
import OnlinePayment from "./OnlinePayment";
import Divorce from "./Divorce";
import FAQ from "./FAQ";
import Fee from "./Fee";
import LegalHelp from "./LegalHelp";
import ContactUs from "./ContactUs";
import Thanks from "./Thanks";


function App() {
  return (
    <Router>
      <>      
      <Routes> 
          <Route exact path="/" element={<Home/>} />  
          <Route path="/about-us" element={<About/>} />  
          <Route path="/about-us" element={<About/>} />  
          <Route path="/marriage-certificate" element={<MarriageCertificate/>} />  
          <Route path="/documents" element={<Documents/>} /> 
          <Route path="/online-payment" element={<OnlinePayment/>} /> 
          <Route path="/divorce" element={<Divorce/>} /> 
          <Route path="/faq" element={<FAQ/>} /> 
          <Route path="/fee" element={<Fee/>} /> 
          <Route path="/legal-help" element={<LegalHelp/>} /> 
          <Route path="/contact-us" element={<ContactUs/>} /> 
		  <Route path="/thanks" element={<Thanks/>} /> 

            
        </Routes>        
      </>
    </Router>    
  );
}

export default App;