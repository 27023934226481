import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/'; 
    // State to manage the menu visibility
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle the menu visibility
  const handleMenuToggle = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  return (
    <div id="page" className="hfeed site">
      <div id="site-header">
        <Link to="/" rel="home">
          <img 
            src="/static/img/cropped-Court-Marriage-In.png" 
            width="1260" 
            height="240" 
            alt="Court Marriage &amp; Registration"
          />
        </Link>
      </div>
      
      <header id="masthead" className="site-header" role="banner">
        <div className="header-main">
          <h1 className="site-title">
            <Link to="/" rel="home">Court Marriage &amp; Registration</Link>
          </h1>

          <div className="search-toggle">
            <a href="#search-container" className="screen-reader-text" aria-expanded="false" aria-controls="search-container">Search</a>
          </div>
          
          <nav id="primary-navigation" className={`site-navigation primary-navigation ${isMenuOpen ? 'site-navigation primary-navigation toggled-on' : ''}`}>
           <button className="menu-toggle" aria-expanded={isMenuOpen} aria-controls="primary-menu" onClick={handleMenuToggle}> Primary Menu </button>
             <a className="screen-reader-text skip-link" href="#content">Skip to content</a>
            <div className={`menu-primary-menu-container`}>
               <ul id="primary-menu" className="nav-menu"  aria-expanded={`${isMenuOpen ? 'true' : 'false'}`}> 
                <li className={`menu-item ${isHomePage ? 'current-menu-item current_page_item active' : ''}`}>
                  <NavLink exact to="/" title="Home">Home</NavLink>
                </li>
                <li className={`menu-item ${location.pathname === '/about-us' ? 'current-menu-item current_page_item active' : ''}`}>
                  <NavLink to="/about-us" title="About Us">About Us</NavLink>
                </li>
                <li className={`menu-item ${location.pathname === '/marriage-certificate' ? 'current-menu-item current_page_item active' : ''}`}>
                  <NavLink to="/marriage-certificate" title="Marriage Certificate">Marriage Certificate</NavLink>
                </li>
                <li className={`menu-item ${location.pathname === '/documents' ? 'current-menu-item current_page_item active' : ''}`}>
                  <NavLink to="/documents" title="Documents">Documents</NavLink>
                </li>
                <li className={`menu-item ${location.pathname === '/faq' ? 'current-menu-item current_page_item active' : ''}`}>
                  <NavLink to="/faq" title="FAQ">FAQ</NavLink>
                </li>
                <li className={`menu-item ${location.pathname === '/online-payment' ? 'current-menu-item current_page_item active' : ''}`}>
                  <NavLink to="/online-payment" title="Online Payment">Online Payment</NavLink>
                </li>
                <li className={`menu-item ${location.pathname === '/fee' ? 'current-menu-item current_page_item active' : ''}`}>
                  <NavLink to="/fee" title="Fee">Fee</NavLink>
                </li>
                <li className={`menu-item ${location.pathname === '/divorce' ? 'current-menu-item current_page_item active' : ''}`}>
                  <NavLink to="/divorce" title="Divorce">Divorce</NavLink>
                </li>
                <li className={`menu-item ${location.pathname === '/legal-help' ? 'current-menu-item current_page_item active' : ''}`}>
                  <NavLink to="/legal-help" title="Legal Help">Legal Help</NavLink>
                </li>
                <li className={`menu-item ${location.pathname === '/contact-us' ? 'current-menu-item current_page_item active' : ''}`}>
                  <NavLink to="/contact-us" title="Contact Us">Contact Us</NavLink>
                </li>
              </ul>  
            </div>      
          </nav>
        </div>

        <div id="search-container" className="search-box-wrapper hide">
          <div className="search-box">
            <form role="search" method="get" className="search-form" action="/">
              <label>
                <span className="screen-reader-text">Search for:</span>
                <input type="search" className="search-field" placeholder="Search &hellip;" name="s" />
              </label>
              <input type="submit" className="search-submit" value="Search" />
            </form>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
