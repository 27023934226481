import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import CssHeader from './CssHeader';
import Header from './Header';
import Footer from './Footer';
import IncludeSidebar from './IncludeSidebar';
import IncludeMariigeCertificate from "./IncludeMariigeCertificate";
import axios from 'axios';

function ContactUs() {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    contact_date: '',
    contact_time: '',
	contact_action: 'yes',
    purpose: '',
	honeypot: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
	
	if (formData.honeypot) {
          alert('Spam detected. Please try again.');
          return;
      }
	
	axios.post('https://admissionschoolofbiodesign.in/kite/court_marrige.php', JSON.stringify(formData),{
		headers: {
			'Content-Type': 'application/json'
		  }
		})
		.then(response => {
			
			if (response.data.status === 'success') {			  
			  window.location.href = response.data.redirect_url;
			} else {			  
			  alert(response.data.message);
			}
		})
		.catch(error => {
			//console.error('There was an error submitting the form!', error);			
			alert('There was an error submitting the form. Please try again.');
		});
  };

  return (
    <>
      <Helmet>
        <title>Court Marriage</title>
        <meta name="description" content="Court Marriage." />
        <meta name="keywords" content="Delhi Court Marriage" />
      </Helmet>
      <CssHeader />
      <Header />
      <div id="main" className="site-main hfeed site">
        <div id="main-content" className="main-content">
          <div id="primary" className="content-area">
            <div id="content" className="site-content" role="main">

            <div id="post-70" className="post-70 page type-page status-publish hentry">
               <header className="entry-header">
                  <h1 className="entry-title">Contact Us</h1>
               </header>
              
               <div className="entry-content">
                  <div className="fl-builder-content fl-builder-content-70 fl-builder-content-primary fl-builder-global-templates-locked" data-post-id="70">
                     <div className="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5f2f8734dfaf1" data-node="5f2f8734dfaf1">
                        <div className="fl-row-content-wrap">
                           <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div className="fl-col-group fl-node-5f2f8734e12e3" data-node="5f2f8734e12e3">
                                 <div className="fl-col fl-node-5f2f8734e13bf" data-node="5f2f8734e13bf">
                                    <div className="fl-col-content fl-node-content">
                                       <div className="fl-module fl-module-photo fl-node-5f6230a1eddb6" data-node="5f6230a1eddb6">
                                          <div className="fl-module fl-module-photo fl-node-5f62281b9a403" data-node="5f62281b9a403">
                                             <div className="fl-module-content fl-node-content">
                                                <div className="fl-photo fl-photo-align-center">
                                                   
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                      
                                       <div className="fl-module fl-module-widget fl-node-5f76b5ac2cc5a" data-node="5f76b5ac2cc5a">
                                          <div className="fl-module-content fl-node-content">
                                             <div className="fl-widget">
                                                <div className="widget widget_ninja_forms_widget">
                                                   <noscript className="ninja-forms-noscript-message">
                                                      Notice: JavaScript is required for this content.
                                                   </noscript>
                                                   <span id="nf-form-title-3" className="nf-form-title">
                                                      <h3>Book your appointment</h3>
                                                   </span>
                                                   <div className="nf-form-layout">
														
														  <form className="cmxform" id="commonForm" method="post" onSubmit={handleSubmit}>
                                            <div>
                                              <div className="nf-before-form-content">
                                                <nf-section>
                                                  <div className="nf-form-fields-required">Fields marked with an <span className="ninja-forms-req-symbol">*</span> are required</div>
                                                </nf-section>
                                              </div>
                                              <div className="nf-form-content">
                                                <nf-fields-wrap>
                                                  <nf-field>
                                                    <div id="nf-field-43-container" className="nf-field-container email-container label-above">
                                                      <div className="nf-before-field">
                                                        <nf-section>
                                                        </nf-section>
                                                      </div>
                                                      <div className="nf-field">
                                                        <div id="nf-field-43-wrap" className="field-wrap email-wrap" data-field-id="43">
                                                          <div className="nf-field-label">
                                                            <label htmlFor="name" id="nf-label-field-43">Name <span className="ninja-forms-req-symbol">*</span></label>
                                                          </div>
                                                          <div className="nf-field-element">
                                                            <input
                                                              type="text"
                                                              className="ninja-forms-field nf-element"
                                                              id="name"
                                                              name="name"
                                                              value={formData.name}
                                                              onChange={handleChange}
                                                              required
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="nf-after-field">
                                                        <nf-section>
                                                          <div id="nf-error-43" className="nf-error-wrap nf-error" role="alert"></div>
                                                        </nf-section>
                                                      </div>
                                                    </div>
                                                  </nf-field>
                                                  <nf-field>
                                                    <div id="nf-field-43-container" className="nf-field-container email-container label-above">
                                                      <div className="nf-before-field">
                                                        <nf-section>
                                                        </nf-section>
                                                      </div>
                                                      <div className="nf-field">
                                                        <div id="nf-field-43-wrap" className="field-wrap email-wrap" data-field-id="43">
                                                          <div className="nf-field-label">
                                                            <label htmlFor="email" id="nf-label-field-43">Email <span className="ninja-forms-req-symbol">*</span></label>
                                                          </div>
                                                          <div className="nf-field-element">
                                                            <input
                                                              type="email"
                                                              className="ninja-forms-field nf-element"
                                                              id="email"
                                                              name="email"
                                                              value={formData.email}
                                                              onChange={handleChange}
                                                              required
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="nf-after-field">
                                                        <nf-section>
                                                          <div id="nf-error-43" className="nf-error-wrap nf-error" role="alert"></div>
                                                        </nf-section>
                                                      </div>
                                                    </div>
                                                  </nf-field>
                                                  <nf-field>
                                                    <div id="nf-field-44-container" className="nf-field-container phone-container label-above textbox-container">
                                                      <div className="nf-before-field">
                                                        <nf-section>
                                                        </nf-section>
                                                      </div>
                                                      <div className="nf-field">
                                                        <div id="nf-field-44-wrap" className="field-wrap phone-wrap textbox-wrap" data-field-id="44">
                                                          <div className="nf-field-label">
                                                            <label htmlFor="phone" id="nf-label-field-44">WhatsApp Number <span className="ninja-forms-req-symbol">*</span></label>
                                                          </div>
                                                          <div className="nf-field-element">
                                                            <input
                                                              type="tel"
                                                              className="ninja-forms-field nf-element"
                                                              id="phone"
                                                              name="phone"
                                                              value={formData.phone}
                                                              onChange={handleChange}
                                                              required
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="nf-after-field">
                                                        <nf-section>
                                                          <div id="nf-error-44" className="nf-error-wrap nf-error" role="alert"></div>
                                                        </nf-section>
                                                      </div>
                                                    </div>
                                                  </nf-field>
                                                  <nf-field>
                                                    <div id="nf-field-45-container" className="nf-field-container date-container label-above">
                                                      <div className="nf-before-field">
                                                        <nf-section>
                                                        </nf-section>
                                                      </div>
                                                      <div className="nf-field">
                                                        <div id="nf-field-45-wrap" className="field-wrap date-wrap" data-field-id="45">
                                                          <div className="nf-field-label">
                                                            <label htmlFor="contact_date" id="nf-label-field-45">Date</label>
                                                          </div>
                                                          <div className="nf-field-element">
                                                            <input
                                                              type="date"
                                                              className="ninja-forms-field nf-element"
                                                              id="contact_date"
                                                              name="contact_date"
                                                              value={formData.contact_date}
                                                              onChange={handleChange}
                                                              required
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="nf-after-field">
                                                        <nf-section>
                                                          <div id="nf-error-45" className="nf-error-wrap nf-error" role="alert"></div>
                                                        </nf-section>
                                                      </div>
                                                    </div>
                                                  </nf-field>
                                                  <nf-field>
                                                    <div id="nf-field-46-container" className="nf-field-container listselect-container label-above list-container">
                                                      <div className="nf-before-field">
                                                        <nf-section>
                                                        </nf-section>
                                                      </div>
                                                      <div className="nf-field">
                                                        <div id="nf-field-46-wrap" className="field-wrap listselect-wrap list-wrap list-select-wrap" data-field-id="46">
                                                          <div className="nf-field-label">
                                                            <label htmlFor="contact_time" id="nf-label-field-46">Time <span className="ninja-forms-req-symbol">*</span></label>
                                                          </div>
                                                          <div className="nf-field-element">
                                                            <select
                                                              id="contact_time"
                                                              name="contact_time"
                                                              value={formData.contact_time}
                                                              onChange={handleChange}
                                                              className="ninja-forms-field nf-element"
                                                              required
                                                            >
                                                              <option value="10-00-am-11-00-am">10:00 AM – 11:00 AM</option>
                                                              <option value="11-00-am-12-00-pm">11:00 AM – 12:00 PM</option>
                                                              <option value="12-00-pm-01-00-pm">12:00 PM – 01:00 PM</option>
                                                              <option value="01-00-pm-02-00-pm">01:00 PM – 02:00 PM</option>
                                                              <option value="02-00-pm-03-00-pm">02:00 PM – 03:00 PM</option>
                                                              <option value="03-00-pm-04-00-pm">03:00 PM – 04:00 PM</option>
                                                            </select>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="nf-after-field">
                                                        <nf-section>
                                                          <div id="nf-error-46" className="nf-error-wrap nf-error" role="alert"></div>
                                                        </nf-section>
                                                      </div>
                                                    </div>
                                                  </nf-field>
                                                  <nf-field>
                                                    <div id="nf-field-47-container" className="nf-field-container textarea-container label-above">
                                                      <div className="nf-before-field">
                                                        <nf-section>
                                                        </nf-section>
                                                      </div>
                                                      <div className="nf-field">
                                                        <div id="nf-field-47-wrap" className="field-wrap textarea-wrap" data-field-id="47">
                                                          <div className="nf-field-label">
                                                            <label htmlFor="purpose" id="nf-label-field-47">Purpose of your visit <span className="ninja-forms-req-symbol">*</span></label>
                                                          </div>
                                                          <div className="nf-field-element">
                                                            <textarea
                                                              id="purpose"
                                                              name="purpose"
                                                              value={formData.purpose}
                                                              onChange={handleChange}
                                                              className="ninja-forms-field nf-element"
                                                              required
                                                            ></textarea>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="nf-after-field">
                                                        <nf-section>
                                                          <div id="nf-error-47" className="nf-error-wrap nf-error" role="alert"></div>
                                                        </nf-section>
                                                      </div>
                                                    </div>
                                                  </nf-field>
                                                  <nf-field>
                                                    <div id="nf-field-48-container" className="nf-field-container recaptcha-container label-above textbox-container">
                                                      <div className="nf-before-field">
                                                        <nf-section>
                                                        </nf-section>
                                                      </div>
                                                      <div className="nf-field">
                                                        <div id="nf-field-48-wrap" className="field-wrap recaptcha-wrap textbox-wrap" data-field-id="48">
                                                          <div className="nf-field-label">
                                                            <label htmlFor="nf-field-48"></label>
                                                          </div>
                                                          <div className="nf-field-element">
                                                            <input id="nf-field-48" name="nf-field-48" className="ninja-forms-field nf-element" type="hidden" value="" />
                                                            <div className="g-recaptcha" data-callback="nf_recaptcha_response_48" data-theme="light" data-sitekey="6LeARW0UAAAAAFafwL-U2O-jzAaoAmcCtJNZkUNq" data-fieldid="48">
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="nf-after-field">
                                                        <nf-section>
                                                          <div id="nf-error-48" className="nf-error-wrap nf-error" role="alert"></div>
                                                        </nf-section>
                                                      </div>
                                                    </div>
                                                  </nf-field>
                                                  <nf-field>
                                                    <div id="nf-field-49-container" className="nf-field-container submit-container label-above textbox-container">
                                                      <div className="nf-before-field">
                                                        <nf-section>
                                                        </nf-section>
                                                      </div>
                                                      <div className="nf-field">
                                                        <div id="nf-field-49-wrap" className="field-wrap submit-wrap textbox-wrap" data-field-id="49">
                                                          <div className="nf-field-label"></div>
                                                          <div className="nf-field-element">                                                   
                                                            <input id="nf-field-49" className="ninja-forms-field nf-element" type="submit" value="Submit" />
                                                          </div>
                                                          <div className="nf-error-wrap"></div>
                                                        </div>
                                                      </div>
                                                      <div className="nf-after-field">
                                                        <nf-section>
                                                          <div id="nf-error-49" className="nf-error-wrap nf-error" role="alert"></div>
                                                        </nf-section>
                                                      </div>
                                                    </div>
                                                  </nf-field>
                                                </nf-fields-wrap>
                                              </div>
                                              <div className="nf-after-form-content">
                                                <nf-section>
                                                  <div id="nf-form-errors-3" className="nf-form-errors" role="alert">
                                                    <nf-errors>
                                                      <nf-section>
                                                        <div className="nf-error-msg nf-error-field-errors">Please correct errors before submitting this form.</div>
                                                      </nf-section>
                                                    </nf-errors>
                                                  </div>
                                                  <div className="nf-form-hp">
                                                    <nf-section>
                                                      <label htmlFor="nf-field-hp-3" aria-hidden="true">
                                                        If you are a human seeing this field, please leave it empty.
                                                        <input id="nf-field-hp-3" name="nf-field-hp" className="nf-element nf-field-hp" type="text" value="" />
                                                      </label>
                                                    </nf-section>
                                                  </div>
                                                </nf-section>
                                              </div>
                                            </div>
                                          </form>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="fl-module-content fl-node-content">
                                          <div className="fl-photo fl-photo-align-center" >
                                             <div className="fl-photo-content fl-photo-img-jpg">
                                                <img loading="lazy" className="fl-photo-img wp-image-124 size-full" src="/static/uploads/2020/10/contact-us.jpg" alt="contact us" itemprop="image" height="1253" width="1920" title="contact us | Court Marriage &amp; Registration"  />
                                             </div>
                                          </div>
                                       </div>
                                       <div className="fl-module fl-module-callout fl-node-5f2f898f5a1b1" data-node="5f2f898f5a1b1">
                                          <div className="fl-module-content fl-node-content">
                                             <div className="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                                                <div className="fl-callout-content">
                                                   <h3 className="fl-callout-title">	<span className="fl-icon">
                                                      <i className="fas fa-phone-volume" aria-hidden="true"></i>
                                                      </span>
                                                      <span className="fl-callout-title-text">24X7 Helpline</span>
                                                   </h3>
                                                   <div className="fl-callout-text-wrap">
                                                      <div className="fl-callout-text">
                                                         <p>9999985852</p>
                                                         <hr />
                                                         <p>&nbsp;</p>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="fl-module fl-module-callout fl-node-5f2f89fa6601a" data-node="5f2f89fa6601a">
                                          <div className="fl-module-content fl-node-content">
                                             <div className="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                                                <div className="fl-callout-content">
                                                   <h3 className="fl-callout-title">	<span className="fl-icon">
                                                      <i className="dashicons dashicons-before dashicons-email-alt" aria-hidden="true"></i>
                                                      </span>
                                                      <span className="fl-callout-title-text">Email</span>
                                                   </h3>
                                                   <div className="fl-callout-text-wrap">
                                                      <div className="fl-callout-text">
                                                         <p>info@courtmarriage-gov.co.in</p>
                                                         <hr />
                                                         <p>&nbsp;</p>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="fl-module fl-module-callout fl-node-5f2f8a9132c31" data-node="5f2f8a9132c31">
                                          <div className="fl-module-content fl-node-content">
                                             <div className="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                                                <div className="fl-callout-content">
                                                   <h3 className="fl-callout-title">	<span className="fl-icon">
                                                      <i className="fas fa-balance-scale" aria-hidden="true"></i>
                                                      </span>
                                                      <span className="fl-callout-title-text">Legal Team</span>
                                                   </h3>
                                                   <div className="fl-callout-text-wrap">
                                                      <div className="fl-callout-text">
                                                         <p>If you need any legal advise then don't hesitate to call/whatsapp any of the following Legal Team Advocates</p>
                                                         <hr />
                                                         <p>&nbsp;</p>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="fl-module fl-module-callout fl-node-5f2f8b20e1838" data-node="5f2f8b20e1838">
                                          <div className="fl-module-content fl-node-content">
                                             <div className="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                                                <div className="fl-callout-content">
                                                   <h3 className="fl-callout-title">	<span className="fl-icon">
                                                      <i className="fas fa-user-graduate" aria-hidden="true"></i>
                                                      </span>
                                                      <span className="fl-callout-title-text">Shreyansh Jain (Advocate)</span>
                                                   </h3>
                                                   <div className="fl-callout-text-wrap">
                                                      <div className="fl-callout-text">
                                                         <p><strong>Mobile : 9999985852</strong></p>
                                                         <p>(WhatsApp Active) if from abroad, please dial (+91) instead of (0) before the number</p>
                                                         <hr />
                                                         <p>&nbsp;</p>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                
                                       <div className="fl-module fl-module-callout fl-node-617fc884c4b08" data-node="617fc884c4b08">
                                          <div className="fl-module-content fl-node-content">
                                             <div className="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                                                <div className="fl-callout-content">
                                                   <h3 className="fl-callout-title">	<span className="fl-icon">
                                                      <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                                                      </span>
                                                      <span className="fl-callout-title-text">Address</span>
                                                   </h3>
                                                   <div className="fl-callout-text-wrap">
                                                      <div className="fl-callout-text">
                                                         <p><strong>Advocate Shreyansh Jain (Rishabh)</strong></p>
                                                         <p>T-93 H Ground Floor</p>
                                                         <p>KHIRKI VILLAGE</p>
                                                       
                                                         <p>New Delhi 110017</p>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
              
            </div>
              
            </div>
          </div>
          <IncludeSidebar/>
        </div>
        <IncludeMariigeCertificate/>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
