import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import CssHeader from './CssHeader';
import Header from './Header';
import Footer from './Footer';
import IncludeSidebar from './IncludeSidebar';
import IncludeMariigeCertificate from "./IncludeMariigeCertificate";
import axios from 'axios';

function Divorce() {
	
	const [formData, setFormData] = useState({
        hus_name: '',
        hus_father_name: '',
        hus_dob: '',
        hub_address: '',
        hub_religion: '',
        hub_nationality: '', 
		hus_phone: '',
        hus_email: '',
        wife_name: '',
        wife_father: '',
        wife_dob: '',
        wife_address: '',
		wife_religion: '',
        wife_nationality: '',
        wife_phone: '',
        wife_email: '',
        marriage_date: '',	
		marriage_place: '',
        divorce_type: '',        		
        divorce_action: 'yes',
        honeypot: ''
    });
	
	const [selectedHusbandReligion, setSelectedHusbandReligion] = useState('');
	const [selectedWifeReligion, setSelectedWifeReligion] = useState('');

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'hub_religion') {
			setSelectedHusbandReligion(value);
			setFormData(prevState => ({
				...prevState,
				hub_religion: value
			}));
		} else if (name === 'wife_religion') {
			setSelectedWifeReligion(value);
			setFormData(prevState => ({
				...prevState,
				wife_religion: value
			}));
		} else {
			
			setFormData(prevState => ({
			  ...prevState,
			  [name]: value
			}));
		}		
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		
		if (formData.honeypot) {
			  alert('Spam detected. Please try again.');
			  return;
		  }
		
		axios.post('https://admissionschoolofbiodesign.in/kite/court_marrige.php', JSON.stringify(formData),{
			headers: {
				'Content-Type': 'application/json'
			  }
			})
			.then(response => {
				
				if (response.data.status === 'success') {			  
          window.location.href = response.data.redirect_url;
				} else {				
				  alert(response.data.message);
				}
			})
			.catch(error => {
				//console.error('There was an error submitting the form!', error);			
				alert('There was an error submitting the form. Please try again.');
			});
	};
	
    return (
    <>
      <Helmet>
        <title>Court Marriage</title>
        <meta name="description" content="Court Marriage." />
        <meta name="keywords" content="Delhi Court Marriage" />
      </Helmet>
      <CssHeader />
      <Header />
      <div id="main" className="site-main hfeed site">
        <div id="main-content" className="main-content">
          <div id="primary" className="content-area">
            <div id="content" className="site-content" role="main">

              <div id="post-70" class="post-70 page type-page status-publish hentry">
              <header class="entry-header">
                <h1 class="entry-title">DIVORCE</h1>
              </header>
              <div class="entry-content">
                <div class="fl-builder-content fl-builder-content-70 fl-builder-content-primary fl-builder-global-templates-locked" data-post-id="70">
                <div class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5f2f8734dfaf1" data-node="5f2f8734dfaf1">
                  <div class="fl-row-content-wrap">
                    <div class="fl-row-content fl-row-fixed-width fl-node-content">
                      <div class="fl-col-group fl-node-5f2f8734e12e3" data-node="5f2f8734e12e3">
                      <div class="fl-col fl-node-5f2f8734e13bf" data-node="5f2f8734e13bf">
                        <div class="fl-col-content fl-node-content">
                          
                          <div class="fl-module fl-module-widget fl-node-5f76b5ac2cc5a" data-node="5f76b5ac2cc5a">
                            <div class="fl-module-content fl-node-content">
                            <div class="fl-widget">
                              <div class="widget widget_ninja_forms_widget">
                                <noscript class="ninja-forms-noscript-message">
                                  Notice: JavaScript is required for this content.
                                </noscript>
                                <div id="nf-form-3-cont" class="nf-form-cont" aria-live="polite" aria-labelledby="nf-form-title-3" aria-describedby="nf-form-errors-3" role="form">
                                  <span id="nf-form-title-3" class="nf-form-title">
                                  <h3>File Online Divorce</h3>
                                  </span>
                                  <div class="nf-form-wrap ninja-forms-form-wrap">
                                  <div class="nf-response-msg"></div>
                                  <div class="nf-debug-msg"></div>
                                  <div class="nf-before-form">
                                    <nf-section>
                                    </nf-section>
                                  </div>
                                  <div class="nf-form-layout">
                                    <form className="cmxform" id="commonForm" method="post" onSubmit={handleSubmit}>	
                                      <div>
                                        <div class="nf-before-form-content">
                                        <nf-section>
                                          <div class="nf-form-fields-required">Fields marked with an <span class="ninja-forms-req-symbol">*</span> are required</div>
                                        </nf-section>
                                        </div>
                                        <div class="nf-form-content ">
                                        <nf-fields-wrap>
                                          <nf-field>
                                            <div id="nf-field-19-container" class="nf-field-container firstname-container  label-above ">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-19-wrap" class="field-wrap firstname-wrap" data-field-id="19">
                                                <div class="nf-field-label"><label for="nf-field-19" id="nf-label-field-19" class="">Name <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <input type="text" class="ninja-forms-field nf-element" id="hus_name" name="hus_name"  placeholder="Enter Husband's Name" value={formData.hus_name} onChange={handleChange} required />
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-19" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-29-container" class="nf-field-container firstname-container  label-above ">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-29-wrap" class="field-wrap firstname-wrap" data-field-id="29">
                                                <div class="nf-field-label"><label for="nf-field-29" id="nf-label-field-29" class="">Father's Name <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <input type="text"  class="ninja-forms-field nf-element" id="hus_father_name" name="hus_father_name"  placeholder="Enter Husband's Father Name" value={formData.hus_father_name} onChange={handleChange} required />
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-29" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-30-container" class="nf-field-container date-container  label-above ">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-30-wrap" class="field-wrap date-wrap" data-field-id="30">
                                                <div class="nf-field-label"><label for="nf-field-30" id="nf-label-field-30" class="">Husband's Date of Birth <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <div class="pikaday__container">
                                                    <input class="ninja-forms-field nf-element datepicker pikaday__display pikaday__display--pikaday form-control input" placeholder=""  tabindex="0" type="date" name="hus_dob" id="hus_dob" value={formData.hus_dob} onChange={handleChange} required  />
                                                  </div>
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-30" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-31-container" class="nf-field-container address-container  label-above ">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-31-wrap" class="field-wrap address-wrap" data-field-id="31">
                                                <div class="nf-field-label"><label for="nf-field-31" id="nf-label-field-31" class="">Address <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <input type="text" class="ninja-forms-field nf-element" id="hub_address" name="hub_address" placeholder="Enter Husband's Address" value={formData.hub_address} onChange={handleChange} required />
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-31" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-19-container" class="nf-field-container listradio-container  label-above  list-container">
                                              <div class="nf-before-field"> </div>
                                              <div class="nf-field">
                                              <div id="nf-field-19-wrap" class="field-wrap" data-field-id="19">
                                                <div class="nf-field-label">
                                                  <label for="nf-field-19" id="nf-label-field-19" class="">Religion (Husband) <span class="ninja-forms-req-symbol">*</span> </label>
                                                </div>
                                                <div class="nf-field-element">							
												
												<div className="nf-field-element">
												  <label className="container">
													Hindu (Sikh, Buddhiest, Jain)
													<input
													  type="radio"
													  name="hub_religion"
													  value="Hindu Sikh Buddhiest Jain"
													  checked={selectedHusbandReligion === 'Hindu Sikh Buddhiest Jain'}
													  onChange={handleChange} />
													<span className="checkmark"></span>
												  </label>
												  <label className="container">
													Muslim
													<input
													  type="radio"
													  name="hub_religion"
													  value="Muslim"
													  checked={selectedHusbandReligion === 'Muslim'}
													  onChange={handleChange}
													/>
													<span className="checkmark"></span>
												  </label>
												  <label className="container">
													Christian
													<input
													  type="radio"
													  name="hub_religion"
													  value="Christian"
													  checked={selectedHusbandReligion === 'Christian'}
													  onChange={handleChange}
													/>
													<span className="checkmark"></span>
												  </label>
												</div>
												
                                                  
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <div class="nf-input-limit"></div>
                                              <div id="nf-error-19" class="nf-error-wrap nf-error" role="alert"></div>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-33-container" class="nf-field-container listcountry-container  label-above  list-container">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-33-wrap" class="field-wrap listcountry-wrap list-wrap" data-field-id="33">
                                                <div class="nf-field-label"><label for="nf-field-33" id="nf-label-field-33" class="">Nationality (Husband) <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <select id="hub_nationality" name="hub_nationality" value={formData.hub_nationality} onChange={handleChange} required class="ninja-forms-field nf-element" >
                                                    <option value="IN" selected="selected">India</option>
                                                    <option value="AF">Afghanistan</option>
                                                    <option value="AL">Albania</option>
                                                    <option value="DZ">Algeria</option>
                                                    <option value="AS">American Samoa</option>
                                                    <option value="AD">Andorra</option>
                                                    <option value="AO">Angola</option>
                                                    <option value="AI">Anguilla</option>
                                                    <option value="AQ">Antarctica</option>
                                                    <option value="AG">Antigua And Barbuda</option>
                                                    <option value="AR">Argentina</option>
                                                    <option value="AM">Armenia</option>
                                                    <option value="AW">Aruba</option>
                                                    <option value="AU">Australia</option>
                                                    <option value="AT">Austria</option>
                                                    <option value="AZ">Azerbaijan</option>
                                                    <option value="BS">Bahamas</option>
                                                    <option value="BH">Bahrain</option>
                                                    <option value="BD">Bangladesh</option>
                                                    <option value="BB">Barbados</option>
                                                    <option value="BY">Belarus</option>
                                                    <option value="BE">Belgium</option>
                                                    <option value="BZ">Belize</option>
                                                    <option value="BJ">Benin</option>
                                                    <option value="BM">Bermuda</option>
                                                    <option value="BT">Bhutan</option>
                                                    <option value="BO">Bolivia</option>
                                                    <option value="BA">Bosnia And Herzegowina</option>
                                                    <option value="BW">Botswana</option>
                                                    <option value="BV">Bouvet Island</option>
                                                    <option value="BR">Brazil</option>
                                                    <option value="IO">British Indian Ocean Territory</option>
                                                    <option value="BN">Brunei Darussalam</option>
                                                    <option value="BG">Bulgaria</option>
                                                    <option value="BF">Burkina Faso</option>
                                                    <option value="BI">Burundi</option>
                                                    <option value="KH">Cambodia</option>
                                                    <option value="CM">Cameroon</option>
                                                    <option value="CA">Canada</option>
                                                    <option value="CV">Cape Verde</option>
                                                    <option value="KY">Cayman Islands</option>
                                                    <option value="CF">Central African Republic</option>
                                                    <option value="TD">Chad</option>
                                                    <option value="CL">Chile</option>
                                                    <option value="CN">China</option>
                                                    <option value="CX">Christmas Island</option>
                                                    <option value="CC">Cocos (Keeling) Islands</option>
                                                    <option value="CO">Colombia</option>
                                                    <option value="KM">Comoros</option>
                                                    <option value="CG">Congo</option>
                                                    <option value="CD">Congo, The Democratic Republic Of The</option>
                                                    <option value="CK">Cook Islands</option>
                                                    <option value="CR">Costa Rica</option>
                                                    <option value="CI">Cote D'Ivoire</option>
                                                    <option value="HR">Croatia (Local Name: Hrvatska)</option>
                                                    <option value="CU">Cuba</option>
                                                    <option value="CY">Cyprus</option>
                                                    <option value="CZ">Czech Republic</option>
                                                    <option value="DK">Denmark</option>
                                                    <option value="DJ">Djibouti</option>
                                                    <option value="DM">Dominica</option>
                                                    <option value="DO">Dominican Republic</option>
                                                    <option value="EC">Ecuador</option>
                                                    <option value="EG">Egypt</option>
                                                    <option value="SV">El Salvador</option>
                                                    <option value="GQ">Equatorial Guinea</option>
                                                    <option value="ER">Eritrea</option>
                                                    <option value="EE">Estonia</option>
                                                    <option value="ET">Ethiopia</option>
                                                    <option value="FK">Falkland Islands (Malvinas)</option>
                                                    <option value="FO">Faroe Islands</option>
                                                    <option value="FJ">Fiji</option>
                                                    <option value="FI">Finland</option>
                                                    <option value="FR">France</option>
                                                    <option value="FX">France, Metropolitan</option>
                                                    <option value="GF">French Guiana</option>
                                                    <option value="PF">French Polynesia</option>
                                                    <option value="TF">French Southern Territories</option>
                                                    <option value="GA">Gabon</option>
                                                    <option value="GM">Gambia</option>
                                                    <option value="GE">Georgia</option>
                                                    <option value="DE">Germany</option>
                                                    <option value="GH">Ghana</option>
                                                    <option value="GI">Gibraltar</option>
                                                    <option value="GR">Greece</option>
                                                    <option value="GL">Greenland</option>
                                                    <option value="GD">Grenada</option>
                                                    <option value="GP">Guadeloupe</option>
                                                    <option value="GU">Guam</option>
                                                    <option value="GT">Guatemala</option>
                                                    <option value="GN">Guinea</option>
                                                    <option value="GW">Guinea-Bissau</option>
                                                    <option value="GY">Guyana</option>
                                                    <option value="HT">Haiti</option>
                                                    <option value="HM">Heard And Mc Donald Islands</option>
                                                    <option value="VA">Holy See (Vatican City State)</option>
                                                    <option value="HN">Honduras</option>
                                                    <option value="HK">Hong Kong</option>
                                                    <option value="HU">Hungary</option>
                                                    <option value="IS">Iceland</option>
                                                   
                                                    <option value="ID">Indonesia</option>
                                                    <option value="IR">Iran (Islamic Republic Of)</option>
                                                    <option value="IQ">Iraq</option>
                                                    <option value="IE">Ireland</option>
                                                    <option value="IL">Israel</option>
                                                    <option value="IT">Italy</option>
                                                    <option value="JM">Jamaica</option>
                                                    <option value="JP">Japan</option>
                                                    <option value="JO">Jordan</option>
                                                    <option value="KZ">Kazakhstan</option>
                                                    <option value="KE">Kenya</option>
                                                    <option value="KI">Kiribati</option>
                                                    <option value="KP">Korea, Democratic People's Republic Of</option>
                                                    <option value="KR">Korea, Republic Of</option>
                                                    <option value="KW">Kuwait</option>
                                                    <option value="KG">Kyrgyzstan</option>
                                                    <option value="LA">Lao People's Democratic Republic</option>
                                                    <option value="LV">Latvia</option>
                                                    <option value="LB">Lebanon</option>
                                                    <option value="LS">Lesotho</option>
                                                    <option value="LR">Liberia</option>
                                                    <option value="LY">Libyan Arab Jamahiriya</option>
                                                    <option value="LI">Liechtenstein</option>
                                                    <option value="LT">Lithuania</option>
                                                    <option value="LU">Luxembourg</option>
                                                    <option value="MO">Macau</option>
                                                    <option value="MK">Macedonia, Former Yugoslav Republic Of</option>
                                                    <option value="MG">Madagascar</option>
                                                    <option value="MW">Malawi</option>
                                                    <option value="MY">Malaysia</option>
                                                    <option value="MV">Maldives</option>
                                                    <option value="ML">Mali</option>
                                                    <option value="MT">Malta</option>
                                                    <option value="MH">Marshall Islands</option>
                                                    <option value="MQ">Martinique</option>
                                                    <option value="MR">Mauritania</option>
                                                    <option value="MU">Mauritius</option>
                                                    <option value="YT">Mayotte</option>
                                                    <option value="MX">Mexico</option>
                                                    <option value="FM">Micronesia, Federated States Of</option>
                                                    <option value="MD">Moldova, Republic Of</option>
                                                    <option value="MC">Monaco</option>
                                                    <option value="MN">Mongolia</option>
                                                    <option value="ME">Montenegro</option>
                                                    <option value="MS">Montserrat</option>
                                                    <option value="MA">Morocco</option>
                                                    <option value="MZ">Mozambique</option>
                                                    <option value="MM">Myanmar</option>
                                                    <option value="NA">Namibia</option>
                                                    <option value="NR">Nauru</option>
                                                    <option value="NP">Nepal</option>
                                                    <option value="NL">Netherlands</option>
                                                    <option value="AN">Netherlands Antilles</option>
                                                    <option value="NC">New Caledonia</option>
                                                    <option value="NZ">New Zealand</option>
                                                    <option value="NI">Nicaragua</option>
                                                    <option value="NE">Niger</option>
                                                    <option value="NG">Nigeria</option>
                                                    <option value="NU">Niue</option>
                                                    <option value="NF">Norfolk Island</option>
                                                    <option value="MP">Northern Mariana Islands</option>
                                                    <option value="NO">Norway</option>
                                                    <option value="OM">Oman</option>
                                                    <option value="PK">Pakistan</option>
                                                    <option value="PW">Palau</option>
                                                    <option value="PA">Panama</option>
                                                    <option value="PG">Papua New Guinea</option>
                                                    <option value="PY">Paraguay</option>
                                                    <option value="PE">Peru</option>
                                                    <option value="PH">Philippines</option>
                                                    <option value="PN">Pitcairn</option>
                                                    <option value="PL">Poland</option>
                                                    <option value="PT">Portugal</option>
                                                    <option value="PR">Puerto Rico</option>
                                                    <option value="QA">Qatar</option>
                                                    <option value="RE">Reunion</option>
                                                    <option value="RO">Romania</option>
                                                    <option value="RU">Russian Federation</option>
                                                    <option value="RW">Rwanda</option>
                                                    <option value="KN">Saint Kitts And Nevis</option>
                                                    <option value="LC">Saint Lucia</option>
                                                    <option value="VC">Saint Vincent And The Grenadines</option>
                                                    <option value="WS">Samoa</option>
                                                    <option value="SM">San Marino</option>
                                                    <option value="ST">Sao Tome And Principe</option>
                                                    <option value="SA">Saudi Arabia</option>
                                                    <option value="SN">Senegal</option>
                                                    <option value="RS">Serbia</option>
                                                    <option value="SC">Seychelles</option>
                                                    <option value="SL">Sierra Leone</option>
                                                    <option value="SG">Singapore</option>
                                                    <option value="SK">Slovakia (Slovak Republic)</option>
                                                    <option value="SI">Slovenia</option>
                                                    <option value="SB">Solomon Islands</option>
                                                    <option value="SO">Somalia</option>
                                                    <option value="ZA">South Africa</option>
                                                    <option value="GS">South Georgia, South Sandwich Islands</option>
                                                    <option value="ES">Spain</option>
                                                    <option value="LK">Sri Lanka</option>
                                                    <option value="SH">St. Helena</option>
                                                    <option value="PM">St. Pierre And Miquelon</option>
                                                    <option value="SD">Sudan</option>
                                                    <option value="SR">Suriname</option>
                                                    <option value="SJ">Svalbard And Jan Mayen Islands</option>
                                                    <option value="SZ">Swaziland</option>
                                                    <option value="SE">Sweden</option>
                                                    <option value="CH">Switzerland</option>
                                                    <option value="SY">Syrian Arab Republic</option>
                                                    <option value="TW">Taiwan</option>
                                                    <option value="TJ">Tajikistan</option>
                                                    <option value="TZ">Tanzania, United Republic Of</option>
                                                    <option value="TH">Thailand</option>
                                                    <option value="TL">Timor-Leste (East Timor)</option>
                                                    <option value="TG">Togo</option>
                                                    <option value="TK">Tokelau</option>
                                                    <option value="TO">Tonga</option>
                                                    <option value="TT">Trinidad And Tobago</option>
                                                    <option value="TN">Tunisia</option>
                                                    <option value="TR">Turkey</option>
                                                    <option value="TM">Turkmenistan</option>
                                                    <option value="TC">Turks And Caicos Islands</option>
                                                    <option value="TV">Tuvalu</option>
                                                    <option value="UG">Uganda</option>
                                                    <option value="UA">Ukraine</option>
                                                    <option value="AE">United Arab Emirates</option>
                                                    <option value="GB">United Kingdom</option>
                                                    <option value="US">United States</option>
                                                    <option value="UM">United States Minor Outlying Islands</option>
                                                    <option value="UY">Uruguay</option>
                                                    <option value="UZ">Uzbekistan</option>
                                                    <option value="VU">Vanuatu</option>
                                                    <option value="VE">Venezuela</option>
                                                    <option value="VN">Viet Nam</option>
                                                    <option value="VG">Virgin Islands (British)</option>
                                                    <option value="VI">Virgin Islands (U.S.)</option>
                                                    <option value="WF">Wallis And Futuna Islands</option>
                                                    <option value="EH">Western Sahara</option>
                                                    <option value="YE">Yemen</option>
                                                    <option value="YU">Yugoslavia</option>
                                                    <option value="ZM">Zambia</option>
                                                    <option value="ZW">Zimbabwe</option>
                                                  </select>
                                                  <div for="nf-field-33"></div>
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-33" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-34-container" class="nf-field-container phone-container  label-above  textbox-container">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-34-wrap" class="field-wrap phone-wrap textbox-wrap" data-field-id="34">
                                                <div class="nf-field-label"><label for="nf-field-34" id="nf-label-field-34" class="">Mobile <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <input type="tel" class="ninja-forms-field nf-element" id="hus_phone" name="hus_phone"  placeholder="Enter Husband's Mobile Number" value={formData.hus_phone} onChange={handleChange} required  />
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-34" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-35-container" class="nf-field-container email-container  label-above ">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-35-wrap" class="field-wrap email-wrap" data-field-id="35">
                                                <div class="nf-field-label"><label for="nf-field-35" id="nf-label-field-35" class="">Email <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <input type="email"  class="ninja-forms-field nf-element" id="hus_email" name="hus_email" placeholder="Enter Husband's Email" value={formData.hus_email} onChange={handleChange} required />
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-35" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-36-container" class="nf-field-container firstname-container  label-above ">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-36-wrap" class="field-wrap firstname-wrap" data-field-id="36">
                                                <div class="nf-field-label"><label for="nf-field-36" id="nf-label-field-36" class="">Name <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <input type="text"  class="ninja-forms-field nf-element" id="wife_name" name="wife_name" placeholder="Enter Wife's Name" value={formData.wife_name} onChange={handleChange} required/>
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-36" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-37-container" class="nf-field-container firstname-container  label-above ">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-37-wrap" class="field-wrap firstname-wrap" data-field-id="37">
                                                <div class="nf-field-label"><label for="nf-field-37" id="nf-label-field-37" class="">Father's Name <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <input type="text"  class="ninja-forms-field nf-element" id="wife_father" name="wife_father" placeholder="Enter Wife's Father Name" value={formData.wife_father} onChange={handleChange} required/>
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-37" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-38-container" class="nf-field-container date-container  label-above ">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-38-wrap" class="field-wrap date-wrap" data-field-id="38">
                                                <div class="nf-field-label"><label for="nf-field-38" id="nf-label-field-38" class="">Wife's Date of Birth <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <div class="pikaday__container">
                                                    
                                                    <input class="ninja-forms-field nf-element datepicker pikaday__display pikaday__display--pikaday form-control input" placeholder="" tabindex="0" type="date" name="wife_dob" id="wife_dob" value={formData.wife_dob} onChange={handleChange} required />
                                                  </div>
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-38" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-39-container" class="nf-field-container address-container  label-above ">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-39-wrap" class="field-wrap address-wrap" data-field-id="39">
                                                <div class="nf-field-label"><label for="nf-field-39" id="nf-label-field-39" class="">Address <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <input type="text" class="ninja-forms-field nf-element" id="wife_address" name="wife_address"  placeholder="Enter Wife's Address" value={formData.wife_address} onChange={handleChange} required />
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-39" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-19-container" class="nf-field-container listradio-container  label-above  list-container">
                                              <div class="nf-before-field"> </div>
                                              <div class="nf-field">
                                              <div id="nf-field-19-wrap" class="field-wrap" data-field-id="19">
                                                <div class="nf-field-label">
                                                  <label for="nf-field-19" id="nf-label-field-19" class="">Religion (Wife) <span class="ninja-forms-req-symbol">*</span> </label>
                                                </div>
                                                <div class="nf-field-element">
												
													<div className="nf-field-element">
													  <label className="container">
														Hindu (Sikh, Buddhiest, Jain)
														<input
														  type="radio"
														  name="wife_religion"
														  value="Hindu Sikh Buddhiest Jain"
														  checked={selectedWifeReligion === 'Hindu Sikh Buddhiest Jain'}
														  onChange={handleChange}
														 />
														<span className="checkmark"></span>
													  </label>
													  <label className="container">
														Muslim
														<input
														  type="radio"
														  name="wife_religion"
														  value="Muslim"
														  checked={selectedWifeReligion === 'Muslim'}
														  onChange={handleChange}
														/>
														<span className="checkmark"></span>
													  </label>
													  <label className="container">
														Christian
														<input
														  type="radio"
														  name="wife_religion"
														  value="Christian"
														  checked={selectedWifeReligion === 'Christian'}
														  onChange={handleChange}
														/>
														<span className="checkmark"></span>
													  </label>
													</div>								
												
                                                  
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <div class="nf-input-limit"></div>
                                              <div id="nf-error-19" class="nf-error-wrap nf-error" role="alert"></div>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-41-container" class="nf-field-container listcountry-container  label-above  list-container">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-41-wrap" class="field-wrap listcountry-wrap list-wrap" data-field-id="41">
                                                <div class="nf-field-label"><label for="nf-field-41" id="nf-label-field-41" class="">Nationality (Wife) <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <select id="wife_nationality" name="wife_nationality"  class="ninja-forms-field nf-element" value={formData.wife_nationality} onChange={handleChange} required>
                                                    <option value="IN" selected="selected">India</option>
                                                    <option value="AF">Afghanistan</option>
                                                    <option value="AL">Albania</option>
                                                    <option value="DZ">Algeria</option>
                                                    <option value="AS">American Samoa</option>
                                                    <option value="AD">Andorra</option>
                                                    <option value="AO">Angola</option>
                                                    <option value="AI">Anguilla</option>
                                                    <option value="AQ">Antarctica</option>
                                                    <option value="AG">Antigua And Barbuda</option>
                                                    <option value="AR">Argentina</option>
                                                    <option value="AM">Armenia</option>
                                                    <option value="AW">Aruba</option>
                                                    <option value="AU">Australia</option>
                                                    <option value="AT">Austria</option>
                                                    <option value="AZ">Azerbaijan</option>
                                                    <option value="BS">Bahamas</option>
                                                    <option value="BH">Bahrain</option>
                                                    <option value="BD">Bangladesh</option>
                                                    <option value="BB">Barbados</option>
                                                    <option value="BY">Belarus</option>
                                                    <option value="BE">Belgium</option>
                                                    <option value="BZ">Belize</option>
                                                    <option value="BJ">Benin</option>
                                                    <option value="BM">Bermuda</option>
                                                    <option value="BT">Bhutan</option>
                                                    <option value="BO">Bolivia</option>
                                                    <option value="BA">Bosnia And Herzegowina</option>
                                                    <option value="BW">Botswana</option>
                                                    <option value="BV">Bouvet Island</option>
                                                    <option value="BR">Brazil</option>
                                                    <option value="IO">British Indian Ocean Territory</option>
                                                    <option value="BN">Brunei Darussalam</option>
                                                    <option value="BG">Bulgaria</option>
                                                    <option value="BF">Burkina Faso</option>
                                                    <option value="BI">Burundi</option>
                                                    <option value="KH">Cambodia</option>
                                                    <option value="CM">Cameroon</option>
                                                    <option value="CA">Canada</option>
                                                    <option value="CV">Cape Verde</option>
                                                    <option value="KY">Cayman Islands</option>
                                                    <option value="CF">Central African Republic</option>
                                                    <option value="TD">Chad</option>
                                                    <option value="CL">Chile</option>
                                                    <option value="CN">China</option>
                                                    <option value="CX">Christmas Island</option>
                                                    <option value="CC">Cocos (Keeling) Islands</option>
                                                    <option value="CO">Colombia</option>
                                                    <option value="KM">Comoros</option>
                                                    <option value="CG">Congo</option>
                                                    <option value="CD">Congo, The Democratic Republic Of The</option>
                                                    <option value="CK">Cook Islands</option>
                                                    <option value="CR">Costa Rica</option>
                                                    <option value="CI">Cote D'Ivoire</option>
                                                    <option value="HR">Croatia (Local Name: Hrvatska)</option>
                                                    <option value="CU">Cuba</option>
                                                    <option value="CY">Cyprus</option>
                                                    <option value="CZ">Czech Republic</option>
                                                    <option value="DK">Denmark</option>
                                                    <option value="DJ">Djibouti</option>
                                                    <option value="DM">Dominica</option>
                                                    <option value="DO">Dominican Republic</option>
                                                    <option value="EC">Ecuador</option>
                                                    <option value="EG">Egypt</option>
                                                    <option value="SV">El Salvador</option>
                                                    <option value="GQ">Equatorial Guinea</option>
                                                    <option value="ER">Eritrea</option>
                                                    <option value="EE">Estonia</option>
                                                    <option value="ET">Ethiopia</option>
                                                    <option value="FK">Falkland Islands (Malvinas)</option>
                                                    <option value="FO">Faroe Islands</option>
                                                    <option value="FJ">Fiji</option>
                                                    <option value="FI">Finland</option>
                                                    <option value="FR">France</option>
                                                    <option value="FX">France, Metropolitan</option>
                                                    <option value="GF">French Guiana</option>
                                                    <option value="PF">French Polynesia</option>
                                                    <option value="TF">French Southern Territories</option>
                                                    <option value="GA">Gabon</option>
                                                    <option value="GM">Gambia</option>
                                                    <option value="GE">Georgia</option>
                                                    <option value="DE">Germany</option>
                                                    <option value="GH">Ghana</option>
                                                    <option value="GI">Gibraltar</option>
                                                    <option value="GR">Greece</option>
                                                    <option value="GL">Greenland</option>
                                                    <option value="GD">Grenada</option>
                                                    <option value="GP">Guadeloupe</option>
                                                    <option value="GU">Guam</option>
                                                    <option value="GT">Guatemala</option>
                                                    <option value="GN">Guinea</option>
                                                    <option value="GW">Guinea-Bissau</option>
                                                    <option value="GY">Guyana</option>
                                                    <option value="HT">Haiti</option>
                                                    <option value="HM">Heard And Mc Donald Islands</option>
                                                    <option value="VA">Holy See (Vatican City State)</option>
                                                    <option value="HN">Honduras</option>
                                                    <option value="HK">Hong Kong</option>
                                                    <option value="HU">Hungary</option>
                                                    <option value="IS">Iceland</option>                                              
                                                    <option value="ID">Indonesia</option>
                                                    <option value="IR">Iran (Islamic Republic Of)</option>
                                                    <option value="IQ">Iraq</option>
                                                    <option value="IE">Ireland</option>
                                                    <option value="IL">Israel</option>
                                                    <option value="IT">Italy</option>
                                                    <option value="JM">Jamaica</option>
                                                    <option value="JP">Japan</option>
                                                    <option value="JO">Jordan</option>
                                                    <option value="KZ">Kazakhstan</option>
                                                    <option value="KE">Kenya</option>
                                                    <option value="KI">Kiribati</option>
                                                    <option value="KP">Korea, Democratic People's Republic Of</option>
                                                    <option value="KR">Korea, Republic Of</option>
                                                    <option value="KW">Kuwait</option>
                                                    <option value="KG">Kyrgyzstan</option>
                                                    <option value="LA">Lao People's Democratic Republic</option>
                                                    <option value="LV">Latvia</option>
                                                    <option value="LB">Lebanon</option>
                                                    <option value="LS">Lesotho</option>
                                                    <option value="LR">Liberia</option>
                                                    <option value="LY">Libyan Arab Jamahiriya</option>
                                                    <option value="LI">Liechtenstein</option>
                                                    <option value="LT">Lithuania</option>
                                                    <option value="LU">Luxembourg</option>
                                                    <option value="MO">Macau</option>
                                                    <option value="MK">Macedonia, Former Yugoslav Republic Of</option>
                                                    <option value="MG">Madagascar</option>
                                                    <option value="MW">Malawi</option>
                                                    <option value="MY">Malaysia</option>
                                                    <option value="MV">Maldives</option>
                                                    <option value="ML">Mali</option>
                                                    <option value="MT">Malta</option>
                                                    <option value="MH">Marshall Islands</option>
                                                    <option value="MQ">Martinique</option>
                                                    <option value="MR">Mauritania</option>
                                                    <option value="MU">Mauritius</option>
                                                    <option value="YT">Mayotte</option>
                                                    <option value="MX">Mexico</option>
                                                    <option value="FM">Micronesia, Federated States Of</option>
                                                    <option value="MD">Moldova, Republic Of</option>
                                                    <option value="MC">Monaco</option>
                                                    <option value="MN">Mongolia</option>
                                                    <option value="ME">Montenegro</option>
                                                    <option value="MS">Montserrat</option>
                                                    <option value="MA">Morocco</option>
                                                    <option value="MZ">Mozambique</option>
                                                    <option value="MM">Myanmar</option>
                                                    <option value="NA">Namibia</option>
                                                    <option value="NR">Nauru</option>
                                                    <option value="NP">Nepal</option>
                                                    <option value="NL">Netherlands</option>
                                                    <option value="AN">Netherlands Antilles</option>
                                                    <option value="NC">New Caledonia</option>
                                                    <option value="NZ">New Zealand</option>
                                                    <option value="NI">Nicaragua</option>
                                                    <option value="NE">Niger</option>
                                                    <option value="NG">Nigeria</option>
                                                    <option value="NU">Niue</option>
                                                    <option value="NF">Norfolk Island</option>
                                                    <option value="MP">Northern Mariana Islands</option>
                                                    <option value="NO">Norway</option>
                                                    <option value="OM">Oman</option>
                                                    <option value="PK">Pakistan</option>
                                                    <option value="PW">Palau</option>
                                                    <option value="PA">Panama</option>
                                                    <option value="PG">Papua New Guinea</option>
                                                    <option value="PY">Paraguay</option>
                                                    <option value="PE">Peru</option>
                                                    <option value="PH">Philippines</option>
                                                    <option value="PN">Pitcairn</option>
                                                    <option value="PL">Poland</option>
                                                    <option value="PT">Portugal</option>
                                                    <option value="PR">Puerto Rico</option>
                                                    <option value="QA">Qatar</option>
                                                    <option value="RE">Reunion</option>
                                                    <option value="RO">Romania</option>
                                                    <option value="RU">Russian Federation</option>
                                                    <option value="RW">Rwanda</option>
                                                    <option value="KN">Saint Kitts And Nevis</option>
                                                    <option value="LC">Saint Lucia</option>
                                                    <option value="VC">Saint Vincent And The Grenadines</option>
                                                    <option value="WS">Samoa</option>
                                                    <option value="SM">San Marino</option>
                                                    <option value="ST">Sao Tome And Principe</option>
                                                    <option value="SA">Saudi Arabia</option>
                                                    <option value="SN">Senegal</option>
                                                    <option value="RS">Serbia</option>
                                                    <option value="SC">Seychelles</option>
                                                    <option value="SL">Sierra Leone</option>
                                                    <option value="SG">Singapore</option>
                                                    <option value="SK">Slovakia (Slovak Republic)</option>
                                                    <option value="SI">Slovenia</option>
                                                    <option value="SB">Solomon Islands</option>
                                                    <option value="SO">Somalia</option>
                                                    <option value="ZA">South Africa</option>
                                                    <option value="GS">South Georgia, South Sandwich Islands</option>
                                                    <option value="ES">Spain</option>
                                                    <option value="LK">Sri Lanka</option>
                                                    <option value="SH">St. Helena</option>
                                                    <option value="PM">St. Pierre And Miquelon</option>
                                                    <option value="SD">Sudan</option>
                                                    <option value="SR">Suriname</option>
                                                    <option value="SJ">Svalbard And Jan Mayen Islands</option>
                                                    <option value="SZ">Swaziland</option>
                                                    <option value="SE">Sweden</option>
                                                    <option value="CH">Switzerland</option>
                                                    <option value="SY">Syrian Arab Republic</option>
                                                    <option value="TW">Taiwan</option>
                                                    <option value="TJ">Tajikistan</option>
                                                    <option value="TZ">Tanzania, United Republic Of</option>
                                                    <option value="TH">Thailand</option>
                                                    <option value="TL">Timor-Leste (East Timor)</option>
                                                    <option value="TG">Togo</option>
                                                    <option value="TK">Tokelau</option>
                                                    <option value="TO">Tonga</option>
                                                    <option value="TT">Trinidad And Tobago</option>
                                                    <option value="TN">Tunisia</option>
                                                    <option value="TR">Turkey</option>
                                                    <option value="TM">Turkmenistan</option>
                                                    <option value="TC">Turks And Caicos Islands</option>
                                                    <option value="TV">Tuvalu</option>
                                                    <option value="UG">Uganda</option>
                                                    <option value="UA">Ukraine</option>
                                                    <option value="AE">United Arab Emirates</option>
                                                    <option value="GB">United Kingdom</option>
                                                    <option value="US">United States</option>
                                                    <option value="UM">United States Minor Outlying Islands</option>
                                                    <option value="UY">Uruguay</option>
                                                    <option value="UZ">Uzbekistan</option>
                                                    <option value="VU">Vanuatu</option>
                                                    <option value="VE">Venezuela</option>
                                                    <option value="VN">Viet Nam</option>
                                                    <option value="VG">Virgin Islands (British)</option>
                                                    <option value="VI">Virgin Islands (U.S.)</option>
                                                    <option value="WF">Wallis And Futuna Islands</option>
                                                    <option value="EH">Western Sahara</option>
                                                    <option value="YE">Yemen</option>
                                                    <option value="YU">Yugoslavia</option>
                                                    <option value="ZM">Zambia</option>
                                                    <option value="ZW">Zimbabwe</option>
                                                  </select>
                                                  <div for="nf-field-41"></div>
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-41" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-42-container" class="nf-field-container phone-container  label-above  textbox-container">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-42-wrap" class="field-wrap phone-wrap textbox-wrap" data-field-id="42">
                                                <div class="nf-field-label"><label for="nf-field-42" id="nf-label-field-42" class="">Mobile <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <input type="tel"  class="ninja-forms-field nf-element" id="wife_phone" name="wife_phone"  placeholder="Enter Wife's Mobile Number" value={formData.wife_phone} onChange={handleChange} required />
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-42" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-43-container" class="nf-field-container email-container  label-above ">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-43-wrap" class="field-wrap email-wrap" data-field-id="43">
                                                <div class="nf-field-label"><label for="nf-field-43" id="nf-label-field-43" class="">Email <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <input type="email" class="ninja-forms-field nf-element" id="wife_email" name="wife_email"  placeholder="Enter Wife's Email" value={formData.wife_email} onChange={handleChange} required  />
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-43" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-44-container" class="nf-field-container date-container  label-above ">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-44-wrap" class="field-wrap date-wrap" data-field-id="44">
                                                <div class="nf-field-label"><label for="nf-field-44" id="nf-label-field-44" class="">Date of Marriage <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <div class="pikaday__container">
                                                    
                                                    <input class="ninja-forms-field nf-element datepicker pikaday__display pikaday__display--pikaday form-control input" placeholder=""  tabindex="0" type="date" name="marriage_date" id="marriage_date"  value={formData.marriage_date} onChange={handleChange} required />
                                                  </div>
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-44" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-45-container" class="nf-field-container listselect-container  label-above  list-container">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-45-wrap" class="field-wrap listselect-wrap list-wrap list-select-wrap" data-field-id="45">
                                                <div class="nf-field-label"><label for="nf-field-45" id="nf-label-field-45" class="">Place of Marriage <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <select id="marriage_place" name="marriage_place"  class="ninja-forms-field nf-element" value={formData.marriage_place} onChange={handleChange} required >
                                                    <option value="delhi" selected="selected">Delhi</option>
                                                    <option value="andra-pradesh">Andra Pradesh</option>
                                                    <option value="arunachal-pradesh">Arunachal Pradesh</option>
                                                    <option value="assam">Assam</option>
                                                    <option value="bihar">Bihar</option>
                                                    <option value="chhattisgarh">Chhattisgarh</option>
                                                    <option value="goa">Goa</option>
                                                    <option value="gujarat">Gujarat</option>
                                                    <option value="haryana">Haryana</option>
                                                    <option value="himachal-pradesh">Himachal Pradesh</option>
                                                    <option value="jammu-and-kashmir">Jammu and Kashmir</option>
                                                    <option value="jharkhand">Jharkhand</option>
                                                    <option value="karnataka">Karnataka</option>
                                                    <option value="kerala">Kerala</option>
                                                    <option value="madya-pradesh">Madya Pradesh</option>
                                                    <option value="maharashtra">Maharashtra</option>
                                                    <option value="manipur">Manipur</option>
                                                    <option value="meghalaya">Meghalaya</option>
                                                    <option value="mizoram">Mizoram</option>
                                                    <option value="nagaland">Nagaland</option>
                                                    <option value="orissa">Orissa</option>
                                                    <option value="punjab">Punjab</option>
                                                    <option value="rajasthan">Rajasthan</option>
                                                    <option value="sikkim">Sikkim</option>
                                                    <option value="tamil-nadu">Tamil Nadu</option>
                                                    <option value="tripura">Tripura</option>
                                                    <option value="uttaranchal">Uttaranchal</option>
                                                    <option value="uttar-pradesh">Uttar Pradesh</option>
                                                    <option value="west-bengal">West Bengal</option>
                                                    <option value="andaman-and-nicobar-islands">Andaman and Nicobar Islands</option>
                                                    <option value="chandigarh">Chandigarh</option>
                                                    <option value="dadar-and-nagar-haveli">Dadar and Nagar Haveli</option>
                                                    <option value="daman-and-diu">Daman and Diu</option>
                                                    <option value="lakshadeep">Lakshadeep</option>
                                                    <option value="other">Other</option>
                                                    <option value="out-of-india">Out of India</option>
                                                  </select>
                                                  <div for="nf-field-45"></div>
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-45" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-46-container" class="nf-field-container listselect-container  label-above  list-container">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-46-wrap" class="field-wrap listselect-wrap list-wrap list-select-wrap" data-field-id="46">
                                                <div class="nf-field-label"><label for="nf-field-46" id="nf-label-field-46" class="">I/We Are Filing Divorce <span class="ninja-forms-req-symbol">*</span> </label></div>
                                                <div class="nf-field-element">
                                                  <select id="divorce_type" name="divorce_type"  class="ninja-forms-field nf-element" value={formData.divorce_type} onChange={handleChange} required>
												  
                                                    <option value="By Mutual Consent Applied by Husband  Wife" selected="selected">By Mutual Consent Applied by Husband &amp; Wife</option>
                                                    <option value="One Sided Applied by Husband">One Sided Applied by Husband</option>
                                                    <option value="One Sided Applied by Wife">One Sided Applied by Wife</option>
                                                  </select>
                                                  <div for="nf-field-46"></div>
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-46" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-26-container" class="nf-field-container checkbox-container  label-right ">
                                              <div class="nf-before-field"> </div>
                                              <div class="nf-field">
                                              <div id="nf-field-26-wrap" class="field-wrap checkbox-wrap" data-field-id="26">
                                                <label class="container">I declare the above particulars are true to the best of my knowledge &amp; befief. <span class="ninja-forms-req-symbol">*</span>
                                                <input type="checkbox" name="declare" id="declare" value="Y" required checked />
                                                <span class="checkmark"></span>
                                                </label>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <div class="nf-input-limit"></div>
                                              <div id="nf-error-26" class="nf-error-wrap nf-error" role="alert"></div>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-26-container" class="nf-field-container checkbox-container  label-right ">
                                              <div class="nf-before-field"> </div>
                                              <div class="nf-field">
                                              <div id="nf-field-26-wrap" class="field-wrap checkbox-wrap" data-field-id="26">
                                                <label class="container">I accept the Terms &amp; Conditions <span class="ninja-forms-req-symbol">*</span>
                                                <input type="checkbox" name="term" id="term" value="Y" required checked />
                                                <span class="checkmark"></span>
                                                </label>
                                              </div>
                                              
                                              <div class="nf-field-description">
                                                <a href="/terms-condition" target="_blank"><font color="blue">Terms &amp; Conditions</font></a>
                                                
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <div class="nf-input-limit"></div>
                                              <div id="nf-error-26" class="nf-error-wrap nf-error" role="alert"></div>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-49-container" class="nf-field-container recaptcha-container  label-above  textbox-container">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-49-wrap" class="field-wrap recaptcha-wrap textbox-wrap" data-field-id="49">
                                                <div class="nf-field-label"><label for="nf-field-49" id="nf-label-field-49" class="">  </label></div>
                                                <div class="nf-field-element">
                                                  <input id="nf-field-49" name="nf-field-49" class="ninja-forms-field nf-element" type="hidden" value="" />
                                                  <div class="g-recaptcha" data-callback="nf_recaptcha_response_49" data-theme="light" data-sitekey="6LcMkoMaAAAAANT0KUishOUimENlsw5HOIb70S5Z" data-fieldid="49">
                                                    <iframe style={{ display: 'none' }} title="mapcourt"></iframe>
                                                  </div>
                                                </div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-49" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                          <nf-field>
                                            <div id="nf-field-10-container" class="nf-field-container submit-container  label-above  textbox-container">
                                              <div class="nf-before-field">
                                              <nf-section>
                                              </nf-section>
                                              </div>
                                              <div class="nf-field">
                                              <div id="nf-field-10-wrap" class="field-wrap submit-wrap textbox-wrap" data-field-id="10">
                                                <div class="nf-field-label"></div>
                                                <div class="nf-field-element">
                                                  <input type="hidden" name="divorce_action" value="yes" />
                                                  <input id="nf-field-10" class="ninja-forms-field nf-element " name="submit" type="submit" value="Submit" />
                                                </div>
                                                <div class="nf-error-wrap"></div>
                                              </div>
                                              </div>
                                              <div class="nf-after-field">
                                              <nf-section>
                                                <div class="nf-input-limit"></div>
                                                <div id="nf-error-10" class="nf-error-wrap nf-error" role="alert"></div>
                                              </nf-section>
                                              </div>
                                            </div>
                                          </nf-field>
                                        </nf-fields-wrap>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                  
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>

            </div>
          </div>
          <IncludeSidebar/>
        </div>
        <IncludeMariigeCertificate/>
      </div>
      <Footer />
    </>
  );
}

export default Divorce;
