import React from 'react';

const Footer = () => {
  return (
    <footer id="colophon" className="site-footer" role="contentinfo">
      <div id="supplementary">
        <div id="footer-sidebar" className="footer-sidebar widget-area" role="complementary">
          <aside id="srs_shc_widget-3" className="widget widget_srs_shc_widget">
            <h1 className="widget-title">Hit Counter</h1>
            <span className='visitors'>183066</span>
          </aside>
          <aside id="text-10" className="widget widget_text">
            <h1 className="widget-title">Govt. Recognized &#038; Registered</h1>
            <div className="textwidget"><p></p></div>
          </aside>
          <aside id="nav_menu-3" className="widget widget_nav_menu">
            <nav className="menu-primary-menu-container" aria-label="Menu">
              <ul className="menu">
                <li className="menu-item menu-item-home"><a href="/">Home</a></li>
                <li className="menu-item"><a href="/about-us">About Us</a></li>
                <li className="menu-item"><a href="/marriage-certificate">Marriage Certificate</a></li>
                <li className="menu-item"><a href="/documents">Documents</a></li>
                <li className="menu-item"><a href="/faq">FAQ</a></li>
                <li className="menu-item"><a href="/online-payment">Online Payment</a></li>
                <li className="menu-item"><a href="/fee">Fee</a></li>
                <li className="menu-item"><a href="/divorce">Divorce</a></li>
                <li className="menu-item"><a href="/legal-help">Legal Help</a></li>
                <li className="menu-item"><a href="/contact-us">Contact Us</a></li>
              </ul>
            </nav>
          </aside>
          <aside id="nav_menu-3" className="widget widget_nav_menu">
            <nav className="menu-primary-menu-container" aria-label="Menu">
              <ul className="menu">
                <li className="menu-item"><a href="/privacy-policy">Privacy Policy</a></li>
                <li className="menu-item"><a href="/refund-policy">Refund Policy</a></li>
                <li className="menu-item"><a href="/terms-conditions">Terms & Conditions</a></li>
              </ul>
            </nav>
          </aside>
        </div>
      </div>
      <div className="site-info">
        <a href="#" className="imprint">Powered by Court Marriage Delhi</a>
      </div>
      <div className="visible-xs whatsapp-button">
        <a href="https://api.whatsapp.com/send?phone=+919999985852&amp;text=Hello, I wanted to inquire for Court Marriage. Please Call Back me !" target="_blank" rel="noopener noreferrer">
          <div className="rs_whatsapp_icon"></div>
        </a>
      </div>
      <div className="rs_mobile_query">
        <a href="tel:9999985852">Call Now</a>
      </div>
      <style jsx>{`
        .rs_whatsapp_icon {
          display: block;
          position: fixed;
          bottom: 50px;
          width: 70px;
          z-index: 999;
          height: 70px;
          margin: 0 auto;
          background-image: url('/path/to/whatsapp1.png');
          background-size: cover;
        }

        .rs_mobile_query {
          display: none;
          width: 100%;
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 999;
        }

        .rs_mobile_query a {
          padding: 8px;
          font-size: 25px;
          text-align: center;
          background: #099603;
          color: white;
          width: 100%;
          display: inline-block;
          font-weight: bold;
          margin-top: 10px;
        }

        @media only screen and (max-width: 767px) {
          .rs_mobile_query {
            display: block;
            position: fixed;
          }
          .rs_mobile_query a {
            margin-top: 0;
          }
        }
      `}</style>
    </footer>
  );
};

export default Footer;
