import React, { useEffect } from 'react';

const IncludeMarriageCertificate = () => {
  useEffect(() => {
    if (!window.googleTranslateElementInit2) {
      const script1 = document.createElement('script');
      script1.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit2';
      script1.async = true;
      document.body.appendChild(script1);

      const script2 = document.createElement('script');
      script2.innerHTML = `
        function googleTranslateElementInit2() {
          new google.translate.TranslateElement({pageLanguage: 'en', autoDisplay: false}, 'google_translate_element2');
        }

        function GTranslateGetCurrentLang() {
          var keyValue = document['cookie'].match('(^|;) ?googtrans=([^;]*)(;|$)');
          return keyValue ? keyValue[2].split('/')[2] : null;
        }

        function GTranslateFireEvent(element, event) {
          try {
            if (document.createEventObject) {
              var evt = document.createEventObject();
              element.fireEvent('on' + event, evt);
            } else {
              var evt = document.createEvent('HTMLEvents');
              evt.initEvent(event, true, true);
              element.dispatchEvent(evt);
            }
          } catch (e) {}
        }

        function doGTranslate(lang_pair) {
          if (lang_pair.value) lang_pair = lang_pair.value;
          if (lang_pair == '') return;
          var lang = lang_pair.split('|')[1];
          if (GTranslateGetCurrentLang() == null && lang == lang_pair.split('|')[0]) return;
          var teCombo;
          var sel = document.getElementsByTagName('select');
          for (var i = 0; i < sel.length; i++)
            if (sel[i].className.indexOf('goog-te-combo') != -1) {
              teCombo = sel[i];
              break;
            }
          if (document.getElementById('google_translate_element2') == null ||
            document.getElementById('google_translate_element2').innerHTML.length == 0 ||
            teCombo.length == 0 ||
            teCombo.innerHTML.length == 0) {
            setTimeout(function () {
              doGTranslate(lang_pair)
            }, 500)
          } else {
            teCombo.value = lang;
            GTranslateFireEvent(teCombo, 'change');
            GTranslateFireEvent(teCombo, 'change')
          }
        }

        (function gt_jquery_ready() {
          if (!window.jQuery || !jQuery.fn.click) return setTimeout(gt_jquery_ready, 20);
          if (GTranslateGetCurrentLang() != null) jQuery(document).ready(function () {
            var lang_html = jQuery('div.switcher div.option').find('img[alt="' + GTranslateGetCurrentLang() + '"]').parent().html();
            if (typeof lang_html != 'undefined') jQuery('div.switcher div.selected a').html(lang_html.replace('data-gt-lazy-', ''));
          });
        })();
      `;
      document.body.appendChild(script2);

      return () => {
        document.body.removeChild(script1);
        document.body.removeChild(script2);
      };
    }
  }, []);

  return (
    <div id="secondary">
      <h2 className="site-description">Marriage Certificate</h2>
      <div id="primary-sidebar" className="primary-sidebar widget-area" role="complementary">
        
        <div id="google_translate_element2"></div>

        <br />
        <aside id="media_image-3" className="widget widget_media_image">
          <img width="150" height="150" src="/static/img/confidential-150x150-1.png" className="image wp-image-37 attachment-full size-full" alt="" loading="lazy" style={{ maxWidth: '100%', height: 'auto' }} title="confidential-150x150 | Court Marriage &amp; Registration" />
        </aside>
        <aside id="text-3" className="widget widget_text">
          <h1 className="widget-title">CONFIDENTIALITY GUARANTEED</h1>
          <div className="textwidget">
            <p>Your privacy is very important to us. We never disclose your information under any circumstances. Your information is always safe with us. <strong>Guaranteed by Team Digital India!</strong></p>
          </div>
        </aside>
        <aside id="text-4" className="widget widget_text">
          <div className="textwidget">
            <p><a href="javascript:void(0)">LEGAL SERVICES</a></p>
          </div>
        </aside>
      </div>

      <style jsx>{`
        .switcher {
          font-family: Arial;
          font-size: 12pt;
          text-align: left;
          cursor: pointer;
          overflow: hidden;
          width: 173px;
          line-height: 17px;
        }
        .switcher a {
          text-decoration: none;
          display: block;
          font-size: 12pt;
          -webkit-box-sizing: content-box;
          -moz-box-sizing: content-box;
          box-sizing: content-box;
        }
        .switcher a img {
          vertical-align: middle;
          display: inline;
          border: 0;
          padding: 0;
          margin: 0;
          opacity: 0.8;
        }
        .switcher a:hover img {
          opacity: 1;
        }
        .switcher .selected {
          background: #fff linear-gradient(180deg, #efefef 0%, #fff 70%);
          position: relative;
          z-index: 9999;
        }
        .switcher .selected a {
          border: 1px solid #ccc;
          color: #666;
          padding: 3px 5px;
          width: 161px;
        }
        .switcher .selected a:after {
          height: 24px;
          display: inline-block;
          position: absolute;
          right: 10px;
          width: 15px;
          background-position: 50%;
          background-size: 11px;
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 285 285'><path d='M282 76.5l-14.2-14.3a9 9 0 0 0-13.1 0L142.5 174.4 30.3 62.2a9 9 0 0 0-13.2 0L3 76.5a9 9 0 0 0 0 13.1l133 133a9 9 0 0 0 13.1 0l133-133a9 9 0 0 0 0-13z' style='fill:%23666'/></svg>");
          background-repeat: no-repeat;
          content: "" !important;
          transition: all 0.2s;
        }
        .switcher .selected a.open:after {
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
        }
        .switcher .selected a:hover {
          background: #fff;
        }
        .switcher .option {
          position: relative;
          z-index: 9998;
          border-left: 1px solid #ccc;
          border-right: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          background-color: #eee;
          display: none;
          width: 171px;
          max-height: 198px;
          -webkit-box-sizing: content-box;
          -moz-box-sizing: content-box;
          box-sizing: content-box;
          overflow-y: auto;
          overflow-x: hidden;
        }
        .switcher .option a {
          color: #000;
          padding: 3px 5px;
        }
        .switcher .option a:hover {
          background: #fff;
        }
        .switcher .option a.selected {
          background: #fff;
        }
        #selected_lang_name {
          float: none;
        }
        .l_name {
          float: none !important;
          margin: 0;
        }
        .switcher .option::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
          border-radius: 5px;
          background-color: #f5f5f5;
        }
        .switcher .option::-webkit-scrollbar {
          width: 5px;
        }
        .switcher .option::-webkit-scrollbar-thumb {
          border-radius: 5px;
          -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
          background-color: #888;
        }
      `}</style>
    </div>
  );
};

export default IncludeMarriageCertificate;
