import React from 'react';
import { Helmet } from 'react-helmet';
import CssHeader from './CssHeader';
import Header from './Header';
import Homecontent from './Homecontent';
import Footer from './Footer';

function Home() {
  return (
    <>
      <Helmet>
        <title>Court Marriage</title>
        <meta name="description" content="Court Marriage." />
        <meta name="keyword" content="Delhi Court Marrige" />
      </Helmet>
      <CssHeader />
      <Header />
      <Homecontent />
      <Footer />
    </>
  );
}

export default Home;
