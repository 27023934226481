import React from 'react';
import { Helmet } from 'react-helmet';
import CssHeader from './CssHeader';
import Header from './Header';
import Footer from './Footer';
import IncludeSidebar from './IncludeSidebar';
import IncludeMariigeCertificate from "./IncludeMariigeCertificate";

function FAQ() {
  return (
    <>
      <Helmet>
        <title>Court Marriage</title>
        <meta name="description" content="Court Marriage." />
        <meta name="keywords" content="Delhi Court Marriage" />
      </Helmet>
      <CssHeader />
      <Header />
      <div id="main" className="site-main hfeed site">
        <div id="main-content" className="main-content">
          <div id="primary" className="content-area">
            <div id="content" className="site-content" role="main">              

              <div id="post-52" class="post-52 page type-page status-publish hentry">
              <header class="entry-header">
                <h1 class="entry-title">FAQ</h1>
              </header>
              <div class="entry-content">
                <div class="fl-builder-content fl-builder-content-52 fl-builder-content-primary fl-builder-global-templates-locked" data-post-id="52">
                  <div class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5f2fa53b6bc02" data-node="5f2fa53b6bc02">
                    <div class="fl-row-content-wrap">
                      <div class="fl-col-content fl-node-content">
                        <div class="fl-module fl-module-callout fl-node-5f2f903a661c5" data-node="5f2f903a661c5">
                          <div class="fl-module-content fl-node-content">
                            <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                              <div class="fl-callout-content">
                                <h3 class="fl-callout-title">	<span class="fl-icon"><br />
                                  <i class="fas fa-hand-point-right" aria-hidden="true"></i><br />
                                  </span><br />
                                  <span class="fl-callout-title-text">Procedure &#8211; Court Marriage</span><br />
                                </h3>
                                <div class="fl-callout-text-wrap">
                                  <div class="fl-callout-text">
                                    <p><span style={{ color: '#0000ff' }}><strong>Q.</strong> : What is the procedure for doing court marriage/registration through online and how long it will take to complete process?</span></p>
                                    <p><strong>Ans. : Step 1 : (start process from home)</strong> if you wish to do court marriage/registration then Click <a href="index.html">Online Registration</a>, fill all the columns and click Send button to send your online application. Thereafter Click the Confirm button to pay the booking amount. After successful booking you will get the confirmation receipt on your Email ID and Mobile. Your booking for court marriage/registration is confirmed.</p>
                                    <p><strong>Step 2 : ( Start process by personal visit)</strong> if you do not want to book online then Call Our Toll Free Helpline No. 9716030199, 9999985852 to book an appointment. Visit registration office on appointment date and submit your documents to get a confirm date. (For booking court marriage/registration process, anyone on behalf of Boy or Girl may come to authorised centre between 9.30 AM to 05.00 PM on appointment date). Note : If you have submitted online form successfully then you need not to visit prior to booking date. Click <a href="contact-us/index.html">Contact Us</a> to get the complete Address of the authorised registration centre where you have to visit.</p>
                                    <p><strong>Step 3 : ( Get marriage certificate within 2/3 hours)</strong> on decided date both of you (boy and girl) with witnesses will have to visit at the authorised centre at 9.30 A.M. and within 2/3 hours you will get the court marriage/registration certificate on the same day under &#8216;Tatkal&#8217; service.</p>
                                    <hr />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f90fbc5116" data-node="5f2f90fbc5116">
                          <div class="fl-module-content fl-node-content">
                            <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                              <div class="fl-callout-content">
                                <h3 class="fl-callout-title">	<span class="fl-icon"><br />
                                  <i class="fas fa-hand-point-right" aria-hidden="true"></i><br />
                                  </span><br />
                                  <span class="fl-callout-title-text">Booking &#8211; Time Period</span><br />
                                </h3>
                                <div class="fl-callout-text-wrap">
                                  <div class="fl-callout-text">
                                    <p><span style={{ color: '#0000ff' }}><strong>Q. </strong>: How many days before I have to apply for court marriage?</span></p>
                                    <p><strong>Ans. :</strong> 1. You can apply to get a confirm date within 18 months. At least one week time is enough to get the confirm date if you are applying for tomorrow (next working day) or within a week then call or email us to know the availability.</p>
                                    <p>2. Choose any date for court marriage/registration between Monday to Saturday (Any Working Day). Check the Indian calendar that there should not be any Public Holiday like Holi, Diwali etc. or contact us to check the same.</p>
                                    <hr />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f91b0e88b5" data-node="5f2f91b0e88b5">
                          <div class="fl-module-content fl-node-content">
                            <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                              <div class="fl-callout-content">
                                <h3 class="fl-callout-title">	<span class="fl-icon"><br />
                                  <i class="fas fa-hand-point-right" aria-hidden="true"></i><br />
                                  </span><br />
                                  <span class="fl-callout-title-text">Reshedule &#8211; Court Marriage Date</span><br />
                                </h3>
                                <div class="fl-callout-text-wrap">
                                  <div class="fl-callout-text">
                                    <p><span style={{ color: '#0000ff' }}><strong>Q. : Can we change/extend our court marriage date?</strong></span></p>
                                    <p><strong>Ans. :</strong> Yes, just send us an email or call toll free helpline number 9716030199, 9999985852 to change/extend your court marriage date.</p>
                                    <p><strong>Note : Information must be given at least one day before.</strong></p>
                                    <hr />
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f91eb467b3" data-node="5f2f91eb467b3">
                          <div class="fl-module-content fl-node-content">
                            <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                              <div class="fl-callout-content">
                                <h3 class="fl-callout-title">	<span class="fl-icon"><br />
                                  <i class="fas fa-hand-point-right" aria-hidden="true"></i><br />
                                  </span><br />
                                  <span class="fl-callout-title-text">Fee &#8211; Court Marriage</span><br />
                                </h3>
                                <div class="fl-callout-text-wrap">
                                  <div class="fl-callout-text">
                                    <p><span style={{ color: '#0000ff' }}><strong>Q. :</strong> What is Court Marriage Fee?</span></p>
                                    <p><strong>Ans. :</strong> Please go to the Fee Menu or <a href="fee/index.html">Click Here</a></p>
                                    <hr />
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f9233603fe" data-node="5f2f9233603fe">
                          <div class="fl-module-content fl-node-content">
                            <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                              <div class="fl-callout-content">
                                <h3 class="fl-callout-title">	<span class="fl-icon"><br />
                                  <i class="fas fa-hand-point-right" aria-hidden="true"></i><br />
                                  </span><br />
                                  <span class="fl-callout-title-text">Age &#8211; Court Marriage</span><br />
                                </h3>
                                <div class="fl-callout-text-wrap">
                                  <div class="fl-callout-text">
                                    <p><span style={{ color: '#0000ff' }}><strong>Q. :</strong> What is Minimum age requirement to get married legally in India?</span></p>
                                    <p><strong>Ans. :</strong> The boy must completed 21 age and girl must completed 18 age.</p>
                                    <hr />
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f926d77445" data-node="5f2f926d77445">
                          <div class="fl-module-content fl-node-content">
                            <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                              <div class="fl-callout-content">
                                <h3 class="fl-callout-title">	<span class="fl-icon"><br />
                                  <i class="fas fa-hand-point-right" aria-hidden="true"></i><br />
                                  </span><br />
                                  <span class="fl-callout-title-text">Documents &#8211; Court Marriage</span><br />
                                </h3>
                                <div class="fl-callout-text-wrap">
                                  <div class="fl-callout-text">
                                    <p><span style={{ color: '#0000ff' }}><strong>Q. :</strong> What are the documents required for court marriage or marriage registration?</span></p>
                                    <p><strong>Ans. :</strong> Please Click <a href="documents/index.html">DOCUMENTS</a> to see the required documents.</p>
                                    <hr />
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f92ba73e51" data-node="5f2f92ba73e51">
                          <div class="fl-module-content fl-node-content">
                            <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                              <div class="fl-callout-content">
                                <h3 class="fl-callout-title">	<span class="fl-icon"><br />
                                  <i class="fas fa-hand-point-right" aria-hidden="true"></i><br />
                                  </span><br />
                                  <span class="fl-callout-title-text">Hindu &#8211; Court Marriage</span><br />
                                </h3>
                                <div class="fl-callout-text-wrap">
                                  <div class="fl-callout-text">
                                    <p><span style={{ color: '#0000ff' }}><strong>Q. :</strong> We both are Hindu, Can we get same day court marriage certificate?</span></p>
                                    <p><strong>Ans. :</strong> Yes, according to Hindu Marriage Act you will get the court marriage certificate same working day by paying TATKAL fees.</p>
                                    <hr />
                                   
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f92ee4a752" data-node="5f2f92ee4a752">
                          <div class="fl-module-content fl-node-content">
                            <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                              <div class="fl-callout-content">
                                <h3 class="fl-callout-title">	<span class="fl-icon"><br />
                                  <i class="fas fa-hand-point-right" aria-hidden="true"></i><br />
                                  </span><br />
                                  <span class="fl-callout-title-text">Muslim &#8211; Court Marriage</span><br />
                                </h3>
                                <div class="fl-callout-text-wrap">
                                  <div class="fl-callout-text">
                                    <p><span style={{ color: '#0000ff' }}><strong>Q. :</strong> We both are Muslim, Can we get same day court marriage certificate?</span></p>
                                    <p><strong>Ans. :</strong> Yes, according to Muslim Law you will get the court marriage certificate same working day by paying TATKAL fees.</p>
                                    <hr />
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f9324b1b28" data-node="5f2f9324b1b28">
                          <div class="fl-module-content fl-node-content">
                            <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                              <div class="fl-callout-content">
                                <h3 class="fl-callout-title">	<span class="fl-icon"><br />
                                  <i class="fas fa-hand-point-right" aria-hidden="true"></i><br />
                                  </span><br />
                                  <span class="fl-callout-title-text">Christian &#8211; Court Marriage</span><br />
                                </h3>
                                <div class="fl-callout-text-wrap">
                                  <div class="fl-callout-text">
                                    <p><span style={{ color: '#0000ff' }}><strong>Q. :</strong>We both are Christian, Can we get same day court marriage certificate?</span></p>
                                    <p><strong>Ans. :</strong> Yes, according to Christian Marriage Act you will get the court marriage certificate same working day by paying TATKAL fees.</p>
                                    <hr />
                                  
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f9359f3c71" data-node="5f2f9359f3c71">
                          <div class="fl-module-content fl-node-content">
                            <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                              <div class="fl-callout-content">
                                <h3 class="fl-callout-title">	<span class="fl-icon"><br />
                                  <i class="fas fa-hand-point-right" aria-hidden="true"></i><br />
                                  </span><br />
                                  <span class="fl-callout-title-text">Inter Religion &#8211; Court Marriage</span><br />
                                </h3>
                                <div class="fl-callout-text-wrap">
                                  <div class="fl-callout-text">
                                    <p><span style={{ color: '#0000ff' }}><strong>Q. :</strong> Boy/Girl is Hindu and Girl/Boy is (Msulim/Christian/Sikh), Can we get same day court marriage certificate?</span></p>
                                    <p><strong>Ans. :</strong> Yes, you can do court marriage.</p>
                                    <hr />
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f9390e4395" data-node="5f2f9390e4395">
                          <div class="fl-module-content fl-node-content">
                            <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                              <div class="fl-callout-content">
                                <h3 class="fl-callout-title">	<span class="fl-icon"><br />
                                  <i class="fas fa-hand-point-right" aria-hidden="true"></i><br />
                                  </span><br />
                                  <span class="fl-callout-title-text">Court Notice</span><br />
                                </h3>
                                <div class="fl-callout-text-wrap">
                                  <div class="fl-callout-text">
                                    <p><span style={{ color: '#0000ff' }}><strong>Q. :</strong> Do you send notice at our home?</span></p>
                                    <p><strong>Ans. :</strong> No, we never send any notice at your home.</p>
                                    <hr />
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f93c85ae54" data-node="5f2f93c85ae54">
                          <div class="fl-module-content fl-node-content">
                            <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                              <div class="fl-callout-content">
                                <h3 class="fl-callout-title">	<span class="fl-icon"><br />
                                  <i class="fas fa-hand-point-right" aria-hidden="true"></i><br />
                                  </span><br />
                                  <span class="fl-callout-title-text">Police Protection</span><br />
                                </h3>
                                <div class="fl-callout-text-wrap">
                                  <div class="fl-callout-text">
                                    <p><span style={{ color: '#0000ff' }}><strong>Q. :</strong> What is police protection, can we get police protection?</span></p>
                                    <p><strong>Ans. :</strong> Yes, you can get police protection but only after court marriage certificate.</p>
                                    <hr />
                                   
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f940007e6e" data-node="5f2f940007e6e">
                          <div class="fl-module-content fl-node-content">
                            <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                              <div class="fl-callout-content">
                                <h3 class="fl-callout-title">	<span class="fl-icon"><br />
                                  <i class="fas fa-hand-point-right" aria-hidden="true"></i><br />
                                  </span><br />
                                  <span class="fl-callout-title-text">NRI / Foreigner Marriage</span><br />
                                </h3>
                                <div class="fl-callout-text-wrap">
                                  <div class="fl-callout-text">
                                    <p><span style={{ color: '#0000ff' }}><strong>Q. :</strong> My fiance is NRI / foreigner can we do court marriage / registration in India?</span></p>
                                    <p><strong>Ans. :</strong> Yes, you can do court marriage / registration in India.</p>
                                    <hr />
                                    
                                  </div>
                                
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            </div>
          </div>
          <IncludeSidebar/>
        </div>
        <IncludeMariigeCertificate/>
      </div>
      <Footer />
    </>
  );
}

export default FAQ;
