import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import CssHeader from './CssHeader';
import Header from './Header';
import Footer from './Footer';
import IncludeSidebar from './IncludeSidebar';
import IncludeMariigeCertificate from "./IncludeMariigeCertificate";
import axios from 'axios';

function Fee() {
	
	 const [formData, setFormData] = useState({
        boy_religion: '',
        boy_residence_state: '',
        boy_nationality: '',
        girl_religion: '',
        girl_residence_state: '',
        girl_nationality: '',
        name: '',
        phone_no: '',
        email: '',
        fee_action: 'yes',
        honeypot: ''
    });

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData(prevState => ({
		  ...prevState,
		  [name]: value
		}));
	};

	  const handleSubmit = (e) => {
		e.preventDefault();
		
		if (formData.honeypot) {
			  alert('Spam detected. Please try again.');
			  return;
		  }
		
		axios.post('https://admissionschoolofbiodesign.in/kite/court_marrige.php', JSON.stringify(formData),{
			headers: {
				'Content-Type': 'application/json'
			  }
			})
			.then(response => {
				
				if (response.data.status === 'success') {			  
          window.location.href = response.data.redirect_url;
				} else {				
				  alert(response.data.message);
				}
			})
			.catch(error => {
				//console.error('There was an error submitting the form!', error);			
				alert('There was an error submitting the form. Please try again.');
			});
	};
  
    return (
    <>
      <Helmet>
        <title>Court Marriage</title>
        <meta name="description" content="Court Marriage." />
        <meta name="keywords" content="Delhi Court Marriage" />
      </Helmet>
      <CssHeader />
      <Header />
      <div id="main" className="site-main hfeed site">
        <div id="main-content" className="main-content">
          <div id="primary" className="content-area">
            <div id="content" className="site-content" role="main">
             

              <div id="post-67" class="post-67 page type-page status-publish hentry">
                <header class="entry-header">
                  <h1 class="entry-title">Fee</h1>
                </header>
                <div class="entry-content">
                  <div class="fl-builder-content fl-builder-content-67 fl-builder-content-primary fl-builder-global-templates-locked" data-post-id="67">
                  <div class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5f2fbd5ccd621" data-node="5f2fbd5ccd621">
                    <div class="fl-row-content-wrap">
                      <div class="fl-module-content fl-node-content">
                        <div class="fl-widget">
                        <div class="widget widget_ninja_forms_widget">
                          
                          
                           <form className="cmxform" id="commonForm" method="post" onSubmit={handleSubmit}>
                            <div class="nf-form-content ">
                              <nf-fields-wrap>
                              <nf-field>
                                <div id="nf-field-32_1-container" class="nf-field-container listselect-container  label-above  list-container">
                                  <div class="nf-before-field">
                                    <nf-section>
                                    </nf-section>
                                  </div>
                                  <div class="nf-field">
                                    <div id="nf-field-32_1-wrap" class="field-wrap listselect-wrap list-wrap list-select-wrap" data-field-id="32_1">
                                    <div class="nf-field-label"><label for="nf-field-32_1" id="nf-label-field-32_1" class="">Boy's Religion <span class="ninja-forms-req-symbol">*</span> </label></div>
                                    <div class="nf-field-element">
                                      <select id="boy_religion" name="boy_religion" aria-invalid="false" value={formData.boy_religion} onChange={handleChange} required>
                                        <option value="Hindu Buddhist Jain Sikh" selected="selected">Hindu (Buddhist, Jain, Sikh)</option>
                                        <option value="Muslim">Muslim</option>
                                        <option value="Christian">Christian</option>
                                      </select>
                                      <div for="nf-field-32_1"></div>
                                    </div>
                                    </div>
                                  </div>
                                  <div class="nf-after-field">
                                    <nf-section>
                                    <div class="nf-input-limit"></div>
                                    <div id="nf-error-32_1" class="nf-error-wrap nf-error" role="alert"></div>
                                    </nf-section>
                                  </div>
                                </div>
                              </nf-field>
                              <nf-field>
                                <div id="nf-field-33_1-container" class="nf-field-container listselect-container  label-above  list-container">
                                  <div class="nf-before-field">
                                    <nf-section>
                                    </nf-section>
                                  </div>
                                  <div class="nf-field">
                                    <div id="nf-field-33_1-wrap" class="field-wrap listselect-wrap list-wrap list-select-wrap" data-field-id="33_1">
                                    <div class="nf-field-label"><label for="nf-field-33_1" id="nf-label-field-33_1" class="">Boy's Current Residence State <span class="ninja-forms-req-symbol">*</span> </label></div>
                                    <div class="nf-field-element">
                                       <select id="boy_residence_state" name="boy_residence_state" aria-invalid="false" value={formData.boy_residence_state} onChange={handleChange} required className="ninja-forms-field nf-element">
									   
                                        <option value="delhi" selected="selected">Delhi</option>
                                        <option value="andra-pradesh">Andra Pradesh</option>
                                        <option value="arunachal-pradesh">Arunachal Pradesh</option>
                                        <option value="assam">Assam</option>
                                        <option value="bihar">Bihar</option>
                                        <option value="chhattisgarh">Chhattisgarh</option>
                                        <option value="goa">Goa</option>
                                        <option value="gujarat">Gujarat</option>
                                        <option value="haryana">Haryana</option>
                                        <option value="himachal-pradesh">Himachal Pradesh</option>
                                        <option value="jammu-and-kashmir">Jammu and Kashmir</option>
                                        <option value="jharkhand">Jharkhand</option>
                                        <option value="karnataka">Karnataka</option>
                                        <option value="kerala">Kerala</option>
                                        <option value="madya-pradesh">Madya Pradesh</option>
                                        <option value="maharashtra">Maharashtra</option>
                                        <option value="manipur">Manipur</option>
                                        <option value="meghalaya">Meghalaya</option>
                                        <option value="mizoram">Mizoram</option>
                                        <option value="nagaland">Nagaland</option>
                                        <option value="orissa">Orissa</option>
                                        <option value="punjab">Punjab</option>
                                        <option value="rajasthan">Rajasthan</option>
                                        <option value="sikkim">Sikkim</option>
                                        <option value="tamil-nadu">Tamil Nadu</option>
                                        <option value="tripura">Tripura</option>
                                        <option value="uttaranchal">Uttaranchal</option>
                                        <option value="uttar-pradesh">Uttar Pradesh</option>
                                        <option value="west-bengal">West Bengal</option>
                                        <option value="andaman-and-nicobar-islands">Andaman and Nicobar Islands</option>
                                        <option value="chandigarh">Chandigarh</option>
                                        <option value="dadar-and-nagar-haveli">Dadar and Nagar Haveli</option>
                                        <option value="daman-and-diu">Daman and Diu</option>
                                        <option value="lakshadeep">Lakshadeep</option>
                                        <option value="other">Other</option>
                                      </select>
                                      <div for="nf-field-33_1"></div>
                                    </div>
                                    </div>
                                  </div>
                                  <div class="nf-after-field">
                                    <nf-section>
                                    <div class="nf-input-limit"></div>
                                    <div id="nf-error-33_1" class="nf-error-wrap nf-error" role="alert"></div>
                                    </nf-section>
                                  </div>
                                </div>
                              </nf-field>
                              <nf-field>
                                <div id="nf-field-34_1-container" class="nf-field-container listcountry-container  label-above  list-container">
                                  <div class="nf-before-field">
                                    <nf-section>
                                    </nf-section>
                                  </div>
                                  <div class="nf-field">
                                    <div id="nf-field-34_1-wrap" class="field-wrap listcountry-wrap list-wrap" data-field-id="34_1">
                                    <div class="nf-field-label"><label for="nf-field-34_1" id="nf-label-field-34_1" class="">Boy's Nationality <span class="ninja-forms-req-symbol">*</span> </label></div>
                                    <div class="nf-field-element">
                                      <select id="boy_nationality" name="boy_nationality" aria-invalid="false" value={formData.boy_nationality} onChange={handleChange} required className="ninja-forms-field nf-element">
									    <option value="IN" selected="selected">India</option>                                  
                                        <option value="AF">Afghanistan</option>
                                        <option value="AL">Albania</option>
                                        <option value="DZ">Algeria</option>
                                        <option value="AS">American Samoa</option>
                                        <option value="AD">Andorra</option>
                                        <option value="AO">Angola</option>
                                        <option value="AI">Anguilla</option>
                                        <option value="AQ">Antarctica</option>
                                        <option value="AG">Antigua And Barbuda</option>
                                        <option value="AR">Argentina</option>
                                        <option value="AM">Armenia</option>
                                        <option value="AW">Aruba</option>
                                        <option value="AU">Australia</option>
                                        <option value="AT">Austria</option>
                                        <option value="AZ">Azerbaijan</option>
                                        <option value="BS">Bahamas</option>
                                        <option value="BH">Bahrain</option>
                                        <option value="BD">Bangladesh</option>
                                        <option value="BB">Barbados</option>
                                        <option value="BY">Belarus</option>
                                        <option value="BE">Belgium</option>
                                        <option value="BZ">Belize</option>
                                        <option value="BJ">Benin</option>
                                        <option value="BM">Bermuda</option>
                                        <option value="BT">Bhutan</option>
                                        <option value="BO">Bolivia</option>
                                        <option value="BA">Bosnia And Herzegowina</option>
                                        <option value="BW">Botswana</option>
                                        <option value="BV">Bouvet Island</option>
                                        <option value="BR">Brazil</option>
                                        <option value="IO">British Indian Ocean Territory</option>
                                        <option value="BN">Brunei Darussalam</option>
                                        <option value="BG">Bulgaria</option>
                                        <option value="BF">Burkina Faso</option>
                                        <option value="BI">Burundi</option>
                                        <option value="KH">Cambodia</option>
                                        <option value="CM">Cameroon</option>
                                        <option value="CA">Canada</option>
                                        <option value="CV">Cape Verde</option>
                                        <option value="KY">Cayman Islands</option>
                                        <option value="CF">Central African Republic</option>
                                        <option value="TD">Chad</option>
                                        <option value="CL">Chile</option>
                                        <option value="CN">China</option>
                                        <option value="CX">Christmas Island</option>
                                        <option value="CC">Cocos (Keeling) Islands</option>
                                        <option value="CO">Colombia</option>
                                        <option value="KM">Comoros</option>
                                        <option value="CG">Congo</option>
                                        <option value="CD">Congo, The Democratic Republic Of The</option>
                                        <option value="CK">Cook Islands</option>
                                        <option value="CR">Costa Rica</option>
                                        <option value="CI">Cote D'Ivoire</option>
                                        <option value="HR">Croatia (Local Name: Hrvatska)</option>
                                        <option value="CU">Cuba</option>
                                        <option value="CY">Cyprus</option>
                                        <option value="CZ">Czech Republic</option>
                                        <option value="DK">Denmark</option>
                                        <option value="DJ">Djibouti</option>
                                        <option value="DM">Dominica</option>
                                        <option value="DO">Dominican Republic</option>
                                        <option value="EC">Ecuador</option>
                                        <option value="EG">Egypt</option>
                                        <option value="SV">El Salvador</option>
                                        <option value="GQ">Equatorial Guinea</option>
                                        <option value="ER">Eritrea</option>
                                        <option value="EE">Estonia</option>
                                        <option value="ET">Ethiopia</option>
                                        <option value="FK">Falkland Islands (Malvinas)</option>
                                        <option value="FO">Faroe Islands</option>
                                        <option value="FJ">Fiji</option>
                                        <option value="FI">Finland</option>
                                        <option value="FR">France</option>
                                        <option value="FX">France, Metropolitan</option>
                                        <option value="GF">French Guiana</option>
                                        <option value="PF">French Polynesia</option>
                                        <option value="TF">French Southern Territories</option>
                                        <option value="GA">Gabon</option>
                                        <option value="GM">Gambia</option>
                                        <option value="GE">Georgia</option>
                                        <option value="DE">Germany</option>
                                        <option value="GH">Ghana</option>
                                        <option value="GI">Gibraltar</option>
                                        <option value="GR">Greece</option>
                                        <option value="GL">Greenland</option>
                                        <option value="GD">Grenada</option>
                                        <option value="GP">Guadeloupe</option>
                                        <option value="GU">Guam</option>
                                        <option value="GT">Guatemala</option>
                                        <option value="GN">Guinea</option>
                                        <option value="GW">Guinea-Bissau</option>
                                        <option value="GY">Guyana</option>
                                        <option value="HT">Haiti</option>
                                        <option value="HM">Heard And Mc Donald Islands</option>
                                        <option value="VA">Holy See (Vatican City State)</option>
                                        <option value="HN">Honduras</option>
                                        <option value="HK">Hong Kong</option>
                                        <option value="HU">Hungary</option>
                                        <option value="IS">Iceland</option>
                                        
                                        <option value="ID">Indonesia</option>
                                        <option value="IR">Iran (Islamic Republic Of)</option>
                                        <option value="IQ">Iraq</option>
                                        <option value="IE">Ireland</option>
                                        <option value="IL">Israel</option>
                                        <option value="IT">Italy</option>
                                        <option value="JM">Jamaica</option>
                                        <option value="JP">Japan</option>
                                        <option value="JO">Jordan</option>
                                        <option value="KZ">Kazakhstan</option>
                                        <option value="KE">Kenya</option>
                                        <option value="KI">Kiribati</option>
                                        <option value="KP">Korea, Democratic People's Republic Of</option>
                                        <option value="KR">Korea, Republic Of</option>
                                        <option value="KW">Kuwait</option>
                                        <option value="KG">Kyrgyzstan</option>
                                        <option value="LA">Lao People's Democratic Republic</option>
                                        <option value="LV">Latvia</option>
                                        <option value="LB">Lebanon</option>
                                        <option value="LS">Lesotho</option>
                                        <option value="LR">Liberia</option>
                                        <option value="LY">Libyan Arab Jamahiriya</option>
                                        <option value="LI">Liechtenstein</option>
                                        <option value="LT">Lithuania</option>
                                        <option value="LU">Luxembourg</option>
                                        <option value="MO">Macau</option>
                                        <option value="MK">Macedonia, Former Yugoslav Republic Of</option>
                                        <option value="MG">Madagascar</option>
                                        <option value="MW">Malawi</option>
                                        <option value="MY">Malaysia</option>
                                        <option value="MV">Maldives</option>
                                        <option value="ML">Mali</option>
                                        <option value="MT">Malta</option>
                                        <option value="MH">Marshall Islands</option>
                                        <option value="MQ">Martinique</option>
                                        <option value="MR">Mauritania</option>
                                        <option value="MU">Mauritius</option>
                                        <option value="YT">Mayotte</option>
                                        <option value="MX">Mexico</option>
                                        <option value="FM">Micronesia, Federated States Of</option>
                                        <option value="MD">Moldova, Republic Of</option>
                                        <option value="MC">Monaco</option>
                                        <option value="MN">Mongolia</option>
                                        <option value="ME">Montenegro</option>
                                        <option value="MS">Montserrat</option>
                                        <option value="MA">Morocco</option>
                                        <option value="MZ">Mozambique</option>
                                        <option value="MM">Myanmar</option>
                                        <option value="NA">Namibia</option>
                                        <option value="NR">Nauru</option>
                                        <option value="NP">Nepal</option>
                                        <option value="NL">Netherlands</option>
                                        <option value="AN">Netherlands Antilles</option>
                                        <option value="NC">New Caledonia</option>
                                        <option value="NZ">New Zealand</option>
                                        <option value="NI">Nicaragua</option>
                                        <option value="NE">Niger</option>
                                        <option value="NG">Nigeria</option>
                                        <option value="NU">Niue</option>
                                        <option value="NF">Norfolk Island</option>
                                        <option value="MP">Northern Mariana Islands</option>
                                        <option value="NO">Norway</option>
                                        <option value="OM">Oman</option>
                                        <option value="PK">Pakistan</option>
                                        <option value="PW">Palau</option>
                                        <option value="PA">Panama</option>
                                        <option value="PG">Papua New Guinea</option>
                                        <option value="PY">Paraguay</option>
                                        <option value="PE">Peru</option>
                                        <option value="PH">Philippines</option>
                                        <option value="PN">Pitcairn</option>
                                        <option value="PL">Poland</option>
                                        <option value="PT">Portugal</option>
                                        <option value="PR">Puerto Rico</option>
                                        <option value="QA">Qatar</option>
                                        <option value="RE">Reunion</option>
                                        <option value="RO">Romania</option>
                                        <option value="RU">Russian Federation</option>
                                        <option value="RW">Rwanda</option>
                                        <option value="KN">Saint Kitts And Nevis</option>
                                        <option value="LC">Saint Lucia</option>
                                        <option value="VC">Saint Vincent And The Grenadines</option>
                                        <option value="WS">Samoa</option>
                                        <option value="SM">San Marino</option>
                                        <option value="ST">Sao Tome And Principe</option>
                                        <option value="SA">Saudi Arabia</option>
                                        <option value="SN">Senegal</option>
                                        <option value="RS">Serbia</option>
                                        <option value="SC">Seychelles</option>
                                        <option value="SL">Sierra Leone</option>
                                        <option value="SG">Singapore</option>
                                        <option value="SK">Slovakia (Slovak Republic)</option>
                                        <option value="SI">Slovenia</option>
                                        <option value="SB">Solomon Islands</option>
                                        <option value="SO">Somalia</option>
                                        <option value="ZA">South Africa</option>
                                        <option value="GS">South Georgia, South Sandwich Islands</option>
                                        <option value="ES">Spain</option>
                                        <option value="LK">Sri Lanka</option>
                                        <option value="SH">St. Helena</option>
                                        <option value="PM">St. Pierre And Miquelon</option>
                                        <option value="SD">Sudan</option>
                                        <option value="SR">Suriname</option>
                                        <option value="SJ">Svalbard And Jan Mayen Islands</option>
                                        <option value="SZ">Swaziland</option>
                                        <option value="SE">Sweden</option>
                                        <option value="CH">Switzerland</option>
                                        <option value="SY">Syrian Arab Republic</option>
                                        <option value="TW">Taiwan</option>
                                        <option value="TJ">Tajikistan</option>
                                        <option value="TZ">Tanzania, United Republic Of</option>
                                        <option value="TH">Thailand</option>
                                        <option value="TL">Timor-Leste (East Timor)</option>
                                        <option value="TG">Togo</option>
                                        <option value="TK">Tokelau</option>
                                        <option value="TO">Tonga</option>
                                        <option value="TT">Trinidad And Tobago</option>
                                        <option value="TN">Tunisia</option>
                                        <option value="TR">Turkey</option>
                                        <option value="TM">Turkmenistan</option>
                                        <option value="TC">Turks And Caicos Islands</option>
                                        <option value="TV">Tuvalu</option>
                                        <option value="UG">Uganda</option>
                                        <option value="UA">Ukraine</option>
                                        <option value="AE">United Arab Emirates</option>
                                        <option value="GB">United Kingdom</option>
                                        <option value="US">United States</option>
                                        <option value="UM">United States Minor Outlying Islands</option>
                                        <option value="UY">Uruguay</option>
                                        <option value="UZ">Uzbekistan</option>
                                        <option value="VU">Vanuatu</option>
                                        <option value="VE">Venezuela</option>
                                        <option value="VN">Viet Nam</option>
                                        <option value="VG">Virgin Islands (British)</option>
                                        <option value="VI">Virgin Islands (U.S.)</option>
                                        <option value="WF">Wallis And Futuna Islands</option>
                                        <option value="EH">Western Sahara</option>
                                        <option value="YE">Yemen</option>
                                        <option value="YU">Yugoslavia</option>
                                        <option value="ZM">Zambia</option>
                                        <option value="ZW">Zimbabwe</option>
                                      </select>
                                      <div for="nf-field-34_1"></div>
                                    </div>
                                    </div>
                                  </div>
                                  <div class="nf-after-field">
                                    <nf-section>
                                    <div class="nf-input-limit"></div>
                                    <div id="nf-error-34_1" class="nf-error-wrap nf-error" role="alert"></div>
                                    </nf-section>
                                  </div>
                                </div>
                              </nf-field>
                              <nf-field>
                                <div id="nf-field-35_1-container" class="nf-field-container listselect-container  label-above  list-container">
                                  <div class="nf-before-field">
                                    <nf-section>
                                    </nf-section>
                                  </div>
                                  <div class="nf-field">
                                    <div id="nf-field-35_1-wrap" class="field-wrap listselect-wrap list-wrap list-select-wrap" data-field-id="35_1">
                                    <div class="nf-field-label"><label for="nf-field-35_1" id="nf-label-field-35_1" class="">Girl's Religion <span class="ninja-forms-req-symbol">*</span> </label></div>
                                    <div class="nf-field-element">
                                       <select id="girl_religion" name="girl_religion" aria-invalid="false" value={formData.girl_religion} onChange={handleChange} required className="ninja-forms-field nf-element">
                                        <option value="Hindu Buddhist Jain Sikh" selected="selected">Hindu (Buddhist, Jain, Sikh)</option>
                                        <option value="Muslim">Muslim</option>
                                        <option value="Christian">Christian</option>
                                      </select>
                                      <div for="nf-field-35_1"></div>
                                    </div>
                                    </div>
                                  </div>
                                  <div class="nf-after-field">
                                    <nf-section>
                                    <div class="nf-input-limit"></div>
                                    <div id="nf-error-35_1" class="nf-error-wrap nf-error" role="alert"></div>
                                    </nf-section>
                                  </div>
                                </div>
                              </nf-field>
                              <nf-field>
                                <div id="nf-field-36_1-container" class="nf-field-container listselect-container  label-above  list-container">
                                  <div class="nf-before-field">
                                    <nf-section>
                                    </nf-section>
                                  </div>
                                  <div class="nf-field">
                                    <div id="nf-field-36_1-wrap" class="field-wrap listselect-wrap list-wrap list-select-wrap" data-field-id="36_1">
                                    <div class="nf-field-label"><label for="nf-field-36_1" id="nf-label-field-36_1" class="">Girl's Current Residence State <span class="ninja-forms-req-symbol">*</span> </label></div>
                                    <div class="nf-field-element">
                                       <select id="girl_residence_state" name="girl_residence_state" aria-invalid="false" value={formData.girl_residence_state} onChange={handleChange} required className="ninja-forms-field nf-element">
									   
                                        <option value="delhi" selected="selected">Delhi</option>
                                        <option value="andra-pradesh">Andra Pradesh</option>
                                        <option value="arunachal-pradesh">Arunachal Pradesh</option>
                                        <option value="assam">Assam</option>
                                        <option value="bihar">Bihar</option>
                                        <option value="chhattisgarh">Chhattisgarh</option>
                                        <option value="goa">Goa</option>
                                        <option value="gujarat">Gujarat</option>
                                        <option value="haryana">Haryana</option>
                                        <option value="himachal-pradesh">Himachal Pradesh</option>
                                        <option value="jammu-and-kashmir">Jammu and Kashmir</option>
                                        <option value="jharkhand">Jharkhand</option>
                                        <option value="karnataka">Karnataka</option>
                                        <option value="kerala">Kerala</option>
                                        <option value="madya-pradesh">Madya Pradesh</option>
                                        <option value="maharashtra">Maharashtra</option>
                                        <option value="manipur">Manipur</option>
                                        <option value="meghalaya">Meghalaya</option>
                                        <option value="mizoram">Mizoram</option>
                                        <option value="nagaland">Nagaland</option>
                                        <option value="orissa">Orissa</option>
                                        <option value="punjab">Punjab</option>
                                        <option value="rajasthan">Rajasthan</option>
                                        <option value="sikkim">Sikkim</option>
                                        <option value="tamil-nadu">Tamil Nadu</option>
                                        <option value="tripura">Tripura</option>
                                        <option value="uttaranchal">Uttaranchal</option>
                                        <option value="uttar-pradesh">Uttar Pradesh</option>
                                        <option value="west-bengal">West Bengal</option>
                                        <option value="andaman-and-nicobar-islands">Andaman and Nicobar Islands</option>
                                        <option value="chandigarh">Chandigarh</option>
                                        <option value="dadar-and-nagar-haveli">Dadar and Nagar Haveli</option>
                                        <option value="daman-and-diu">Daman and Diu</option>
                                        <option value="lakshadeep">Lakshadeep</option>
                                        <option value="other">Other</option>
                                      </select>
                                      <div for="nf-field-36_1"></div>
                                    </div>
                                    </div>
                                  </div>
                                  <div class="nf-after-field">
                                    <nf-section>
                                    <div class="nf-input-limit"></div>
                                    <div id="nf-error-36_1" class="nf-error-wrap nf-error" role="alert"></div>
                                    </nf-section>
                                  </div>
                                </div>
                              </nf-field>
                              <nf-field>
                                <div id="nf-field-37_1-container" class="nf-field-container listcountry-container  label-above  list-container">
                                  <div class="nf-before-field">
                                    <nf-section>
                                    </nf-section>
                                  </div>
                                  <div class="nf-field">
                                    <div id="nf-field-37_1-wrap" class="field-wrap listcountry-wrap list-wrap" data-field-id="37_1">
                                    <div class="nf-field-label"><label for="nf-field-37_1" id="nf-label-field-37_1" class="">Girl's Nationality <span class="ninja-forms-req-symbol">*</span> </label></div>
                                    <div class="nf-field-element">
                                       <select id="girl_nationality" name="girl_nationality" aria-invalid="false" value={formData.girl_nationality} onChange={handleChange} required className="ninja-forms-field nf-element">
									    <option value="IN" selected="selected">India</option>                                  
                                        <option value="AF">Afghanistan</option>
                                        <option value="AL">Albania</option>
                                        <option value="DZ">Algeria</option>
                                        <option value="AS">American Samoa</option>
                                        <option value="AD">Andorra</option>
                                        <option value="AO">Angola</option>
                                        <option value="AI">Anguilla</option>
                                        <option value="AQ">Antarctica</option>
                                        <option value="AG">Antigua And Barbuda</option>
                                        <option value="AR">Argentina</option>
                                        <option value="AM">Armenia</option>
                                        <option value="AW">Aruba</option>
                                        <option value="AU">Australia</option>
                                        <option value="AT">Austria</option>
                                        <option value="AZ">Azerbaijan</option>
                                        <option value="BS">Bahamas</option>
                                        <option value="BH">Bahrain</option>
                                        <option value="BD">Bangladesh</option>
                                        <option value="BB">Barbados</option>
                                        <option value="BY">Belarus</option>
                                        <option value="BE">Belgium</option>
                                        <option value="BZ">Belize</option>
                                        <option value="BJ">Benin</option>
                                        <option value="BM">Bermuda</option>
                                        <option value="BT">Bhutan</option>
                                        <option value="BO">Bolivia</option>
                                        <option value="BA">Bosnia And Herzegowina</option>
                                        <option value="BW">Botswana</option>
                                        <option value="BV">Bouvet Island</option>
                                        <option value="BR">Brazil</option>
                                        <option value="IO">British Indian Ocean Territory</option>
                                        <option value="BN">Brunei Darussalam</option>
                                        <option value="BG">Bulgaria</option>
                                        <option value="BF">Burkina Faso</option>
                                        <option value="BI">Burundi</option>
                                        <option value="KH">Cambodia</option>
                                        <option value="CM">Cameroon</option>
                                        <option value="CA">Canada</option>
                                        <option value="CV">Cape Verde</option>
                                        <option value="KY">Cayman Islands</option>
                                        <option value="CF">Central African Republic</option>
                                        <option value="TD">Chad</option>
                                        <option value="CL">Chile</option>
                                        <option value="CN">China</option>
                                        <option value="CX">Christmas Island</option>
                                        <option value="CC">Cocos (Keeling) Islands</option>
                                        <option value="CO">Colombia</option>
                                        <option value="KM">Comoros</option>
                                        <option value="CG">Congo</option>
                                        <option value="CD">Congo, The Democratic Republic Of The</option>
                                        <option value="CK">Cook Islands</option>
                                        <option value="CR">Costa Rica</option>
                                        <option value="CI">Cote D'Ivoire</option>
                                        <option value="HR">Croatia (Local Name: Hrvatska)</option>
                                        <option value="CU">Cuba</option>
                                        <option value="CY">Cyprus</option>
                                        <option value="CZ">Czech Republic</option>
                                        <option value="DK">Denmark</option>
                                        <option value="DJ">Djibouti</option>
                                        <option value="DM">Dominica</option>
                                        <option value="DO">Dominican Republic</option>
                                        <option value="EC">Ecuador</option>
                                        <option value="EG">Egypt</option>
                                        <option value="SV">El Salvador</option>
                                        <option value="GQ">Equatorial Guinea</option>
                                        <option value="ER">Eritrea</option>
                                        <option value="EE">Estonia</option>
                                        <option value="ET">Ethiopia</option>
                                        <option value="FK">Falkland Islands (Malvinas)</option>
                                        <option value="FO">Faroe Islands</option>
                                        <option value="FJ">Fiji</option>
                                        <option value="FI">Finland</option>
                                        <option value="FR">France</option>
                                        <option value="FX">France, Metropolitan</option>
                                        <option value="GF">French Guiana</option>
                                        <option value="PF">French Polynesia</option>
                                        <option value="TF">French Southern Territories</option>
                                        <option value="GA">Gabon</option>
                                        <option value="GM">Gambia</option>
                                        <option value="GE">Georgia</option>
                                        <option value="DE">Germany</option>
                                        <option value="GH">Ghana</option>
                                        <option value="GI">Gibraltar</option>
                                        <option value="GR">Greece</option>
                                        <option value="GL">Greenland</option>
                                        <option value="GD">Grenada</option>
                                        <option value="GP">Guadeloupe</option>
                                        <option value="GU">Guam</option>
                                        <option value="GT">Guatemala</option>
                                        <option value="GN">Guinea</option>
                                        <option value="GW">Guinea-Bissau</option>
                                        <option value="GY">Guyana</option>
                                        <option value="HT">Haiti</option>
                                        <option value="HM">Heard And Mc Donald Islands</option>
                                        <option value="VA">Holy See (Vatican City State)</option>
                                        <option value="HN">Honduras</option>
                                        <option value="HK">Hong Kong</option>
                                        <option value="HU">Hungary</option>
                                        <option value="IS">Iceland</option>
                                       
                                        <option value="ID">Indonesia</option>
                                        <option value="IR">Iran (Islamic Republic Of)</option>
                                        <option value="IQ">Iraq</option>
                                        <option value="IE">Ireland</option>
                                        <option value="IL">Israel</option>
                                        <option value="IT">Italy</option>
                                        <option value="JM">Jamaica</option>
                                        <option value="JP">Japan</option>
                                        <option value="JO">Jordan</option>
                                        <option value="KZ">Kazakhstan</option>
                                        <option value="KE">Kenya</option>
                                        <option value="KI">Kiribati</option>
                                        <option value="KP">Korea, Democratic People's Republic Of</option>
                                        <option value="KR">Korea, Republic Of</option>
                                        <option value="KW">Kuwait</option>
                                        <option value="KG">Kyrgyzstan</option>
                                        <option value="LA">Lao People's Democratic Republic</option>
                                        <option value="LV">Latvia</option>
                                        <option value="LB">Lebanon</option>
                                        <option value="LS">Lesotho</option>
                                        <option value="LR">Liberia</option>
                                        <option value="LY">Libyan Arab Jamahiriya</option>
                                        <option value="LI">Liechtenstein</option>
                                        <option value="LT">Lithuania</option>
                                        <option value="LU">Luxembourg</option>
                                        <option value="MO">Macau</option>
                                        <option value="MK">Macedonia, Former Yugoslav Republic Of</option>
                                        <option value="MG">Madagascar</option>
                                        <option value="MW">Malawi</option>
                                        <option value="MY">Malaysia</option>
                                        <option value="MV">Maldives</option>
                                        <option value="ML">Mali</option>
                                        <option value="MT">Malta</option>
                                        <option value="MH">Marshall Islands</option>
                                        <option value="MQ">Martinique</option>
                                        <option value="MR">Mauritania</option>
                                        <option value="MU">Mauritius</option>
                                        <option value="YT">Mayotte</option>
                                        <option value="MX">Mexico</option>
                                        <option value="FM">Micronesia, Federated States Of</option>
                                        <option value="MD">Moldova, Republic Of</option>
                                        <option value="MC">Monaco</option>
                                        <option value="MN">Mongolia</option>
                                        <option value="ME">Montenegro</option>
                                        <option value="MS">Montserrat</option>
                                        <option value="MA">Morocco</option>
                                        <option value="MZ">Mozambique</option>
                                        <option value="MM">Myanmar</option>
                                        <option value="NA">Namibia</option>
                                        <option value="NR">Nauru</option>
                                        <option value="NP">Nepal</option>
                                        <option value="NL">Netherlands</option>
                                        <option value="AN">Netherlands Antilles</option>
                                        <option value="NC">New Caledonia</option>
                                        <option value="NZ">New Zealand</option>
                                        <option value="NI">Nicaragua</option>
                                        <option value="NE">Niger</option>
                                        <option value="NG">Nigeria</option>
                                        <option value="NU">Niue</option>
                                        <option value="NF">Norfolk Island</option>
                                        <option value="MP">Northern Mariana Islands</option>
                                        <option value="NO">Norway</option>
                                        <option value="OM">Oman</option>
                                        <option value="PK">Pakistan</option>
                                        <option value="PW">Palau</option>
                                        <option value="PA">Panama</option>
                                        <option value="PG">Papua New Guinea</option>
                                        <option value="PY">Paraguay</option>
                                        <option value="PE">Peru</option>
                                        <option value="PH">Philippines</option>
                                        <option value="PN">Pitcairn</option>
                                        <option value="PL">Poland</option>
                                        <option value="PT">Portugal</option>
                                        <option value="PR">Puerto Rico</option>
                                        <option value="QA">Qatar</option>
                                        <option value="RE">Reunion</option>
                                        <option value="RO">Romania</option>
                                        <option value="RU">Russian Federation</option>
                                        <option value="RW">Rwanda</option>
                                        <option value="KN">Saint Kitts And Nevis</option>
                                        <option value="LC">Saint Lucia</option>
                                        <option value="VC">Saint Vincent And The Grenadines</option>
                                        <option value="WS">Samoa</option>
                                        <option value="SM">San Marino</option>
                                        <option value="ST">Sao Tome And Principe</option>
                                        <option value="SA">Saudi Arabia</option>
                                        <option value="SN">Senegal</option>
                                        <option value="RS">Serbia</option>
                                        <option value="SC">Seychelles</option>
                                        <option value="SL">Sierra Leone</option>
                                        <option value="SG">Singapore</option>
                                        <option value="SK">Slovakia (Slovak Republic)</option>
                                        <option value="SI">Slovenia</option>
                                        <option value="SB">Solomon Islands</option>
                                        <option value="SO">Somalia</option>
                                        <option value="ZA">South Africa</option>
                                        <option value="GS">South Georgia, South Sandwich Islands</option>
                                        <option value="ES">Spain</option>
                                        <option value="LK">Sri Lanka</option>
                                        <option value="SH">St. Helena</option>
                                        <option value="PM">St. Pierre And Miquelon</option>
                                        <option value="SD">Sudan</option>
                                        <option value="SR">Suriname</option>
                                        <option value="SJ">Svalbard And Jan Mayen Islands</option>
                                        <option value="SZ">Swaziland</option>
                                        <option value="SE">Sweden</option>
                                        <option value="CH">Switzerland</option>
                                        <option value="SY">Syrian Arab Republic</option>
                                        <option value="TW">Taiwan</option>
                                        <option value="TJ">Tajikistan</option>
                                        <option value="TZ">Tanzania, United Republic Of</option>
                                        <option value="TH">Thailand</option>
                                        <option value="TL">Timor-Leste (East Timor)</option>
                                        <option value="TG">Togo</option>
                                        <option value="TK">Tokelau</option>
                                        <option value="TO">Tonga</option>
                                        <option value="TT">Trinidad And Tobago</option>
                                        <option value="TN">Tunisia</option>
                                        <option value="TR">Turkey</option>
                                        <option value="TM">Turkmenistan</option>
                                        <option value="TC">Turks And Caicos Islands</option>
                                        <option value="TV">Tuvalu</option>
                                        <option value="UG">Uganda</option>
                                        <option value="UA">Ukraine</option>
                                        <option value="AE">United Arab Emirates</option>
                                        <option value="GB">United Kingdom</option>
                                        <option value="US">United States</option>
                                        <option value="UM">United States Minor Outlying Islands</option>
                                        <option value="UY">Uruguay</option>
                                        <option value="UZ">Uzbekistan</option>
                                        <option value="VU">Vanuatu</option>
                                        <option value="VE">Venezuela</option>
                                        <option value="VN">Viet Nam</option>
                                        <option value="VG">Virgin Islands (British)</option>
                                        <option value="VI">Virgin Islands (U.S.)</option>
                                        <option value="WF">Wallis And Futuna Islands</option>
                                        <option value="EH">Western Sahara</option>
                                        <option value="YE">Yemen</option>
                                        <option value="YU">Yugoslavia</option>
                                        <option value="ZM">Zambia</option>
                                        <option value="ZW">Zimbabwe</option>
                                      </select>
                                      <div for="nf-field-37_1"></div>
                                    </div>
                                    </div>
                                  </div>
                                  <div class="nf-after-field">
                                    <nf-section>
                                    <div class="nf-input-limit"></div>
                                    <div id="nf-error-37_1" class="nf-error-wrap nf-error" role="alert"></div>
                                    </nf-section>
                                  </div>
                                </div>
                              </nf-field>
                              <nf-field>
                                <div id="nf-field-50_1-container" class="nf-field-container firstname-container  label-above ">
                                  <div class="nf-before-field">
                                    <nf-section>
                                    </nf-section>
                                  </div>
                                  <div class="nf-field">
                                    <div id="nf-field-50_1-wrap" class="field-wrap firstname-wrap" data-field-id="50_1">
                                    <div class="nf-field-label"><label for="nf-field-50_1" id="nf-label-field-50_1" class="">Name <span class="ninja-forms-req-symbol">*</span> </label></div>
                                    <div class="nf-field-element">
                                       <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                                    </div>
                                    </div>
                                  </div>
                                  <div class="nf-after-field">
                                    <nf-section>
                                    <div class="nf-input-limit"></div>
                                    <div id="nf-error-50_1" class="nf-error-wrap nf-error" role="alert"></div>
                                    </nf-section>
                                  </div>
                                </div>
                              </nf-field>
                              <nf-field>
                                <div id="nf-field-38_1-container" class="nf-field-container phone-container  label-above  textbox-container">
                                  <div class="nf-before-field">
                                    <nf-section>
                                    </nf-section>
                                  </div>
                                  <div class="nf-field">
                                    <div id="nf-field-38_1-wrap" class="field-wrap phone-wrap textbox-wrap" data-field-id="38_1">
                                    <div class="nf-field-label"><label for="nf-field-38_1" id="nf-label-field-38_1" class="">WhatsApp Number <span class="ninja-forms-req-symbol">*</span> </label></div>
                                    <div class="nf-field-element">
                                      <input type="tel" id="phone_no" name="phone_no" value={formData.phone_no} onChange={handleChange} required />
                                    </div>
                                    </div>
                                  </div>
                                  <div class="nf-after-field">
                                    <nf-section>
                                    <div class="nf-input-limit"></div>
                                    <div id="nf-error-38_1" class="nf-error-wrap nf-error" role="alert"></div>
                                    </nf-section>
                                  </div>
                                </div>
                              </nf-field>
                              <nf-field>
                                <div id="nf-field-39_1-container" class="nf-field-container email-container  label-above ">
                                  <div class="nf-before-field">
                                    <nf-section>
                                    </nf-section>
                                  </div>
                                  <div class="nf-field">
                                    <div id="nf-field-39_1-wrap" class="field-wrap email-wrap" data-field-id="39_1">
                                    <div class="nf-field-label"><label for="nf-field-39_1" id="nf-label-field-39_1" class="">Email <span class="ninja-forms-req-symbol">*</span> </label></div>
                                    <div class="nf-field-element">
                                     <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                                    </div>
                                    </div>
                                  </div>
                                  <div class="nf-after-field">
                                    <nf-section>
                                    <div class="nf-input-limit"></div>
                                    <div id="nf-error-39_1" class="nf-error-wrap nf-error" role="alert"></div>
                                    </nf-section>
                                  </div>
                                </div>
                              </nf-field>
                              <nf-field>
                                <div id="nf-field-40_1-container" class="nf-field-container recaptcha-container  label-above  textbox-container">
                                  <div class="nf-before-field">
                                    <nf-section>
                                    </nf-section>
                                  </div>
                                  <div class="nf-field">
                                    <div id="nf-field-40_1-wrap" class="field-wrap recaptcha-wrap textbox-wrap" data-field-id="40_1">
                                    <div class="nf-field-label"><label for="nf-field-40_1" id="nf-label-field-40_1" class="">  </label></div>
                                    <div class="nf-field-element">
                                      <input id="nf-field-40_1" name="nf-field-40_1" class="ninja-forms-field nf-element" type="hidden" value="" />
                                      <div class="g-recaptcha" data-callback="nf_recaptcha_response_40_1" data-theme="light" data-sitekey="6LeaabwZAAAAANzeD-6HbORtd9EElEAotUWcRMlx" data-fieldid="40_1"></div>
                                    </div>
                                    </div>
                                  </div>
                                  <div class="nf-after-field">
                                    <nf-section>
                                    <div class="nf-input-limit"></div>
                                    <div id="nf-error-40_1" class="nf-error-wrap nf-error" role="alert"></div>
                                    </nf-section>
                                  </div>
                                </div>
                              </nf-field>
                              <nf-field>
                                <div id="nf-field-41_1-container" class="nf-field-container submit-container  label-above  textbox-container">
                                  <div class="nf-before-field">
                                    <nf-section>
                                    </nf-section>
                                  </div>
                                  <div class="nf-field">
                                    <div id="nf-field-41_1-wrap" class="field-wrap submit-wrap textbox-wrap" data-field-id="41_1">
                                    <div class="nf-field-label"></div>
                                    <div class="nf-field-element">
                                      <input type="hidden" name="fee_action" value="yes" />
                                      <input id="nf-field-41_1" class="ninja-forms-field nf-element " type="submit" value="Submit" />
                                    </div>
                                    <div class="nf-error-wrap"></div>
                                    </div>
                                  </div>
                                  <div class="nf-after-field">
                                    <nf-section>
                                    <div class="nf-input-limit"></div>
                                    <div id="nf-error-41_1" class="nf-error-wrap nf-error" role="alert"></div>
                                    </nf-section>
                                  </div>
                                </div>
                              </nf-field>
                              </nf-fields-wrap>
                            </div>
                          </form>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <IncludeSidebar/>
        </div>
        <IncludeMariigeCertificate/>
      </div>
      <Footer />
    </>
  );
}

export default Fee;
