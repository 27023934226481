import React from 'react';
import { Helmet } from 'react-helmet';
import CssHeader from './CssHeader';
import Header from './Header';
import Footer from './Footer';
import IncludeSidebar from './IncludeSidebar';
import IncludeMariigeCertificate from "./IncludeMariigeCertificate";

function Documents() {
  return (
    <>
      <Helmet>
        <title>Court Marriage</title>
        <meta name="description" content="Court Marriage." />
        <meta name="keywords" content="Delhi Court Marriage" />
      </Helmet>
      <CssHeader />
      <Header />
      <div id="main" className="site-main hfeed site">
        <div id="main-content" className="main-content">
          <div id="primary" className="content-area">
            <div id="content" className="site-content" role="main">

              <div id="post-52" class="post-52 page type-page status-publish hentry">
                <header class="entry-header">
                  <h1 class="entry-title">Documents</h1>
                </header>
                <div class="entry-content">
                  <div class="fl-builder-content fl-builder-content-52 fl-builder-content-primary fl-builder-global-templates-locked" data-post-id="52">
                  <div class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5f2fa53b6bc02" data-node="5f2fa53b6bc02">
                    <div class="fl-row-content-wrap">
                      <div class="fl-col-content fl-node-content">
                        <div class="fl-module fl-module-callout fl-node-5f2f952e43d61" data-node="5f2f952e43d61">
                        <div class="fl-module-content fl-node-content">
                          <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                            <div class="fl-callout-content">
                              <h3 class="fl-callout-title"><span class="fl-icon"><br />
                              <i class="fi-torsos-male-female" aria-hidden="true"></i><br />
                              </span><br />
                              <span class="fl-callout-title-text">Age For Court Marriage</span>
                              </h3>
                              <div class="fl-callout-text-wrap">
                              <div class="fl-callout-text">
                                <p>Boy should be above 21 years and girl above 18 years</p>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f958d55460" data-node="5f2f958d55460">
                        <div class="fl-module-content fl-node-content">
                          <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                            <div class="fl-callout-content">
                              <h3 class="fl-callout-title">
                              <span class="fl-callout-title-text">Required Documents</span>
                              </h3>
                              <div class="fl-callout-text-wrap">
                              <div class="fl-callout-text"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f95c432d10" data-node="5f2f95c432d10">
                        <div class="fl-module-content fl-node-content">
                          <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                            <div class="fl-callout-content">
                              <h3 class="fl-callout-title"><span class="fl-icon"><br />
                              <i class="fi-elevator" aria-hidden="true"></i><br />
                              </span><br />
                              <span class="fl-callout-title-text">Age Proof (Any One)</span>
                              </h3>
                              <div class="fl-callout-text-wrap">
                              <div class="fl-callout-text">
                                <ul>
                                  <li>Aadhar Card</li>
                                  <li>Matriculation Certificate</li>
                                  <li>Passport</li>
                                  <li>Driving License</li>
                                  <li>PAN Card</li>
                                  <li>Birth Certificate</li>
                                  <li>Voter I-Card</li>
                                  <li>Ration Card</li>
                                  <li>School Document</li>
                                  <li>
                                    College I-card or Any *Other Relevant Proof.<br />
                                    <hr />
                                  </li>
                                </ul>
                                <p>&nbsp;</p>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f9614afb43" data-node="5f2f9614afb43">
                        <div class="fl-module-content fl-node-content">
                          <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                            <div class="fl-callout-content">
                              <h3 class="fl-callout-title"><span class="fl-icon"><br />
                              <i class="fas fa-home-lg" aria-hidden="true"></i><br />
                              </span><br />
                              <span class="fl-callout-title-text">Address Proof (Any One)</span>
                              </h3>
                              <div class="fl-callout-text-wrap">
                              <div class="fl-callout-text">
                                <ul>
                                  <li>Voter I-card</li>
                                  <li>Aadhar Card</li>
                                  <li>Ration Card</li>
                                  <li>Passport</li>
                                  <li>Driving License</li>
                                  <li>Bank Passbook</li>
                                  <li>Phone/Electricity Bill</li>
                                  <li>Voter I-card of Father or Mother</li>
                                  <li>School/College I-card</li>
                                  <li>Service I-Card with Address</li>
                                  <li>
                                    Rent agreement Or Any *Other Relevant Proof<br />
                                    <hr />
                                  </li>
                                  <li>5 Passport Size Photo both Boy and Girl</li>
                                  <li>
                                    2 witnesses (Any ID/Address Proof &amp; 3 Passport Size Photo)<br />
                                    <hr />
                                  </li>
                                </ul>
                                <p>&nbsp;</p>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f96651a3c9" data-node="5f2f96651a3c9">
                        <div class="fl-module-content fl-node-content">
                          <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                            <div class="fl-callout-content">
                              <h3 class="fl-callout-title"><span class="fl-icon"><br />
                              <i class="fi-torsos-all" aria-hidden="true"></i><br />
                              </span><br />
                              <span class="fl-callout-title-text">Witnesses</span>
                              </h3>
                              <div class="fl-callout-text-wrap">
                              <div class="fl-callout-text">
                                <ul>
                                  <li>Require total 2 witnesses from any side any male / female who has completed the age of 18 years having any ID and address proof can be your witness. (Witness may be your friend / family member or any of your known person).</li>
                                  <li>For Muslim Marriage Act required only male witnesses.</li>
                                  <li>
                                    Only for Special Marriage Act required total 3 witnesses.<br />
                                    <hr />
                                  </li>
                                </ul>
                                <p>&nbsp;</p>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="fl-module fl-module-callout fl-node-5f2f96a94581b" data-node="5f2f96a94581b">
                        <div class="fl-module-content fl-node-content">
                          <div class="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                            <div class="fl-callout-content">
                              <h3 class="fl-callout-title">
                              <span class="fl-callout-title-text">Other Documents</span>
                              </h3>
                              <div class="fl-callout-text-wrap">
                              <div class="fl-callout-text">
                                <p>If any party is divorcee / widow / widower (copy of that proof)</p>
                                <hr />
                                <p>&nbsp;</p>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <IncludeSidebar/>
        </div>
        <IncludeMariigeCertificate/>
      </div>
      <Footer />
    </>
  );
}

export default Documents;
