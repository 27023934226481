import React from 'react';
import { Helmet } from 'react-helmet';
import CssHeader from './CssHeader';
import Header from './Header';
import Footer from './Footer';
import IncludeSidebar from './IncludeSidebar';
import IncludeMariigeCertificate from "./IncludeMariigeCertificate";

function About() {
	return (
		<>
		  <Helmet>
			<title>Court Marriage</title>
			<meta name="description" content="Court Marriage." />
			<meta name="keywords" content="Delhi Court Marriage" />
		  </Helmet>
		  <CssHeader />
		  <Header />
		  <div id="main" className="site-main hfeed site">
			<div id="main-content" className="main-content">
			  <div id="primary" className="content-area">
				<div id="content" className="site-content" role="main">				 
				
					<div id="post-52" className="post-52 page type-page status-publish hentry">
						<header class="entry-header">
							<h1 class="entry-title">About Us</h1>
						</header>
						<div class="entry-content">
							<div class="fl-callout-content">
								<h3 class="fl-callout-title">
									<span class="fl-callout-title-text">We register marriages.</span>
								</h3>
								<div class="fl-callout-text-wrap">
									<div class="fl-callout-text">
									<p>We add value to marriages.</p>
									<p>We devise innovative service delivery model for registration of marriages to benefit the citizen.</p>
									<p>As an application towards providing speedy services to the citizens, <b>MARRIAGE</b> shall be totally web accessed.</p>
									<p>This shall enable greater mobility of registrars as the site may be accessed from any location using Internet facilities from any ISP.</p>
									<p>Overall responsibility to ensure that the policies and guidelines for <b>MARRIAGE</b> are properly implemented</p>
									<p>Computerisation of the process of registration of marriages necessitates the need to preserve data in electronic form and aid that the incumbents get a fair trial in the event of any dispute arising out of the marriage, viz.</p>
									<ul>
										<li>Polygamy / Polyandry</li>
										<li>Legal Separation</li>
										<li>Grant of Citizenship</li>
										<li>Other Legal and Social Angles</li>
									</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
			  </div>
			  <IncludeSidebar/>
			</div>
			<IncludeMariigeCertificate/>
		  </div>
		  <Footer />
		</>
	  );
	}
	

export default About;
