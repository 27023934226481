import React from 'react';

const IncludeSidebar = () => {
  return (
    <>
    <div id="content-sidebar" class="content-sidebar widget-area" role="complementary">
      <aside id="text-5" class="widget widget_text">
          <h1>24X7 Helpline: 9999985852</h1>
          <h1 class="widget-title">Note</h1>
          <div class="textwidget">
            <p>Court Marriage is an easy process. Please do not contact any Middleman/Touts, Contact Us Directly.</p>
          </div>
      </aside>
      <aside id="text-6" class="widget widget_text">
          <h1 class="widget-title">TATKAL COURT MARRIAGE / REGISTRATION BENEFITS</h1>
          <div class="textwidget">
            <p>No Notice @ Home</p>
            <p>No Waiting - No Que</p>
            <p>Get Court Marriage / Registration Certificate Same Day Within 2/3 Hours</p>
          </div>
      </aside>
      <aside id="text-7" class="widget widget_text">
          <h1 class="widget-title">TATKAL CERTIFICATE</h1>
          <div class="textwidget">
            <p>In April 2014, the Revenue Department of Delhi government introduced a tatkal service ensuring a single-day authorisation of the marriage under which the registration process will be undertaken on priority. The service, which became operational on April 22, 2014 enables citizens to register their nuptials and get a certificate issued within 24 hours on payment of tatkal fee.</p>
          </div>
      </aside>
      <aside id="text-8" class="widget widget_text">
          <h1 class="widget-title">Landmark Judgments</h1>
          <div class="textwidget">
           
            <ol>
                <li><a href="#" rel="noopener">Police Protection</a></li>
                <li><a href="#" rel="noopener">Special Marriage Act</a></li>
                <li><a href="#" rel="noopener">Legal Marriage</a></li>
                <li><a href="#" rel="noopener">Marriage Law for NRIs</a></li>
                <li><a href="tell:9999985852" rel="noopener">Call: 9999985852</a></li>
                
            </ol>
          </div>
      </aside>
      
    </div>
    
    </>
  );
};

export default IncludeSidebar;
