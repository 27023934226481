import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import CssHeader from './CssHeader';
import Header from './Header';
import Footer from './Footer';
import IncludeSidebar from './IncludeSidebar';
import IncludeMariigeCertificate from "./IncludeMariigeCertificate";
import axios from 'axios';

function LegalHelp() {
	
	const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        category: '',
        service: '',
        detail: '',       
        legal_action: 'yes',
        honeypot: ''
    });

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData(prevState => ({
		  ...prevState,
		  [name]: value
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		
		if (formData.honeypot) {
			  alert('Spam detected. Please try again.');
			  return;
		  }
		
		axios.post('https://admissionschoolofbiodesign.in/kite/court_marrige.php', JSON.stringify(formData),{
			headers: {
				'Content-Type': 'application/json'
			  }
			})
			.then(response => {
				
				if (response.data.status === 'success') {			  
				  window.location.href = 'http://localhost:3000/thanks';
				} else {				
				  alert(response.data.message);
				}
			})
			.catch(error => {
				//console.error('There was an error submitting the form!', error);			
				alert('There was an error submitting the form. Please try again.');
			});
	};

  return (
    <>
      <Helmet>
        <title>Court Marriage</title>
        <meta name="description" content="Court Marriage." />
        <meta name="keywords" content="Delhi Court Marriage" />
      </Helmet>
      <CssHeader />
      <Header />
      <div id="main" className="site-main hfeed site">
        <div id="main-content" className="main-content">
          <div id="primary" className="content-area">
            <div id="content" className="site-content" role="main">

            <div id="post-70" class="post-70 page type-page status-publish hentry">
					   <header class="entry-header">
						  <h1 class="entry-title">Legal Help</h1>
					   </header>
					  
					   <div class="entry-content">
						  <div class="fl-builder-content fl-builder-content-70 fl-builder-content-primary fl-builder-global-templates-locked" data-post-id="70">
							 <div class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5f2f8734dfaf1" data-node="5f2f8734dfaf1">
								<div class="fl-row-content-wrap">
								   <div class="fl-row-content fl-row-fixed-width fl-node-content">
									  <div class="fl-col-group fl-node-5f2f8734e12e3" data-node="5f2f8734e12e3">
										 <div class="fl-col fl-node-5f2f8734e13bf" data-node="5f2f8734e13bf">
											<div class="fl-col-content fl-node-content">
											   
											  
											   <div class="fl-module fl-module-widget fl-node-5f76b5ac2cc5a" data-node="5f76b5ac2cc5a">
												  <div class="fl-module-content fl-node-content">
													 <div class="fl-widget">
														<div class="widget widget_ninja_forms_widget">
														   <noscript class="ninja-forms-noscript-message">
															  Notice: JavaScript is required for this content.
														   </noscript>
														   <span id="nf-form-title-3" class="nf-form-title">
															  <h3>Book your appointment</h3>
														   </span>
														   <div class="nf-form-layout">
																
																<form className="cmxform" id="commonForm" method="post" onSubmit={handleSubmit}>		
																	<div>
																	   <div class="nf-before-form-content">
																		  <nf-section>
																			 <div class="nf-form-fields-required">Fields marked with an <span class="ninja-forms-req-symbol">*</span> are required</div>
																		  </nf-section>
																	   </div>
																	   
																	   <div class="nf-form-content ">
																		  <nf-fields-wrap>
																			 <nf-field>
																				<div id="nf-field-5-container" class="nf-field-container textbox-container  label-above ">
																				   <div class="nf-before-field">
																					  <nf-section>
																					  </nf-section>
																				   </div>
																				   <div class="nf-field">
																					  <div id="nf-field-5-wrap" class="field-wrap textbox-wrap" data-field-id="5">
																						 <div class="nf-field-label"><label for="nf-field-5" id="nf-label-field-5" class="">Name <span class="ninja-forms-req-symbol">*</span> </label></div>
																						 <div class="nf-field-element">
																							<input type="text"  class="ninja-forms-field nf-element" placeholder="Enter your name" id="name" name="name" value={formData.name} onChange={handleChange} required />
																						 </div>
																					  </div>
																				   </div>
																				   <div class="nf-after-field">
																					  <nf-section>
																						 <div class="nf-input-limit"></div>
																						 <div id="nf-error-5" class="nf-error-wrap nf-error" role="alert"></div>
																					  </nf-section>
																				   </div>
																				</div>
																			 </nf-field>
																			 <nf-field>
																				<div id="nf-field-6-container" class="nf-field-container email-container  label-above ">
																				   <div class="nf-before-field">
																					  <nf-section>
																					  </nf-section>
																				   </div>
																				   <div class="nf-field">
																					  <div id="nf-field-6-wrap" class="field-wrap email-wrap" data-field-id="6">
																						 <div class="nf-field-label"><label for="nf-field-6" id="nf-label-field-6" class="">Email <span class="ninja-forms-req-symbol">*</span> </label></div>
																						 <div class="nf-field-element">
																							<input type="email"  class="ninja-forms-field nf-element" id="email" name="email"  placeholder="Enter your e-mail" value={formData.email} onChange={handleChange} required />
																						 </div>
																					  </div>
																				   </div>
																				   <div class="nf-after-field">
																					  <nf-section>
																						 <div class="nf-input-limit"></div>
																						 <div id="nf-error-6" class="nf-error-wrap nf-error" role="alert"></div>
																					  </nf-section>
																				   </div>
																				</div>
																			 </nf-field>
																			 <nf-field>
																				<div id="nf-field-7-container" class="nf-field-container phone-container  label-above  textbox-container">
																				   <div class="nf-before-field">
																					  <nf-section>
																					  </nf-section>
																				   </div>
																				   <div class="nf-field">
																					  <div id="nf-field-7-wrap" class="field-wrap phone-wrap textbox-wrap" data-field-id="7">
																						 <div class="nf-field-label"><label for="nf-field-7" id="nf-label-field-7" class="">Enter your mobile <span class="ninja-forms-req-symbol">*</span> </label></div>
																						 <div class="nf-field-element">
																							<input type="tel" class="ninja-forms-field nf-element" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
																						 </div>
																					  </div>
																				   </div>
																				   <div class="nf-after-field">
																					  <nf-section>
																						 <div class="nf-input-limit"></div>
																						 <div id="nf-error-7" class="nf-error-wrap nf-error" role="alert"></div>
																					  </nf-section>
																				   </div>
																				</div>
																			 </nf-field>
																			 <nf-field>
																				<div id="nf-field-8-container" class="nf-field-container listselect-container  label-above  list-container">
																				   <div class="nf-before-field">
																					  <nf-section>
																					  </nf-section>
																				   </div>
																				   <div class="nf-field">
																					  <div id="nf-field-8-wrap" class="field-wrap listselect-wrap list-wrap list-select-wrap" data-field-id="8">
																						 <div class="nf-field-label"><label for="nf-field-8" id="nf-label-field-8" class="">Select a Category <span class="ninja-forms-req-symbol">*</span> </label></div>
																						 <div class="nf-field-element">
																							<select id="category" name="category"  value={formData.category} onChange={handleChange} required className="ninja-forms-field nf-element">
																							   <option value="Legal Help" selected="selected">Legal Help</option>
																							   <option value="administrative-law">Court Marriage</option>
																							   <option value="adoption">Love Marriage</option>
																							   <option value="advertising">Tatkal Marriage</option>
																							   <option value="animal-laws">Same Day Marrigae</option>
																							   <option value="animal-laws">Arya Samaj Marriage</option>
																							   <option value="administrative-law">Administrative Law</option>
																							   <option value="adoption">Adoption</option>
																							   <option value="advertising">Advertising</option>
																							   <option value="animal-laws">Animal Laws</option>
																							   <option value="arbitration-and-mediation">Arbitration and Mediation</option>
																							   <option value="banking">Banking</option>
																							   <option value="bankruptcy-and-debt">Bankruptcy and Debt</option>
																							   <option value="cheque-bounce">Cheque Bounce</option>
																							   <option value="child-custody">Child Custody</option>
																							   <option value="civil">Civil</option>
																							   <option value="commercial">Commercial</option>
																							   <option value="consumer-protection">Consumer Protection</option>
																							   <option value="contracts-and-agreements">Contracts and Agreements</option>
																							   <option value="court-marriage-and-registration">Court Marriage &amp; Registration</option>
																							   <option value="criminal">Criminal</option>
																							   <option value="Excise">Customs</option>
																							   <option value="Internet">Cyber</option>
																							   <option value="debt-and-lending-agreement">Debt and Lending Agreement</option>
																							   <option value="debt-collection">Debt Collection</option>
																							   <option value="divorce">Divorce</option>
																							   <option value="documentation">Documentation</option>
																							   <option value="domestic-violence">Domestic Violence</option>
																							   <option value="election-campaign-and-political-laws">Election Campaign and Political Laws</option>
																							   <option value="employment-and-labour">Employment and Labour</option>
																							   <option value="environment-and-natural-resources">Environment and Natural Resources</option>
																							   <option value="equipment-finance-and-leasing">Equipment Finance and Leasing</option>
																							   <option value="family">Family</option>
																							   <option value="financial-markets-and-services">Financial Markets and Services</option>
																							   <option value="government-contracts">Government Contracts</option>
																							   <option value="human-rights">Human Rights</option>
																							   <option value="immigration">Immigration</option>
																							   <option value="industrial-laws">Industrial Laws</option>
																							   <option value="insurance">Insurance</option>
																							   <option value="Copyright">Intellectual Property</option>
																							   <option value="international-laws">International Laws</option>
																							   <option value="it-contracts">IT Contracts</option>
																							   <option value="juvenile">Juvenile</option>
																							   <option value="landlord-and-tenant">Landlord and Tenant</option>
																							   <option value="licensing">Licensing</option>
																							   <option value="mail-fraud">Mail Fraud</option>
																							   <option value="maternity">Maternity</option>
																							   <option value="Communication">Media</option>
																							   <option value="mergers-and-acquisition">Mergers and Acquisition</option>
																							   <option value="motor-accident">Motor Accident </option>
																							   <option value="outsourcing-agreement-laws">Outsourcing Agreement/Laws</option>
																							   <option value="partnership">Partnership</option>
																							   <option value="police-laws">Police Laws</option>
																							   <option value="power-of-attorney">Power of Attorney</option>
																							   <option value="privacy">Privacy</option>
																							   <option value="property">Property</option>
																							   <option value="registration">Registration</option>
																							   <option value="rti">RTI</option>
																							   <option value="sale">Sale</option>
																							   <option value="sale-of-goods">Sale of Goods</option>
																							   <option value="sex-crime">Sex Crime</option>
																							   <option value="sexual-harassment-at-workplace">Sexual Harassment at Workplace</option>
																							   <option value="telecommunication">Telecommunication</option>
																							   <option value="torts">Torts</option>
																							   <option value="transportation">Transportation</option>
																							   <option value="trust-and-society-ngo">Trust and Society (NGO)</option>
																							   <option value="will">Will</option>
																							</select>
																							<div for="nf-field-8"></div>
																						 </div>
																					  </div>
																				   </div>
																				   <div class="nf-after-field">
																					  <nf-section>
																						 <div class="nf-input-limit"></div>
																						 <div id="nf-error-8" class="nf-error-wrap nf-error" role="alert"></div>
																					  </nf-section>
																				   </div>
																				</div>
																			 </nf-field>
																			 <nf-field>
																				<div id="nf-field-9-container" class="nf-field-container listselect-container  label-above  list-container">
																				   <div class="nf-before-field">
																					  <nf-section>
																					  </nf-section>
																				   </div>
																				   <div class="nf-field">
																					  <div id="nf-field-9-wrap" class="field-wrap listselect-wrap list-wrap list-select-wrap" data-field-id="9">
																						 <div class="nf-field-label"><label for="nf-field-9" id="nf-label-field-9" class="">Select a Service <span class="ninja-forms-req-symbol">*</span> </label></div>
																						 <div class="nf-field-element">
																							<select id="service" name="service" value={formData.service} onChange={handleChange} required className="ninja-forms-field nf-element">
																							   <option value="want-to-file-a-new-case" selected="selected">Want to file a new case</option>
																							   <option value="want-to-send-respond-to-a-legal-notice">Want to send/respond to a Legal Notice</option>
																							   <option value="need-a-lawyer-to-defend-a-case">Need a Lawyer to defend a case</option>
																							   <option value="want-to-draft-review-an-agreement-document">Want to draft/review an agreement/document</option>
																							   <option value="want-lawyer-for-ongoing-case">Want Lawyer for ongoing case</option>
																							   <option value="want-registration-services">Want Registration services</option>
																							   <option value="want-consultation-advice-only">Want Consultation/Advice Only</option>
																							   <option value="not-sure-what-to-do-next">Not sure what to do next</option>
																							   <option value="want-legal-opinion">Want Legal Opinion</option>
																							</select>
																							<div for="nf-field-9"></div>
																						 </div>
																					  </div>
																				   </div>
																				   <div class="nf-after-field">
																					  <nf-section>
																						 <div class="nf-input-limit"></div>
																						 <div id="nf-error-9" class="nf-error-wrap nf-error" role="alert"></div>
																					  </nf-section>
																				   </div>
																				</div>
																			 </nf-field>
																			 <nf-field>
																				<div id="nf-field-11-container" class="nf-field-container textarea-container  label-above ">
																				   <div class="nf-before-field">
																					  <nf-section>
																					  </nf-section>
																				   </div>
																				   <div class="nf-field">
																					  <div id="nf-field-11-wrap" class="field-wrap textarea-wrap" data-field-id="11">
																						 <div class="nf-field-label"><label for="nf-field-11" id="nf-label-field-11" class="">Details <span class="ninja-forms-req-symbol">*</span> </label></div>
																						 <div class="nf-field-element">
																							<textarea id="detail" name="detail" value={formData.detail} onChange={handleChange} required className="ninja-forms-field nf-element"></textarea>
																						 </div>
																					  </div>
																				   </div>
																				   <div class="nf-after-field">
																					  <nf-section>
																						 <div class="nf-input-limit"></div>
																						 <div id="nf-error-11" class="nf-error-wrap nf-error" role="alert"></div>
																					  </nf-section>
																				   </div>
																				</div>
																			 </nf-field>
																			 <nf-field>
																				<div id="nf-field-13-container" class="nf-field-container  label-right ">
																				 
																			 <div class="nf-field">
																				<div id="nf-field-25-wrap" class="field-wrap checkbox-wrap" data-field-id="25">															
																					  <input type="checkbox" name="term" id="term" value="1" required="" checked="" />
																					  <label for="term">I accept the Terms & Conditions *</label>
																					
																					
																				   
																				</div>
																			 </div>
																				   
																				  
																				 
																				</div>
																			 </nf-field>
																			 <nf-field>
																				<div id="nf-field-14-container" class="nf-field-container recaptcha-container  label-above  textbox-container">
																				   <div class="nf-before-field">
																					  <nf-section>
																					  </nf-section>
																				   </div>
																				   <div class="nf-field">
																					  <div id="nf-field-14-wrap" class="field-wrap recaptcha-wrap textbox-wrap" data-field-id="14">
																						 <div class="nf-field-label"><label for="nf-field-14" id="nf-label-field-14" class="">  </label></div>
																						 <div class="nf-field-element">
																							<input id="nf-field-14" name="nf-field-14" class="ninja-forms-field nf-element" type="hidden" value="" />
																						   
																						 </div>
																					  </div>
																				   </div>
																				   <div class="nf-after-field">
																					  <nf-section>
																						 <div class="nf-input-limit"></div>
																						 <div id="nf-error-14" class="nf-error-wrap nf-error" role="alert"></div>
																					  </nf-section>
																				   </div>
																				</div>
																			 </nf-field>
																			 <nf-field>
																				<div id="nf-field-10-container" class="nf-field-container submit-container  label-above  textbox-container">
																				   <div class="nf-before-field">
																					  <nf-section>
																					  </nf-section>
																				   </div>
																				   <div class="nf-field">
																					  <div id="nf-field-10-wrap" class="field-wrap submit-wrap textbox-wrap" data-field-id="10">
																						 <div class="nf-field-label"></div>
																						 <div class="nf-field-element">
																							<input type="hidden" name="legal_action" value="yes" />
																							<input id="nf-field-10" class="ninja-forms-field nf-element " name="submit" type="submit" value="Submit" />
																						 </div>
																						 <div class="nf-error-wrap"></div>
																					  </div>
																				   </div>
																				   <div class="nf-after-field">
																					  <nf-section>
																						 <div class="nf-input-limit"></div>
																						 <div id="nf-error-10" class="nf-error-wrap nf-error" role="alert"></div>
																					  </nf-section>
																				   </div>
																				</div>
																			 </nf-field>
																		  </nf-fields-wrap>
																	   </div>
																	   
																	   <div class="nf-after-form-content">
																		  <nf-section>
																			 <div id="nf-form-errors-2" class="nf-form-errors" role="alert">
																				<nf-errors></nf-errors>
																			 </div>
																			 <div class="nf-form-hp">
																				<nf-section>
																				   <label for="nf-field-hp-2" aria-hidden="true">
																				   If you are a human seeing this field, please leave it empty.
																				   <input id="nf-field-hp-2" name="nf-field-hp" class="nf-element nf-field-hp" type="text" value="" />
																				   </label>
																				</nf-section>
																			 </div>
																		  </nf-section>
																	   </div>
																	</div>
																 </form>
														   </div>
														</div>
													 </div>
												  </div>
											   </div>
											   
											</div>
										 </div>
									  </div>
								   </div>
								</div>
							 </div>
						  </div>
					   </div>
					  
					</div>
              
            </div>
          </div>
          <IncludeSidebar/>
        </div>
        <IncludeMariigeCertificate/>
      </div>
      <Footer />
    </>
  );
}

export default LegalHelp;
