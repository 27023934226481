import React, { useState } from 'react';
import IncludeSidebar from './IncludeSidebar';
import IncludeMariigeCertificate from "./IncludeMariigeCertificate";
import axios from 'axios';
//import { Link, NavLink } from "react-router-dom";

const Homecontent = () => {	
	
	const [formData, setFormData] = useState({
        boy_name: '',
        boy_father_name: '',
        boy_mother_name: '',
        boy_dob: '',
        boy_address: '',
        boy_religion: '',  
		boy_nationality: '',
        boy_marital_status: '',
        boy_phone: '',
        boy_email: '',
        girl_name: '',
        girl_father_name: '', 
		girl_mother_name: '',
        girl_dob: '',
        girl_address: '',
        girl_religion: '',
        girl_nationality: '',
        girl_marital: '',
		girl_phone_no: '',
        girl_email: '',
        expected_date: '',        
        page_action: 'yes',
        honeypot: ''
    });

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData(prevState => ({
		  ...prevState,
		  [name]: value
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		
		if (formData.honeypot) {
			  alert('Spam detected. Please try again.');
			  return;
		  }
		
		axios.post('https://admissionschoolofbiodesign.in/kite/court_marrige.php', JSON.stringify(formData),{
			headers: {
				'Content-Type': 'application/json'
			  }
			})
			.then(response => {
				
				if (response.data.status === 'success') {			  
                    window.location.href = response.data.redirect_url;
				} else {				
				  alert(response.data.message);
				}
			})
			.catch(error => {
				//console.error('There was an error submitting the form!', error);			
				alert('There was an error submitting the form. Please try again.');
			});
	};
	
	return (
    <>
       
        <div id="main" class="site-main hfeed site">
            <div id="main-content" class="main-content">
            <div id="primary" class="content-area">
                <div id="content" class="site-content" role="main">
                    <article id="post-12" class="post-12 page type-page status-publish hentry">
                    <header class="entry-header">
                        <h1 class="entry-title">Online Registration</h1>
                    </header>
                    
                    <div class="entry-content">
                        <div class="fl-builder-content fl-builder-content-12 fl-builder-content-primary fl-builder-global-templates-locked" data-post-id="12">
                            <div class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-615fb229b8e28" data-node="615fb229b8e28">
                                <div class="fl-row-content-wrap">
                                <div class="fl-row-content fl-row-fixed-width fl-node-content">
                                    <div class="fl-col-group fl-node-615fb229bbeaf" data-node="615fb229bbeaf">
                                        <div class="fl-col fl-node-615fb229bbf32" data-node="615fb229bbf32">
                                            <div class="fl-col-content fl-node-content">
                                            <div class="fl-module fl-module-photo fl-node-615fb229b8c05" data-node="615fb229b8c05">
                                                <div class="fl-module-content fl-node-content">
                                                    <div class="fl-photo fl-photo-align-center">
                                                        <div class="fl-photo-content fl-photo-img-png"> <img loading="lazy" class="fl-photo-img wp-image-99 size-full" src="/static/img/Marriage-Certificate-Government-of-India.png" alt="Marriage-Certificate-Government-of-India" itemprop="image" height="240" width="1260" title="Marriage-Certificate-Government-of-India | Court Marriage &amp; Registration"  sizes="(max-width: 1260px) 100vw, 1260px" /> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5f2fb7e1303c9" data-node="5f2fb7e1303c9">
                                <div class="fl-row-content-wrap">
                                <div class="fl-row-content fl-row-fixed-width fl-node-content">
                                    <div class="fl-col-group fl-node-5f2fb7e132369" data-node="5f2fb7e132369">
                                        <div class="fl-col fl-node-5f2fb7e13245a" data-node="5f2fb7e13245a">
                                            <div class="fl-col-content fl-node-content">
                                            <div class="fl-module fl-module-widget fl-node-5f2fb7e130269" data-node="5f2fb7e130269">
                                                <div class="fl-module-content fl-node-content">
                                                    <div class="fl-widget">
                                                        <div class="widget widget_ninja_forms_widget">
                                                        <noscript class="ninja-forms-noscript-message">
                                                            Notice: JavaScript is required for this content.
                                                        </noscript>
                                                       
                                                        <span id="nf-form-title-1" class="nf-form-title">
                                                            <h3>Online Form for Court Marriage &amp; Registration</h3>
                                                        </span>
                                                            
                                                           													
                                                        <div class="nf-form-layout">
                                                            <form className="cmxform" id="commonForm" method="post" onSubmit={handleSubmit}>
                                                                <div>
                                                                    <div class="nf-before-form-content">
                                                                    <div class="nf-form-fields-required">Fields marked with an <span class="ninja-forms-req-symbol">*</span> are required</div>
                                                                    </div>
                                                                    <div class="nf-form-content ">
                                                                    
                                                                    <nf-field>
                                                                        <div id="nf-field-5-container" class="nf-field-container textbox-container  label-above ">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-5-wrap" class="field-wrap textbox-wrap" data-field-id="1">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-1" id="nf-label-field-1" class="">Name <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <input type="text" class="ninja-forms-field nf-element" placeholder="Enter Boy's Name" id="boy_name" name="boy_name" value={formData.boy_name} onChange={handleChange} required  />
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-5" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    
                                                                    <nf-field>
                                                                        <div id="nf-field-5-container" class="nf-field-container textbox-container  label-above ">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-5-wrap" class="field-wrap textbox-wrap" data-field-id="5">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-5" id="nf-label-field-5" class="">Father's Name <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <input type="text"  class="ninja-forms-field nf-element" placeholder="Enter Boy's Father Name" id="boy_father_name" name="boy_father_name" value={formData.boy_father_name} onChange={handleChange} required  />
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-5" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-6-container" class="nf-field-container textbox-container  label-above ">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-6-wrap" class="field-wrap textbox-wrap" data-field-id="6">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-6" id="nf-label-field-6" class="">Mother's Name <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <input type="text"  class="ninja-forms-field nf-element" placeholder="Enter Boy's Mother Name" id="boy_mother_name" name="boy_mother_name" value={formData.boy_mother_name} onChange={handleChange} required  />
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-6" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-7-container" class="nf-field-container date-container  label-above ">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-7-wrap" class="field-wrap date-wrap" data-field-id="7">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-7" id="nf-label-field-7" class="">Boy's Date of Birth </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <div class="pikaday__container">
                                                                                        <input class="ninja-forms-field nf-element datepicker pikaday__display pikaday__display--pikaday form-control input" placeholder="Enter Boy's DOB"  tabindex="0" type="date" id="boy_dob" name="boy_dob" value={formData.boy_dob} onChange={handleChange} required  />
                                                                                    </div>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-7" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-8-container" class="nf-field-container address-container  label-above ">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-8-wrap" class="field-wrap address-wrap" data-field-id="8">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-8" id="nf-label-field-8" class="">Address <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <input type="text" value="" class="ninja-forms-field nf-element"  name="boy_address" id="boy_address"   placeholder="Enter Boy's Address" value={formData.boy_address} onChange={handleChange} required  />
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-8" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-9-container" class="nf-field-container listradio-container  label-above  list-container">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-9-wrap" class="field-wrap" data-field-id="9">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-9" id="nf-label-field-9" class="">Religion (Boy) <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <label class="container">Hindu (Sikh, Buddhiest, Jain)
                                                                                    <input type="radio" checked="checked" name="boy_religion" id="flexRadioDefault1" value="Hindu Sikh Buddhiest Jain" />
                                                                                    <span class="checkmark"></span>
                                                                                    </label>
                                                                                    <label class="container">Muslim
                                                                                    <input type="radio" name="boy_religion" id="flexRadioDefault2" value="Muslim" />
                                                                                    <span class="checkmark"></span>
                                                                                    </label>
                                                                                    <label class="container">Christian
                                                                                    <input type="radio" name="boy_religion" id="flexRadioDefault3" value="Christian" />
                                                                                    <span class="checkmark"></span>
                                                                                    </label>                                                                             
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-9" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-10-container" class="nf-field-container listcountry-container  label-above  list-container">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-10-wrap" class="field-wrap listcountry-wrap list-wrap" data-field-id="10">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-10" id="nf-label-field-10" class="">Nationality (Boy) <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <select name="boy_nationality" id="boy_nationality"  class="ninja-forms-field nf-element" value={formData.boy_nationality} onChange={handleChange} required>
                                                                                        <option value="">- Select Country -</option>
                                                                                        <option value="AF">Afghanistan</option>
                                                                                        <option value="AL">Albania</option>
                                                                                        <option value="DZ">Algeria</option>
                                                                                        <option value="AS">American Samoa</option>
                                                                                        <option value="AD">Andorra</option>
                                                                                        <option value="AO">Angola</option>
                                                                                        <option value="AI">Anguilla</option>
                                                                                        <option value="AQ">Antarctica</option>
                                                                                        <option value="AG">Antigua And Barbuda</option>
                                                                                        <option value="AR">Argentina</option>
                                                                                        <option value="AM">Armenia</option>
                                                                                        <option value="AW">Aruba</option>
                                                                                        <option value="AU">Australia</option>
                                                                                        <option value="AT">Austria</option>
                                                                                        <option value="AZ">Azerbaijan</option>
                                                                                        <option value="BS">Bahamas</option>
                                                                                        <option value="BH">Bahrain</option>
                                                                                        <option value="BD">Bangladesh</option>
                                                                                        <option value="BB">Barbados</option>
                                                                                        <option value="BY">Belarus</option>
                                                                                        <option value="BE">Belgium</option>
                                                                                        <option value="BZ">Belize</option>
                                                                                        <option value="BJ">Benin</option>
                                                                                        <option value="BM">Bermuda</option>
                                                                                        <option value="BT">Bhutan</option>
                                                                                        <option value="BO">Bolivia</option>
                                                                                        <option value="BA">Bosnia And Herzegowina</option>
                                                                                        <option value="BW">Botswana</option>
                                                                                        <option value="BV">Bouvet Island</option>
                                                                                        <option value="BR">Brazil</option>
                                                                                        <option value="IO">British Indian Ocean Territory</option>
                                                                                        <option value="BN">Brunei Darussalam</option>
                                                                                        <option value="BG">Bulgaria</option>
                                                                                        <option value="BF">Burkina Faso</option>
                                                                                        <option value="BI">Burundi</option>
                                                                                        <option value="KH">Cambodia</option>
                                                                                        <option value="CM">Cameroon</option>
                                                                                        <option value="CA">Canada</option>
                                                                                        <option value="CV">Cape Verde</option>
                                                                                        <option value="KY">Cayman Islands</option>
                                                                                        <option value="CF">Central African Republic</option>
                                                                                        <option value="TD">Chad</option>
                                                                                        <option value="CL">Chile</option>
                                                                                        <option value="CN">China</option>
                                                                                        <option value="CX">Christmas Island</option>
                                                                                        <option value="CC">Cocos (Keeling) Islands</option>
                                                                                        <option value="CO">Colombia</option>
                                                                                        <option value="KM">Comoros</option>
                                                                                        <option value="CG">Congo</option>
                                                                                        <option value="CD">Congo, The Democratic Republic Of The</option>
                                                                                        <option value="CK">Cook Islands</option>
                                                                                        <option value="CR">Costa Rica</option>
                                                                                        <option value="CI">Cote D'Ivoire</option>
                                                                                        <option value="HR">Croatia (Local Name: Hrvatska)</option>
                                                                                        <option value="CU">Cuba</option>
                                                                                        <option value="CY">Cyprus</option>
                                                                                        <option value="CZ">Czech Republic</option>
                                                                                        <option value="DK">Denmark</option>
                                                                                        <option value="DJ">Djibouti</option>
                                                                                        <option value="DM">Dominica</option>
                                                                                        <option value="DO">Dominican Republic</option>
                                                                                        <option value="EC">Ecuador</option>
                                                                                        <option value="EG">Egypt</option>
                                                                                        <option value="SV">El Salvador</option>
                                                                                        <option value="GQ">Equatorial Guinea</option>
                                                                                        <option value="ER">Eritrea</option>
                                                                                        <option value="EE">Estonia</option>
                                                                                        <option value="ET">Ethiopia</option>
                                                                                        <option value="FK">Falkland Islands (Malvinas)</option>
                                                                                        <option value="FO">Faroe Islands</option>
                                                                                        <option value="FJ">Fiji</option>
                                                                                        <option value="FI">Finland</option>
                                                                                        <option value="FR">France</option>
                                                                                        <option value="FX">France, Metropolitan</option>
                                                                                        <option value="GF">French Guiana</option>
                                                                                        <option value="PF">French Polynesia</option>
                                                                                        <option value="TF">French Southern Territories</option>
                                                                                        <option value="GA">Gabon</option>
                                                                                        <option value="GM">Gambia</option>
                                                                                        <option value="GE">Georgia</option>
                                                                                        <option value="DE">Germany</option>
                                                                                        <option value="GH">Ghana</option>
                                                                                        <option value="GI">Gibraltar</option>
                                                                                        <option value="GR">Greece</option>
                                                                                        <option value="GL">Greenland</option>
                                                                                        <option value="GD">Grenada</option>
                                                                                        <option value="GP">Guadeloupe</option>
                                                                                        <option value="GU">Guam</option>
                                                                                        <option value="GT">Guatemala</option>
                                                                                        <option value="GN">Guinea</option>
                                                                                        <option value="GW">Guinea-Bissau</option>
                                                                                        <option value="GY">Guyana</option>
                                                                                        <option value="HT">Haiti</option>
                                                                                        <option value="HM">Heard And Mc Donald Islands</option>
                                                                                        <option value="VA">Holy See (Vatican City State)</option>
                                                                                        <option value="HN">Honduras</option>
                                                                                        <option value="HK">Hong Kong</option>
                                                                                        <option value="HU">Hungary</option>
                                                                                        <option value="IS">Iceland</option>
                                                                                        <option value="IN" selected="selected">India</option>
                                                                                        <option value="ID">Indonesia</option>
                                                                                        <option value="IR">Iran (Islamic Republic Of)</option>
                                                                                        <option value="IQ">Iraq</option>
                                                                                        <option value="IE">Ireland</option>
                                                                                        <option value="IL">Israel</option>
                                                                                        <option value="IT">Italy</option>
                                                                                        <option value="JM">Jamaica</option>
                                                                                        <option value="JP">Japan</option>
                                                                                        <option value="JO">Jordan</option>
                                                                                        <option value="KZ">Kazakhstan</option>
                                                                                        <option value="KE">Kenya</option>
                                                                                        <option value="KI">Kiribati</option>
                                                                                        <option value="KP">Korea, Democratic People's Republic Of</option>
                                                                                        <option value="KR">Korea, Republic Of</option>
                                                                                        <option value="KW">Kuwait</option>
                                                                                        <option value="KG">Kyrgyzstan</option>
                                                                                        <option value="LA">Lao People's Democratic Republic</option>
                                                                                        <option value="LV">Latvia</option>
                                                                                        <option value="LB">Lebanon</option>
                                                                                        <option value="LS">Lesotho</option>
                                                                                        <option value="LR">Liberia</option>
                                                                                        <option value="LY">Libyan Arab Jamahiriya</option>
                                                                                        <option value="LI">Liechtenstein</option>
                                                                                        <option value="LT">Lithuania</option>
                                                                                        <option value="LU">Luxembourg</option>
                                                                                        <option value="MO">Macau</option>
                                                                                        <option value="MK">Macedonia, Former Yugoslav Republic Of</option>
                                                                                        <option value="MG">Madagascar</option>
                                                                                        <option value="MW">Malawi</option>
                                                                                        <option value="MY">Malaysia</option>
                                                                                        <option value="MV">Maldives</option>
                                                                                        <option value="ML">Mali</option>
                                                                                        <option value="MT">Malta</option>
                                                                                        <option value="MH">Marshall Islands</option>
                                                                                        <option value="MQ">Martinique</option>
                                                                                        <option value="MR">Mauritania</option>
                                                                                        <option value="MU">Mauritius</option>
                                                                                        <option value="YT">Mayotte</option>
                                                                                        <option value="MX">Mexico</option>
                                                                                        <option value="FM">Micronesia, Federated States Of</option>
                                                                                        <option value="MD">Moldova, Republic Of</option>
                                                                                        <option value="MC">Monaco</option>
                                                                                        <option value="MN">Mongolia</option>
                                                                                        <option value="ME">Montenegro</option>
                                                                                        <option value="MS">Montserrat</option>
                                                                                        <option value="MA">Morocco</option>
                                                                                        <option value="MZ">Mozambique</option>
                                                                                        <option value="MM">Myanmar</option>
                                                                                        <option value="NA">Namibia</option>
                                                                                        <option value="NR">Nauru</option>
                                                                                        <option value="NP">Nepal</option>
                                                                                        <option value="NL">Netherlands</option>
                                                                                        <option value="AN">Netherlands Antilles</option>
                                                                                        <option value="NC">New Caledonia</option>
                                                                                        <option value="NZ">New Zealand</option>
                                                                                        <option value="NI">Nicaragua</option>
                                                                                        <option value="NE">Niger</option>
                                                                                        <option value="NG">Nigeria</option>
                                                                                        <option value="NU">Niue</option>
                                                                                        <option value="NF">Norfolk Island</option>
                                                                                        <option value="MP">Northern Mariana Islands</option>
                                                                                        <option value="NO">Norway</option>
                                                                                        <option value="OM">Oman</option>
                                                                                        <option value="PK">Pakistan</option>
                                                                                        <option value="PW">Palau</option>
                                                                                        <option value="PA">Panama</option>
                                                                                        <option value="PG">Papua New Guinea</option>
                                                                                        <option value="PY">Paraguay</option>
                                                                                        <option value="PE">Peru</option>
                                                                                        <option value="PH">Philippines</option>
                                                                                        <option value="PN">Pitcairn</option>
                                                                                        <option value="PL">Poland</option>
                                                                                        <option value="PT">Portugal</option>
                                                                                        <option value="PR">Puerto Rico</option>
                                                                                        <option value="QA">Qatar</option>
                                                                                        <option value="RE">Reunion</option>
                                                                                        <option value="RO">Romania</option>
                                                                                        <option value="RU">Russian Federation</option>
                                                                                        <option value="RW">Rwanda</option>
                                                                                        <option value="KN">Saint Kitts And Nevis</option>
                                                                                        <option value="LC">Saint Lucia</option>
                                                                                        <option value="VC">Saint Vincent And The Grenadines</option>
                                                                                        <option value="WS">Samoa</option>
                                                                                        <option value="SM">San Marino</option>
                                                                                        <option value="ST">Sao Tome And Principe</option>
                                                                                        <option value="SA">Saudi Arabia</option>
                                                                                        <option value="SN">Senegal</option>
                                                                                        <option value="RS">Serbia</option>
                                                                                        <option value="SC">Seychelles</option>
                                                                                        <option value="SL">Sierra Leone</option>
                                                                                        <option value="SG">Singapore</option>
                                                                                        <option value="SK">Slovakia (Slovak Republic)</option>
                                                                                        <option value="SI">Slovenia</option>
                                                                                        <option value="SB">Solomon Islands</option>
                                                                                        <option value="SO">Somalia</option>
                                                                                        <option value="ZA">South Africa</option>
                                                                                        <option value="GS">South Georgia, South Sandwich Islands</option>
                                                                                        <option value="ES">Spain</option>
                                                                                        <option value="LK">Sri Lanka</option>
                                                                                        <option value="SH">St. Helena</option>
                                                                                        <option value="PM">St. Pierre And Miquelon</option>
                                                                                        <option value="SD">Sudan</option>
                                                                                        <option value="SR">Suriname</option>
                                                                                        <option value="SJ">Svalbard And Jan Mayen Islands</option>
                                                                                        <option value="SZ">Swaziland</option>
                                                                                        <option value="SE">Sweden</option>
                                                                                        <option value="CH">Switzerland</option>
                                                                                        <option value="SY">Syrian Arab Republic</option>
                                                                                        <option value="TW">Taiwan</option>
                                                                                        <option value="TJ">Tajikistan</option>
                                                                                        <option value="TZ">Tanzania, United Republic Of</option>
                                                                                        <option value="TH">Thailand</option>
                                                                                        <option value="TL">Timor-Leste (East Timor)</option>
                                                                                        <option value="TG">Togo</option>
                                                                                        <option value="TK">Tokelau</option>
                                                                                        <option value="TO">Tonga</option>
                                                                                        <option value="TT">Trinidad And Tobago</option>
                                                                                        <option value="TN">Tunisia</option>
                                                                                        <option value="TR">Turkey</option>
                                                                                        <option value="TM">Turkmenistan</option>
                                                                                        <option value="TC">Turks And Caicos Islands</option>
                                                                                        <option value="TV">Tuvalu</option>
                                                                                        <option value="UG">Uganda</option>
                                                                                        <option value="UA">Ukraine</option>
                                                                                        <option value="AE">United Arab Emirates</option>
                                                                                        <option value="GB">United Kingdom</option>
                                                                                        <option value="US">United States</option>
                                                                                        <option value="UM">United States Minor Outlying Islands</option>
                                                                                        <option value="UY">Uruguay</option>
                                                                                        <option value="UZ">Uzbekistan</option>
                                                                                        <option value="VU">Vanuatu</option>
                                                                                        <option value="VE">Venezuela</option>
                                                                                        <option value="VN">Viet Nam</option>
                                                                                        <option value="VG">Virgin Islands (British)</option>
                                                                                        <option value="VI">Virgin Islands (U.S.)</option>
                                                                                        <option value="WF">Wallis And Futuna Islands</option>
                                                                                        <option value="EH">Western Sahara</option>
                                                                                        <option value="YE">Yemen</option>
                                                                                        <option value="YU">Yugoslavia</option>
                                                                                        <option value="ZM">Zambia</option>
                                                                                        <option value="ZW">Zimbabwe</option>
                                                                                    </select>
                                                                                    <div for="nf-field-10"></div>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-10" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-11-container" class="nf-field-container listselect-container  label-above  list-container">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-11-wrap" class="field-wrap listselect-wrap list-wrap list-select-wrap" data-field-id="11">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-11" id="nf-label-field-11" class="">Marital Status (Boy) <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <select id="boy_marital_status" name="boy_marital_status"  class="ninja-forms-field nf-element" value={formData.boy_marital_status} onChange={handleChange} required>
                                                                                        <option value="Unmarried" selected="selected">Unmarried</option>
                                                                                        <option value="Divorcee">Divorcee</option>
                                                                                        <option value="Widower">Widower</option>
                                                                                    </select>
                                                                                    <div for="nf-field-11"></div>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-11" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-12-container" class="nf-field-container phone-container  label-above  textbox-container">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-12-wrap" class="field-wrap phone-wrap textbox-wrap" data-field-id="12">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-12" id="nf-label-field-12" class="">WhatsApp Number <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <input type="tel" class="ninja-forms-field nf-element" id="boy_phone" name="boy_phone" autocomplete="tel" placeholder="Enter Boy's WhatsApp Number" value={formData.boy_phone} onChange={handleChange} required />
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-12" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-13-container" class="nf-field-container email-container  label-above ">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-13-wrap" class="field-wrap email-wrap" data-field-id="13">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-13" id="nf-label-field-13" class="">Email <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <input type="email" class="ninja-forms-field nf-element" id="boy_email" name="boy_email" autocomplete="email" placeholder="Enter Boy's Email" value={formData.boy_email} onChange={handleChange} required/>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-13" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-15-container" class="nf-field-container textbox-container  label-above ">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-15-wrap" class="field-wrap textbox-wrap" data-field-id="14">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-14" id="nf-label-field-14" class="">Girl Name <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <input type="text"  class="ninja-forms-field nf-element" placeholder="Enter Girl's Name" id="girl_name" name="girl_name" value={formData.girl_name} onChange={handleChange} required />
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-15" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-15-container" class="nf-field-container textbox-container  label-above ">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-15-wrap" class="field-wrap textbox-wrap" data-field-id="15">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-15" id="nf-label-field-15" class="">Father's Name <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <input type="text"  class="ninja-forms-field nf-element" placeholder="Enter Girl's Father Name" id="girl_father_name" name="girl_father_name" value={formData.girl_father_name} onChange={handleChange} required />
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-15" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-16-container" class="nf-field-container textbox-container  label-above ">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-16-wrap" class="field-wrap textbox-wrap" data-field-id="16">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-16" id="nf-label-field-16" class="">Mother's Name <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <input type="text" class="ninja-forms-field nf-element" placeholder="Enter Girl's Mother Name" id="girl_mother_name" name="girl_mother_name" value={formData.girl_mother_name} onChange={handleChange} required />
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-16" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-17-container" class="nf-field-container date-container  label-above ">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-17-wrap" class="field-wrap date-wrap" data-field-id="17">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-17" id="nf-label-field-17" class="">Girl's Date of Birth </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <div class="pikaday__container">
                                                                                        <input id="girl_dob" name="girl_dob" aria-invalid="false" aria-describedby="nf-error-17" class="ninja-forms-field nf-element datepicker pikaday__display pikaday__display--pikaday flatpickr-input"   type="date" value={formData.girl_dob} onChange={handleChange} required/>                                              
                                                                                    </div>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-17" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-18-container" class="nf-field-container address-container  label-above ">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-18-wrap" class="field-wrap address-wrap" data-field-id="18">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-18" id="nf-label-field-18" class="">Address <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <input type="text" class="ninja-forms-field nf-element" id="girl_address" name="girl_address"  placeholder="Enter Girl's Address" value={formData.girl_address} onChange={handleChange} required />
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-18" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-19-container" class="nf-field-container listradio-container  label-above  list-container">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-19-wrap" class="field-wrap" data-field-id="19">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-19" id="nf-label-field-19" class="">Religion (Girl) <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <div class="nf-field-element">
                                                                                    <label class="container">Hindu (Sikh, Buddhiest, Jain)
                                                                                    <input type="radio" checked="checked" name="girl_religion" id="flexRadioDefault1" value="Hindu Sikh Buddhiest Jain" />
                                                                                    <span class="checkmark"></span>
                                                                                    </label>
                                                                                    <label class="container">Muslim
                                                                                    <input type="radio" name="girl_religion" id="flexRadioDefault2" value="Muslim" />
                                                                                    <span class="checkmark"></span>
                                                                                    </label>
                                                                                    <label class="container">Christian
                                                                                    <input type="radio" name="girl_religion" id="flexRadioDefault3" value="Christian" />
                                                                                    <span class="checkmark"></span>
                                                                                    </label>                                                                             
                                                                                </div>
                                                                                    
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-19" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-20-container" class="nf-field-container listcountry-container  label-above  list-container">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-20-wrap" class="field-wrap listcountry-wrap list-wrap" data-field-id="20">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-20" id="nf-label-field-20" class="">Nationality (Girl) <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <select id="girl_nationality" name="girl_nationality"  class="ninja-forms-field nf-element" value={formData.girl_nationality} onChange={handleChange} required>
                                                                                        <option value="">- Select Country -</option>
                                                                                        <option value="AF">Afghanistan</option>
                                                                                        <option value="AL">Albania</option>
                                                                                        <option value="DZ">Algeria</option>
                                                                                        <option value="AS">American Samoa</option>
                                                                                        <option value="AD">Andorra</option>
                                                                                        <option value="AO">Angola</option>
                                                                                        <option value="AI">Anguilla</option>
                                                                                        <option value="AQ">Antarctica</option>
                                                                                        <option value="AG">Antigua And Barbuda</option>
                                                                                        <option value="AR">Argentina</option>
                                                                                        <option value="AM">Armenia</option>
                                                                                        <option value="AW">Aruba</option>
                                                                                        <option value="AU">Australia</option>
                                                                                        <option value="AT">Austria</option>
                                                                                        <option value="AZ">Azerbaijan</option>
                                                                                        <option value="BS">Bahamas</option>
                                                                                        <option value="BH">Bahrain</option>
                                                                                        <option value="BD">Bangladesh</option>
                                                                                        <option value="BB">Barbados</option>
                                                                                        <option value="BY">Belarus</option>
                                                                                        <option value="BE">Belgium</option>
                                                                                        <option value="BZ">Belize</option>
                                                                                        <option value="BJ">Benin</option>
                                                                                        <option value="BM">Bermuda</option>
                                                                                        <option value="BT">Bhutan</option>
                                                                                        <option value="BO">Bolivia</option>
                                                                                        <option value="BA">Bosnia And Herzegowina</option>
                                                                                        <option value="BW">Botswana</option>
                                                                                        <option value="BV">Bouvet Island</option>
                                                                                        <option value="BR">Brazil</option>
                                                                                        <option value="IO">British Indian Ocean Territory</option>
                                                                                        <option value="BN">Brunei Darussalam</option>
                                                                                        <option value="BG">Bulgaria</option>
                                                                                        <option value="BF">Burkina Faso</option>
                                                                                        <option value="BI">Burundi</option>
                                                                                        <option value="KH">Cambodia</option>
                                                                                        <option value="CM">Cameroon</option>
                                                                                        <option value="CA">Canada</option>
                                                                                        <option value="CV">Cape Verde</option>
                                                                                        <option value="KY">Cayman Islands</option>
                                                                                        <option value="CF">Central African Republic</option>
                                                                                        <option value="TD">Chad</option>
                                                                                        <option value="CL">Chile</option>
                                                                                        <option value="CN">China</option>
                                                                                        <option value="CX">Christmas Island</option>
                                                                                        <option value="CC">Cocos (Keeling) Islands</option>
                                                                                        <option value="CO">Colombia</option>
                                                                                        <option value="KM">Comoros</option>
                                                                                        <option value="CG">Congo</option>
                                                                                        <option value="CD">Congo, The Democratic Republic Of The</option>
                                                                                        <option value="CK">Cook Islands</option>
                                                                                        <option value="CR">Costa Rica</option>
                                                                                        <option value="CI">Cote D'Ivoire</option>
                                                                                        <option value="HR">Croatia (Local Name: Hrvatska)</option>
                                                                                        <option value="CU">Cuba</option>
                                                                                        <option value="CY">Cyprus</option>
                                                                                        <option value="CZ">Czech Republic</option>
                                                                                        <option value="DK">Denmark</option>
                                                                                        <option value="DJ">Djibouti</option>
                                                                                        <option value="DM">Dominica</option>
                                                                                        <option value="DO">Dominican Republic</option>
                                                                                        <option value="EC">Ecuador</option>
                                                                                        <option value="EG">Egypt</option>
                                                                                        <option value="SV">El Salvador</option>
                                                                                        <option value="GQ">Equatorial Guinea</option>
                                                                                        <option value="ER">Eritrea</option>
                                                                                        <option value="EE">Estonia</option>
                                                                                        <option value="ET">Ethiopia</option>
                                                                                        <option value="FK">Falkland Islands (Malvinas)</option>
                                                                                        <option value="FO">Faroe Islands</option>
                                                                                        <option value="FJ">Fiji</option>
                                                                                        <option value="FI">Finland</option>
                                                                                        <option value="FR">France</option>
                                                                                        <option value="FX">France, Metropolitan</option>
                                                                                        <option value="GF">French Guiana</option>
                                                                                        <option value="PF">French Polynesia</option>
                                                                                        <option value="TF">French Southern Territories</option>
                                                                                        <option value="GA">Gabon</option>
                                                                                        <option value="GM">Gambia</option>
                                                                                        <option value="GE">Georgia</option>
                                                                                        <option value="DE">Germany</option>
                                                                                        <option value="GH">Ghana</option>
                                                                                        <option value="GI">Gibraltar</option>
                                                                                        <option value="GR">Greece</option>
                                                                                        <option value="GL">Greenland</option>
                                                                                        <option value="GD">Grenada</option>
                                                                                        <option value="GP">Guadeloupe</option>
                                                                                        <option value="GU">Guam</option>
                                                                                        <option value="GT">Guatemala</option>
                                                                                        <option value="GN">Guinea</option>
                                                                                        <option value="GW">Guinea-Bissau</option>
                                                                                        <option value="GY">Guyana</option>
                                                                                        <option value="HT">Haiti</option>
                                                                                        <option value="HM">Heard And Mc Donald Islands</option>
                                                                                        <option value="VA">Holy See (Vatican City State)</option>
                                                                                        <option value="HN">Honduras</option>
                                                                                        <option value="HK">Hong Kong</option>
                                                                                        <option value="HU">Hungary</option>
                                                                                        <option value="IS">Iceland</option>
                                                                                        <option value="IN" selected="selected">India</option>
                                                                                        <option value="ID">Indonesia</option>
                                                                                        <option value="IR">Iran (Islamic Republic Of)</option>
                                                                                        <option value="IQ">Iraq</option>
                                                                                        <option value="IE">Ireland</option>
                                                                                        <option value="IL">Israel</option>
                                                                                        <option value="IT">Italy</option>
                                                                                        <option value="JM">Jamaica</option>
                                                                                        <option value="JP">Japan</option>
                                                                                        <option value="JO">Jordan</option>
                                                                                        <option value="KZ">Kazakhstan</option>
                                                                                        <option value="KE">Kenya</option>
                                                                                        <option value="KI">Kiribati</option>
                                                                                        <option value="KP">Korea, Democratic People's Republic Of</option>
                                                                                        <option value="KR">Korea, Republic Of</option>
                                                                                        <option value="KW">Kuwait</option>
                                                                                        <option value="KG">Kyrgyzstan</option>
                                                                                        <option value="LA">Lao People's Democratic Republic</option>
                                                                                        <option value="LV">Latvia</option>
                                                                                        <option value="LB">Lebanon</option>
                                                                                        <option value="LS">Lesotho</option>
                                                                                        <option value="LR">Liberia</option>
                                                                                        <option value="LY">Libyan Arab Jamahiriya</option>
                                                                                        <option value="LI">Liechtenstein</option>
                                                                                        <option value="LT">Lithuania</option>
                                                                                        <option value="LU">Luxembourg</option>
                                                                                        <option value="MO">Macau</option>
                                                                                        <option value="MK">Macedonia, Former Yugoslav Republic Of</option>
                                                                                        <option value="MG">Madagascar</option>
                                                                                        <option value="MW">Malawi</option>
                                                                                        <option value="MY">Malaysia</option>
                                                                                        <option value="MV">Maldives</option>
                                                                                        <option value="ML">Mali</option>
                                                                                        <option value="MT">Malta</option>
                                                                                        <option value="MH">Marshall Islands</option>
                                                                                        <option value="MQ">Martinique</option>
                                                                                        <option value="MR">Mauritania</option>
                                                                                        <option value="MU">Mauritius</option>
                                                                                        <option value="YT">Mayotte</option>
                                                                                        <option value="MX">Mexico</option>
                                                                                        <option value="FM">Micronesia, Federated States Of</option>
                                                                                        <option value="MD">Moldova, Republic Of</option>
                                                                                        <option value="MC">Monaco</option>
                                                                                        <option value="MN">Mongolia</option>
                                                                                        <option value="ME">Montenegro</option>
                                                                                        <option value="MS">Montserrat</option>
                                                                                        <option value="MA">Morocco</option>
                                                                                        <option value="MZ">Mozambique</option>
                                                                                        <option value="MM">Myanmar</option>
                                                                                        <option value="NA">Namibia</option>
                                                                                        <option value="NR">Nauru</option>
                                                                                        <option value="NP">Nepal</option>
                                                                                        <option value="NL">Netherlands</option>
                                                                                        <option value="AN">Netherlands Antilles</option>
                                                                                        <option value="NC">New Caledonia</option>
                                                                                        <option value="NZ">New Zealand</option>
                                                                                        <option value="NI">Nicaragua</option>
                                                                                        <option value="NE">Niger</option>
                                                                                        <option value="NG">Nigeria</option>
                                                                                        <option value="NU">Niue</option>
                                                                                        <option value="NF">Norfolk Island</option>
                                                                                        <option value="MP">Northern Mariana Islands</option>
                                                                                        <option value="NO">Norway</option>
                                                                                        <option value="OM">Oman</option>
                                                                                        <option value="PK">Pakistan</option>
                                                                                        <option value="PW">Palau</option>
                                                                                        <option value="PA">Panama</option>
                                                                                        <option value="PG">Papua New Guinea</option>
                                                                                        <option value="PY">Paraguay</option>
                                                                                        <option value="PE">Peru</option>
                                                                                        <option value="PH">Philippines</option>
                                                                                        <option value="PN">Pitcairn</option>
                                                                                        <option value="PL">Poland</option>
                                                                                        <option value="PT">Portugal</option>
                                                                                        <option value="PR">Puerto Rico</option>
                                                                                        <option value="QA">Qatar</option>
                                                                                        <option value="RE">Reunion</option>
                                                                                        <option value="RO">Romania</option>
                                                                                        <option value="RU">Russian Federation</option>
                                                                                        <option value="RW">Rwanda</option>
                                                                                        <option value="KN">Saint Kitts And Nevis</option>
                                                                                        <option value="LC">Saint Lucia</option>
                                                                                        <option value="VC">Saint Vincent And The Grenadines</option>
                                                                                        <option value="WS">Samoa</option>
                                                                                        <option value="SM">San Marino</option>
                                                                                        <option value="ST">Sao Tome And Principe</option>
                                                                                        <option value="SA">Saudi Arabia</option>
                                                                                        <option value="SN">Senegal</option>
                                                                                        <option value="RS">Serbia</option>
                                                                                        <option value="SC">Seychelles</option>
                                                                                        <option value="SL">Sierra Leone</option>
                                                                                        <option value="SG">Singapore</option>
                                                                                        <option value="SK">Slovakia (Slovak Republic)</option>
                                                                                        <option value="SI">Slovenia</option>
                                                                                        <option value="SB">Solomon Islands</option>
                                                                                        <option value="SO">Somalia</option>
                                                                                        <option value="ZA">South Africa</option>
                                                                                        <option value="GS">South Georgia, South Sandwich Islands</option>
                                                                                        <option value="ES">Spain</option>
                                                                                        <option value="LK">Sri Lanka</option>
                                                                                        <option value="SH">St. Helena</option>
                                                                                        <option value="PM">St. Pierre And Miquelon</option>
                                                                                        <option value="SD">Sudan</option>
                                                                                        <option value="SR">Suriname</option>
                                                                                        <option value="SJ">Svalbard And Jan Mayen Islands</option>
                                                                                        <option value="SZ">Swaziland</option>
                                                                                        <option value="SE">Sweden</option>
                                                                                        <option value="CH">Switzerland</option>
                                                                                        <option value="SY">Syrian Arab Republic</option>
                                                                                        <option value="TW">Taiwan</option>
                                                                                        <option value="TJ">Tajikistan</option>
                                                                                        <option value="TZ">Tanzania, United Republic Of</option>
                                                                                        <option value="TH">Thailand</option>
                                                                                        <option value="TL">Timor-Leste (East Timor)</option>
                                                                                        <option value="TG">Togo</option>
                                                                                        <option value="TK">Tokelau</option>
                                                                                        <option value="TO">Tonga</option>
                                                                                        <option value="TT">Trinidad And Tobago</option>
                                                                                        <option value="TN">Tunisia</option>
                                                                                        <option value="TR">Turkey</option>
                                                                                        <option value="TM">Turkmenistan</option>
                                                                                        <option value="TC">Turks And Caicos Islands</option>
                                                                                        <option value="TV">Tuvalu</option>
                                                                                        <option value="UG">Uganda</option>
                                                                                        <option value="UA">Ukraine</option>
                                                                                        <option value="AE">United Arab Emirates</option>
                                                                                        <option value="GB">United Kingdom</option>
                                                                                        <option value="US">United States</option>
                                                                                        <option value="UM">United States Minor Outlying Islands</option>
                                                                                        <option value="UY">Uruguay</option>
                                                                                        <option value="UZ">Uzbekistan</option>
                                                                                        <option value="VU">Vanuatu</option>
                                                                                        <option value="VE">Venezuela</option>
                                                                                        <option value="VN">Viet Nam</option>
                                                                                        <option value="VG">Virgin Islands (British)</option>
                                                                                        <option value="VI">Virgin Islands (U.S.)</option>
                                                                                        <option value="WF">Wallis And Futuna Islands</option>
                                                                                        <option value="EH">Western Sahara</option>
                                                                                        <option value="YE">Yemen</option>
                                                                                        <option value="YU">Yugoslavia</option>
                                                                                        <option value="ZM">Zambia</option>
                                                                                        <option value="ZW">Zimbabwe</option>
                                                                                    </select>
                                                                                    <div for="nf-field-20"></div>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-20" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-21-container" class="nf-field-container listselect-container  label-above  list-container">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-21-wrap" class="field-wrap listselect-wrap list-wrap list-select-wrap" data-field-id="21">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-21" id="nf-label-field-21" class="">Marital Status (Girl) <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <select id="girl_marital" name="girl_marital"   class="ninja-forms-field nf-element" value={formData.girl_marital} onChange={handleChange} required>
                                                                                        <option value="Unmarried" selected="selected">Unmarried</option>
                                                                                        <option value="Divorcee">Divorcee</option>
                                                                                        <option value="Widow">Widow</option>
                                                                                    </select>
                                                                                    <div for="nf-field-21"></div>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-21" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    
                                                                    <nf-field>
                                                                        <div id="nf-field-12-container" class="nf-field-container phone-container  label-above  textbox-container">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-12-wrap" class="field-wrap phone-wrap textbox-wrap" data-field-id="12">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-12" id="nf-label-field-12" class="">WhatsApp Number <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <input type="tel"  class="ninja-forms-field nf-element" id="girl_phone_no" name="girl_phone_no"  placeholder="Enter girl's WhatsApp Number"value={formData.girl_phone_no} onChange={handleChange} required />
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-12" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    
                                                                    <nf-field>
                                                                        <div id="nf-field-23-container" class="nf-field-container email-container  label-above ">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-23-wrap" class="field-wrap email-wrap" data-field-id="23">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-23" id="nf-label-field-23" class="">Email <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <input type="email"  class="ninja-forms-field nf-element" id="girl_email" name="girl_email"  placeholder="Enter Girl's Email" value={formData.girl_email} onChange={handleChange} required />
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-23" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-24-container" class="nf-field-container date-container  label-above ">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-24-wrap" class="field-wrap date-wrap" data-field-id="24">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-24" id="nf-label-field-24" class="">Expected Date for Court Marriage/Registration <span class="ninja-forms-req-symbol">*</span> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <div class="pikaday__container">
                                                                                       
                                                                                    
                                                                                        <input type="date" class="ninja-forms-field nf-element datepicker pikaday__display pikaday__display--pikaday form-control input" placeholder="" required="" tabindex="0" name="expected_date" id="expected_date"  value={formData.expected_date} onChange={handleChange} required />
                                                                                    </div>
                                                                                    <div style={{ display: 'none' }}>
                                                                                    <ul>
                                                                                        <li>
                                                                                        Closed for Sunday &amp; Public Holiday, if your selected date is Holiday then by default it will go for next working day or you can reschedule it by contacting the support team.
                                                                                        </li>
                                                                                        <li>
                                                                                        to see the list of holidays kindly visit <b>Google Calendar</b> for <b>Indian Holidays</b> or contact the support team.
                                                                                        </li>
                                                                                    </ul>
                                                                                    </div>

                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-24" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-25-container" class="nf-field-container checkbox-container  label-right ">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-25-wrap" class="field-wrap checkbox-wrap" data-field-id="25">
                                                                                
                                                                                <label class="container">I declare the above particulars are true to the best of my knowledge &amp; befief. <span class="ninja-forms-req-symbol">*</span>
                                                                                    <input type="checkbox" name="fee_menu" id="flexRadioDefault3" value="Y" required checked />
                                                                                    <span class="checkmark"></span>
                                                                                    </label>
                                                                                    
                                                                                    
                                                                                
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-25" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-26-container" class="nf-field-container checkbox-container  label-right ">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-26-wrap" class="field-wrap checkbox-wrap" data-field-id="26">
                                                                                
                                                                                <label class="container">I have checked the fee details from Fee Menu <span class="ninja-forms-req-symbol">*</span>
                                                                                    <input type="checkbox" name="fee_menu" id="flexRadioDefault3" value="Y" required checked />
                                                                                    <span class="checkmark"></span>
                                                                                    </label>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-26" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-28-container" class="nf-field-container checkbox-container  label-right ">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-28-wrap" class="field-wrap checkbox-wrap" data-field-id="28">
                                                                                
                                                                                <label class="container">I accept the Terms &amp; Conditions <span class="ninja-forms-req-symbol">*</span>
                                                                                    <input type="checkbox" name="fee_menu" id="flexRadioDefault3" value="Y" required checked /> 
                                                                                    <span class="checkmark"></span>
                                                                                    </label>
                                                                                    
                                                                                    
                                                                                <div class="nf-field-description">
                                                                                    <p><a href="https://courtmarriage.info/wp-content/uploads/2020/08/Terms-Conditions-Court-Marriage-2020.pdf" target="_blank"><font color="blue">Terms &amp; Conditions</font></a></p>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-28" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-29-container" class="nf-field-container recaptcha-container  label-above  textbox-container">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-29-wrap" class="field-wrap recaptcha-wrap textbox-wrap" data-field-id="29">
                                                                                <div class="nf-field-label">
                                                                                    <label for="nf-field-29" id="nf-label-field-29" class=""> </label>
                                                                                </div>
                                                                                <div class="nf-field-element">
                                                                                    <input id="nf-field-29" name="nf-field-29" class="ninja-forms-field nf-element" type="hidden" value="" />
                                                                                    <div class="g-recaptcha" data-callback="nf_recaptcha_response_29" data-theme="light" data-sitekey="6LeaabwZAAAAANzeD-6HbORtd9EElEAotUWcRMlx" data-fieldid="29"></div>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-29" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                    <nf-field>
                                                                        <div id="nf-field-30-container" class="nf-field-container submit-container  label-above  textbox-container">
                                                                            <div class="nf-before-field"> </div>
                                                                            <div class="nf-field">
                                                                                <div id="nf-field-30-wrap" class="field-wrap submit-wrap textbox-wrap" data-field-id="30">
                                                                                <div class="nf-field-label"></div>
                                                                                <div class="nf-field-element">
                                                                                    <input type="hidden" name="page_action" value="yes" />
                                                                                    <input id="nf-field-30" class="ninja-forms-field nf-element " type="submit" value="Submit" />
                                                                                </div>
                                                                                <div class="nf-error-wrap"></div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="nf-after-field">
                                                                                <div class="nf-input-limit"></div>
                                                                                <div id="nf-error-30" class="nf-error-wrap nf-error" role="alert"></div>
                                                                            </div>
                                                                        </div>
                                                                    </nf-field>
                                                                   
                                                                    </div>
                                                                    <div class="nf-after-form-content">
                                                                    <div id="nf-form-errors-1" class="nf-form-errors" role="alert">
                                                                        <nf-errors>
                                                                            <div class="nf-error-msg nf-error-field-errors">Please correct errors before submitting this form.</div>
                                                                        </nf-errors>
                                                                    </div>
                                                                    <div class="nf-form-hp">
                                                                        <label for="nf-field-hp-1" aria-hidden="true"> If you are a human seeing this field, please leave it empty.
                                                                        <input id="nf-field-hp-1" name="nf-field-hp" class="nf-element nf-field-hp" type="text" value="" />
                                                                        </label>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>                   
                    </article>                    
                </div>               
            </div>
            <IncludeSidebar/>
        </div>
        <IncludeMariigeCertificate/>
        </div>


    </>
  );
};

export default Homecontent;
